import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';

const errCodesMapper = {
  4001: 'Invalid Status',
  666: 'Network Error. Please Try Again!',
};
export default function PackShipment(props) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMessages, setErrMessages] = useState([]);
  useEffect(() => {
    if (!open) setErrMessages([]);
  }, [open]);
  const shipToBenghazi = async () => {
    const shipmentId = props.shipmentData.id;
    setIsLoading(true);
    try {
      const response = await netjeekServer.post(
        `/shipments/change-status`,
        {
          shipmentId,
          event: 'shipment:received-in-transit-hub',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        props.setSuccessMessages([`Shipment Received In Transit Hub`]);
        setOpen(false);
        props.getShipmentData(shipmentId);
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      setIsLoading(false);
      setErrMessages(errorMessages);
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <Button
        disabled={props.disabled}
        variant='contained'
        color='primary'
        size='small'
        onClick={() => setOpen(true)}
      >
        Receive In Transit Hub
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='addNumber-dialog-title'
        fullWidth
      >
        <DialogTitle id='addNumber-dialog-title'>
          Receive In Transit Hub
        </DialogTitle>
        <Snackbar type='error' messages={errMessages} />

        <DialogContent style={{ textAlign: 'center', fontSize: '17px' }}>
          Are you sure you want this shipment to be{' '}
          <b>Received In Transit Hub</b>?
        </DialogContent>

        <DialogActions style={{ margin: 10 }}>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            style={{ color: '#000', fontSize: '16px' }}
          >
            No
          </Button>
          <Button
            style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
            disabled={isLoading}
            onClick={shipToBenghazi}
            variant='contained'
            color='primary'
          >
            Receive
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 4, position: 'absolute' }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
