import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core/';
import { Route } from 'react-router-dom';
import OrdersManagment from 'components/Orders/OrdersManagment';
import ReceiveItems from 'components/Orders/ReceiveItems';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
    flexDirection: 'column',
  },
}));

export default function Orders(props) {
  const classes = useStyles();
  const { changePageHeader } = props;

  return (
    <Grid container className={classes.root}>
      <Route
        path={`/admin-portal/orders/orders-management`}
        render={() => (
          <OrdersManagment
            {...props}
            changePageHeader={changePageHeader}
          ></OrdersManagment>
        )}
      />
      <Route
        path={`/admin-portal/orders/receive-itmes`}
        render={() => (
          <ReceiveItems
            {...props}
            changePageHeader={changePageHeader}
          ></ReceiveItems>
        )}
      />
    </Grid>
  );
}
