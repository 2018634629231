/**
 * @module SignIn
 */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  CssBaseline,
  Paper,
  CircularProgress,
} from '@material-ui/core/';
import netjeekServer from 'api/netjeek';
import Background from 'assets/images/cart.jpg';
import netjeekLogo from 'assets/images/logo.png';
import Snackbar from 'components/Snackbar';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';

const errCodesMapper = {
  1000: 'Email is missing',
  1001: 'Password is missing',
  3000: 'Email is not valid',
  4000: 'Invalid Email or Passwrod',
  5000: 'Invalid Email or Passwrod',
  666: 'Network Error. Please Try Again!',
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    padding: theme.spacing(1),
    margin: theme.spacing(3, 0, 2),
  },
  progressor: {
    marginLeft: 4,
    position: 'absolute',
  },
  forgotPasswordStyle: {
    color: '#3d67f2',
  },
  netjeekLogo: {
    height: '100px',
    marginBottom: '16px',
  },
}));
/**
 * @method SignIn
 * @description Sign In Functional Component
 */
export default function SignIn(props) {
  const classes = useStyles();
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setuserData] = useState({
    // rememberMe: false,
    email: '',
    password: '',
  });
  /**
   * @callback useEffect
   * @description Auto sign in the user if a token already exists in Local storage.
   * @memberof SignIn
   */
  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsLoading(true);
      setErrorMessages([]);
      getUserData();
    }
  }, []);

  const getUserData = async () => {
    try {
      const response = await netjeekServer.get('/users/me', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response) {
        setTimeout(() => {
          props.history.push('/admin-portal');
          setIsLoading(false);
        }, 500);
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            if (error.code === 5000) {
              localStorage.clear();
            } else errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      setIsLoading(false);
      setErrorMessages(errorMessages);
    }
  };
  /**
   * @method inputchangeHandler
   * @memberof SignIn
   * @description Handle input field data change updating user's data object.
   * @param {string} name Name of input field.
   * @param {Object} event Event containing information about the input action.
   */
  const inputchangeHandler = (name) => (event) => {
    const tempUserData = { ...userData };
    tempUserData[name] = event.target.value;
    setuserData(tempUserData);
  };

  // const checkHandler = () => {
  //   const tempUserData = { ...userData };
  //   tempUserData.rememberMe = !tempUserData.rememberMe;
  //   setuserData(tempUserData);
  // };

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessages([]);

    try {
      const response = await netjeekServer.post('/users/login', userData);

      if (response) {
        const token = response.data.data.token;
        const refresh_token = response.data.data.refresh_token;

        await localStorage.setItem('refresh_token', refresh_token);
        await localStorage.setItem('token', token);
        setTimeout(() => {
          props.history.push('/admin-portal');
          setIsLoading(false);
        }, 500);
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      setIsLoading(false);
      setErrorMessages(errorMessages);
    }
  };

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img
            alt='netjeek-logo'
            src={netjeekLogo}
            className={classes.netjeekLogo}
          />
          <Typography gutterBottom component='h1' variant='h3' align='center'>
            NETJEEK ADMIN
          </Typography>
          <Typography gutterBottom component='h1' variant='h4'>
            Sign In
          </Typography>
          <ValidatorForm className={classes.form} onSubmit={submitHandler}>
            <Snackbar type='error' messages={errorMessages} />
            <TextValidator
              variant='outlined'
              validators={['required', 'isEmail']}
              errorMessages={[
                'Email Address is required',
                'Email Address is not valid',
              ]}
              value={userData.email}
              onChange={inputchangeHandler('email')}
              margin='normal'
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              color='secondary'
              autoFocus
            />
            <TextValidator
              variant='outlined'
              validators={['required']}
              errorMessages={['Password is required']}
              value={userData.password}
              onChange={inputchangeHandler('password')}
              margin='normal'
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              color='secondary'
              autoComplete='current-password'
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  value='remember'
                  onClick={checkHandler}
                  color='primary'
                />
              }
              label='Remember me'
            /> */}
            {isLoading ? (
              <Button
                disabled
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                Please Wait
                <CircularProgress size={24} className={classes.progressor} />
              </Button>
            ) : (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                Sign In
              </Button>
            )}

            <Grid container>
              <Grid item xs>
                <Link
                  to={`/forgot-password`}
                  className={classes.forgotPasswordStyle}
                >
                  Forgot Password?
                </Link>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </Grid>
    </Grid>
  );
}
