import React, { useState, useEffect } from 'react';
import {
  Box,
  MenuItem,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core/';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';

const errCodesMapper = {
  1000: 'Number is missing',
  1001: 'Retailer is missing',
  1002: 'Number Type is missing',
  2000: 'Retailer is not valid',
  2001: 'Number is not valid',
  2002: 'Number Type is not valid',
  2100: 'Something went wrong',
  3000: 'Retailer must be one of [AMAZON, WALMART, ALIEXPRESS]',
  4000: 'Order does not exist.',
  4001: 'Driver not found.',
  666: 'Network Error. Please Try Again!',
};
export default function AddNumberModal(props) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [numberData, setNumberData] = useState({
    number: '',
    retailer: '',
    numberType: '',
  });
  const [errMessages, setErrMessages] = useState([]);

  useEffect(() => {
    setNumberData({ number: '', retailer: props.retailer, numberType: '' });
  }, []);

  useEffect(() => {
    if (!open) setErrMessages([]);
  }, [open]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const tempNumberData = { ...numberData };
    tempNumberData[name] = value;
    setNumberData(tempNumberData);
  };

  const addNumber = async () => {
    const orderId = props.orderData.id;
    setIsLoading(true);
    try {
      const response = await netjeekServer.post(
        `/orders/${orderId}/numbers`,
        numberData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        numberData.numberType === 'ORDER'
          ? props.setSuccessMessages([`Order Number Added Successfully`])
          : props.setSuccessMessages([`Tracking Number Added Successfully`]);
        setOpen(false);
        props.getOrderData(orderId);
        setNumberData({ number: '', retailer: props.retailer, numberType: '' });
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      setIsLoading(false);
      setErrMessages(errorMessages);
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <Button
        size='small'
        variant='contained'
        color='primary'
        onClick={() => setOpen(true)}
      >
        Add Number
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='addNumber-dialog-title'
        fullWidth
      >
        <DialogTitle id='addNumber-dialog-title'>Add New Number</DialogTitle>
        <Snackbar type='error' messages={errMessages} />
        <ValidatorForm onSubmit={addNumber}>
          <DialogContent>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  fontWeight: 500,
                  width: '30%',
                }}
              >
                Number:
              </Typography>
              <TextValidator
                fullWidth
                name='number'
                margin='dense'
                validators={['required']}
                errorMessages={['Number is required']}
                type='text'
                id='number'
                value={numberData.number}
                onChange={handleChange}
                variant='outlined'
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  fontWeight: 500,
                  width: '30%',
                }}
              >
                Retailer
              </Typography>
              <TextValidator
                disabled
                select
                margin='dense'
                name='retailer'
                variant='outlined'
                validators={['required']}
                errorMessages={['Retailer is required']}
                fullWidth
                value={numberData.retailer}
                onChange={handleChange}
                style={{ marginBottom: '10px' }}
              >
                <MenuItem value={'AMAZON_CA'}>AMAZON</MenuItem>
                <MenuItem value={'WALMART_CA'}>WALMART</MenuItem>
                <MenuItem value={'ALIEXPRESS'}>ALIEXPRESS</MenuItem>
              </TextValidator>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  fontWeight: 500,
                  width: '30%',
                }}
              >
                Number Type
              </Typography>
              <TextValidator
                select
                margin='dense'
                name='numberType'
                variant='outlined'
                validators={['required']}
                errorMessages={['Number Type is required']}
                fullWidth
                value={numberData.numberType}
                onChange={handleChange}
                style={{ marginBottom: '10px' }}
              >
                <MenuItem value={'ORDER'}>Order Number</MenuItem>
                <MenuItem value={'TRACKING'}>Tracking Number</MenuItem>
              </TextValidator>
            </Box>
          </DialogContent>
          <DialogActions style={{ margin: 10 }}>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              style={{ color: '#000', fontSize: '16px' }}
            >
              Cancel
            </Button>
            <Button
              style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
              disabled={isLoading}
              type='submit'
              variant='contained'
              color='primary'
            >
              Update
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </Box>
  );
}
