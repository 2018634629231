import React, { Component } from 'react';
import {
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
  Tooltip,
  withStyles,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';

const errCodesMapper = {
  1000: 'Quantity is missing',
  3000: 'Quantity is not valid',
  666: 'Network Error. Please Try Again!',
};
const styles = (theme) => ({
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(7),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
});
class CreateShipmentModal extends Component {
  state = {
    open: false,
    isLoading: false,
    errorMessages: [],
    successMessages: [],
    shipmentData: {
      // shipmentNumber: '',
      date: moment(),
      eta: moment(),
      method: '',
      courier_number: '',
      notes: '',
    },
  };

  componentDidMount = () => {};

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
  };

  createShipment = async (event) => {
    event.preventDefault();
    let shipmentData = { ...this.state.shipmentData };
    this.setState({ isLoading: true });
    try {
      shipmentData.date = shipmentData.date.toISOString();
      shipmentData.eta = shipmentData.eta.toISOString();

      const response = await netjeekServer.post(`/shipments`, shipmentData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response) {
        console.log('response', response);
        const newShipmentId = response.data.data.id;
        this.props.setSuccessMessages(['Shipment Created Successfully']);
        this.props.history.push(
          `/admin-portal/shipment-details/${newShipmentId}`
        );
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (err.response.status === 404)
          errorMessages.push(errCodesMapper['666']);
        else if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
  };
  handleShipmentDateChange = (date) => {
    const shipmentData = { ...this.state.shipmentData };
    shipmentData['date'] = date;
    this.setState({ shipmentData });
  };
  handleETADateChange = (date) => {
    const shipmentData = { ...this.state.shipmentData };
    shipmentData['eta'] = date;
    this.setState({ shipmentData });
  };
  handleChange = (event) => {
    const name = event.target.name;

    const value = event.target.value;

    const shipmentData = { ...this.state.shipmentData };
    shipmentData[name] = value;
    this.setState({ shipmentData });
  };

  render() {
    const { open, errorMessages, shipmentData, isLoading } = this.state;
    const { classes } = this.props;
    return (
      <Box>
        <Tooltip placement='left' title='Add Shipment'>
          <SpeedDial
            open={false}
            ariaLabel='SpeedDialShortCuts'
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClick={() => this.setOpen(true)}
          />
        </Tooltip>
        <Dialog
          open={open}
          onClose={() => this.setOpen(false)}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Create New Shipment</DialogTitle>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <Snackbar type='error' messages={errorMessages} />
          <ValidatorForm
            ref='form'
            onSubmit={this.createShipment}
            style={{ width: 600 }}
          >
            <DialogContent>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    //   marginRight: 15,
                    width: '30%',
                  }}
                >
                  Shipping Date:
                </Typography>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                >
                  <DatePicker
                    disableToolbar
                    fullWidth
                    autoFocus
                    autoOk
                    // keyboard
                    inputVariant='outlined'
                    variant='inline'
                    format={process.env.REACT_APP_DATE_FORMAT}
                    margin='dense'
                    value={shipmentData.date}
                    onChange={this.handleShipmentDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    //   marginRight: 15,
                    width: '30%',
                  }}
                >
                  Estimated Date:
                </Typography>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                >
                  <DatePicker
                    disableToolbar
                    fullWidth
                    autoOk
                    variant='inline'
                    inputVariant='outlined'
                    format={process.env.REACT_APP_DATE_FORMAT}
                    margin='dense'
                    value={shipmentData.eta}
                    onChange={this.handleETADateChange}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    width: '30%',
                  }}
                >
                  Shipping Method:
                </Typography>
                <TextValidator
                  select
                  margin='dense'
                  validators={['required']}
                  errorMessages={['Shipping Mode is required']}
                  name='method'
                  variant='outlined'
                  fullWidth
                  value={shipmentData.method}
                  onChange={this.handleChange}
                  style={{ marginBottom: '10px' }}
                >
                  <MenuItem value={'EXPRESS'}>Express</MenuItem>
                </TextValidator>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    width: '30%',
                  }}
                >
                  Courier Number:
                </Typography>
                <TextValidator
                  fullWidth
                  variant='outlined'
                  // validators={['required']}
                  // errorMessages={['Courier Number is required']}
                  margin='dense'
                  name='courier_number'
                  value={shipmentData.courier_number}
                  onChange={this.handleChange}
                  type='text'
                />
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  // variant='h6'
                  // display='block'
                  style={{
                    fontSize: '15px',
                    //   marginRight: 15,
                    width: '30%',
                  }}
                >
                  Notes:
                </Typography>
                <TextValidator
                  fullWidth
                  name='notes'
                  margin='dense'
                  multiline
                  rows={3}
                  type='text'
                  id='notes'
                  value={shipmentData.notes}
                  onChange={this.handleChange}
                  variant='outlined'
                />
              </Box>
            </DialogContent>
            <DialogActions style={{ margin: 10 }}>
              <Button
                onClick={() => {
                  this.setOpen(false);
                }}
                style={{ color: '#000', fontSize: '16px' }}
              >
                Cancel
              </Button>
              <Button
                style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
                disabled={isLoading}
                type='submit'
                variant='contained'
                color='primary'
                // onClick={event => this.createShipment(event)}
              >
                Create
                {isLoading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 4, position: 'absolute' }}
                  />
                )}
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </Box>
    );
  }
}
export default withStyles(styles)(CreateShipmentModal);
