import React, { useState, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import TableComponent from 'components/Table/TableComponent';
import CreateShippingFeeModal from 'components/ShippingFees/CreateShippingFeeModal';

const tableFields = {
  head: ['Region', 'City', 'Price Per Kg', 'Delivery Time', 'Action'],
  search: ['Region', 'City', 'Price', 'Delivery Time', null],
  dataKeys: [
    'region',
    'city',
    'pricePerKgStr',
    'avgDTStr',
    'editAndeDeleteAction',
  ],
};

const tableSelectOptions = [
  {
    label: 'ALL',
    value: 'ALL',
  },
  {
    label: 'Tripoli',
    value: 'Tripoli',
  },
  {
    label: 'Benghazi',
    value: 'Benghazi',
  },
  {
    label: 'Misrata',
    value: 'Misrata',
  },
  {
    label: 'Sabha',
    value: 'Sabha',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
  },
  tableHolderStyle: {
    padding: theme.spacing(1),
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  tabelCardHeaderStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}));

export default function ShippingFees(props) {
  const classes = useStyles();
  const [isTableLoading, setITableLoading] = useState(true);
  useEffect(() => {
    props.changePageHeader('Settings | Shipping Fees');
  }, []);

  const rerenderTable = (state) => {
    setITableLoading(state);
  };

  return (
    <Grid container justify='center' className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.tableHolderStyle} elevation={1}>
          {isTableLoading && (
            <TableComponent
              {...props}
              dataRoute='settings/shipping-fees'
              selectable={true}
              searchable={true}
              tableFields={tableFields}
              selectOptions={tableSelectOptions}
              rowsPerPage={10}
              rowsPerPageOptions={[5, 10, 25]}
              pagination={true}
            />
          )}
        </Paper>
      </Grid>
      <CreateShippingFeeModal
        {...props}
        rerenderTable={rerenderTable}
        // ref={this.createShippingFeeRef}
      />
    </Grid>
  );
}
