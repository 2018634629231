import React, { useState } from 'react';
import {
  Box,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core/';

export default function ShowNotes(props) {
  const [open, setOpen] = React.useState(false);
  const [notes] = useState(props.notes);

  return (
    <Box>
      <Button
        disabled={notes === ''}
        variant='outlined'
        style={{ padding: '2px 30px', width: 90 }}
        onClick={() => setOpen(true)}
      >
        Show ⇪
      </Button>
      <Dialog
        open={open}
        fullWidth
        onClick={() => setOpen(false)}
        aria-labelledby={`notes-dialog-title`}
        aria-describedby={`notes-dialog-description`}
      >
        <DialogTitle id={`notes-dialog-title`}>Notes</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: 17, fontWeight: 500 }}
            id={`notes-dialog-description`}
          >
            {notes}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            color='primary'
            variant='contained'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
