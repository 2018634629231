import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Box,
  Chip,
  Typography,
  StepContent,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
} from '@material-ui/core/';

import moment from 'moment';
import { Link } from 'react-router-dom';
import orderStatusMapper from 'shared/orderStatusMapper';
import AddNumberModal from 'components/OrderTraceCardActions/AddNumber';
import DriverOrShipModal from 'components/OrderTraceCardActions/DriverOrShip';
import AssignToDriverModal from 'components/OrderTraceCardActions/AssignToDriver';
import ReceiveInMisrataModal from 'components/OrderTraceCardActions/ReceiveInMisrata';
import ReceiveInBenghazi from 'components/OrderTraceCardActions/ReceiveInBenghazi';

import PickedByCustomerModal from 'components/OrderTraceCardActions/PickedByCustomer';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(3.5),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  completed: {
    backgroundImage: '#0f0',
  },
  listStyle: {
    //   display: 'flex',
    //   justifyContent: 'space-between',
    //   padding: '15px'
  },
  listItemStyle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#eee',
  },
  listItemNumberStyle: {
    color: '#00f',
  },
  listItemChipStyle: {},
}));

export default function TraceCard(props) {
  const classes = useStyles();
  const [steps, setSteps] = React.useState([]);
  const orderData = props.orderData;

  useEffect(() => {
    setSteps(props.traceData);
  }, [props]);

  const checkFullyReceived = () => {
    let isFullyReceived = false;
    steps.forEach((step) => {
      if (step.name === 'FULLY_RECEIVED_CANADA') {
        console.log('Fully Received');
        isFullyReceived = true;
      }
    });
    return isFullyReceived;
  };
  const getStepName = (name, success, hidden) => {
    switch (name) {
      case 'ORDER_PLACED_AMAZON':
        return (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='body1'>{orderStatusMapper[name]}</Typography>
            {!checkFullyReceived() && (
              <AddNumberModal {...props} retailer='AMAZON_CA' />
            )}
          </Box>
        );
      case 'ORDER_PLACED_WALMART':
        return (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='body1'>{orderStatusMapper[name]}</Typography>
            {!checkFullyReceived() && (
              <AddNumberModal {...props} retailer='WALMART_CA' />
            )}
          </Box>
        );
      case 'ORDER_PLACED_ALIEXPRESS':
        return (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='body1'>{orderStatusMapper[name]}</Typography>
            {!checkFullyReceived() && (
              <AddNumberModal {...props} retailer='ALIEXPRESS' />
            )}
          </Box>
        );
      case 'RECEIVED_IN_TRIPOLI':
        return success ? (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='body1'>{orderStatusMapper[name]}</Typography>
            {!hidden && <DriverOrShipModal {...props} />}
          </Box>
        ) : (
          orderStatusMapper[name]
        );
      case 'SHIPPED_TO_MISRATA':
        return success ? (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='body1'>{orderStatusMapper[name]}</Typography>
            {!hidden && <ReceiveInMisrataModal {...props} />}
          </Box>
        ) : (
          orderStatusMapper[name]
        );
      case 'SHIPPED_TO_BENGHAZI':
        return success ? (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='body1'>{orderStatusMapper[name]}</Typography>
            {!hidden && <ReceiveInBenghazi {...props} />}
          </Box>
        ) : (
          orderStatusMapper[name]
        );
      case 'RECEIVED_IN_MISRATA':
      case 'RECEIVED_IN_BENGHAZI':
        return success ? (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='body1'>{orderStatusMapper[name]}</Typography>
            {!hidden && <AssignToDriverModal {...props} />}
          </Box>
        ) : (
          orderStatusMapper[name]
        );
      case 'ASSIGNED_TO_SHIPMENT':
        return success ? (
          <Typography variant='body1'>
            Order Assigned To Shipment &nbsp;
            {props.userData.role !== 'support' ? (
              <Link
                style={{ color: '#00f' }}
                to={`/admin-portal/shipment-details/${orderData.shipment.id}`}
              >
                {`#${orderData.shipment.number}`}
              </Link>
            ) : (
              <span style={{ color: '#00f' }}>
                #${orderData.shipment.number}
              </span>
            )}
          </Typography>
        ) : (
          <Typography variant='body1'>
            Failed to Assign Order To Shipment
          </Typography>
        );
      case 'DELIVERED':
        return success ? (
          orderStatusMapper[name]
        ) : (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='body1'>{orderStatusMapper[name]}</Typography>
            {!hidden && <PickedByCustomerModal {...props} />}
          </Box>
        );
      default:
        return (
          <Typography variant='body1'>{orderStatusMapper[name]}</Typography>
        );
    }
  };

  const getStepContent = (step, success) => {
    switch (step) {
      case 'ASSIGNED_TO_SHIPMENT':
        return success ? '' : `Assign To Shipment Manually`;
      case 'ORDER_PLACED_AMAZON':
        return (
          <List className={classes.listStyle}>
            {orderData.order_numbers.map((orderNumber) => {
              if (orderNumber.retailer === 'AMAZON_CA' && orderNumber.number)
                return (
                  <ListItem className={classes.listItemStyle}>
                    <Typography
                      variant='body2'
                      className={classes.listItemNumberStyle}
                    >
                      {orderNumber.number}
                    </Typography>
                    <Chip
                      className={classes.listItemChipStyle}
                      variant='outlined'
                      label={
                        orderNumber.type[0] +
                        orderNumber.type.substr(1).toLowerCase() +
                        ' Number'
                      }
                      size='small'
                    />
                  </ListItem>
                );
              else return null;
            })}
          </List>
        );
      case 'ORDER_PLACED_WALMART':
        return (
          <List className={classes.listStyle}>
            {orderData.order_numbers.map((orderNumber) => {
              if (orderNumber.retailer === 'WALMART_CA' && orderNumber.number)
                return (
                  <ListItem className={classes.listItemStyle}>
                    <Typography
                      variant='body2'
                      className={classes.listItemNumberStyle}
                    >
                      {orderNumber.number}
                    </Typography>
                    <Chip
                      className={classes.listItemChipStyle}
                      variant='outlined'
                      label={
                        orderNumber.type[0] +
                        orderNumber.type.substr(1).toLowerCase() +
                        ' Number'
                      }
                      size='small'
                    />
                  </ListItem>
                );
              else return null;
            })}
          </List>
        );
      case 'ORDER_PLACED_ALIEXPRESS':
        return (
          <List className={classes.listStyle}>
            {orderData.order_numbers.map((orderNumber) => {
              if (orderNumber.retailer === 'ALIEXPRESS' && orderNumber.number)
                return (
                  <ListItem className={classes.listItemStyle}>
                    <Typography
                      variant='body2'
                      className={classes.listItemNumberStyle}
                    >
                      {orderNumber.number}
                    </Typography>
                    <Chip
                      className={classes.listItemChipStyle}
                      variant='outlined'
                      label={
                        orderNumber.type[0] +
                        orderNumber.type.substr(1).toLowerCase() +
                        ' Number'
                      }
                      size='small'
                    />
                  </ListItem>
                );
              else return null;
            })}
          </List>
        );
      case 'ASSIGNED_TO_DRIVER':
        return (
          <List className={classes.listStyle}>
            <ListItem className={classes.listItemStyle}>
              <Typography
                variant='body2'
                className={classes.listItemNumberStyle}
              >
                {orderData.delivery.driver.first_name +
                  ' ' +
                  orderData.delivery.driver.last_name}
              </Typography>
              <Chip
                className={classes.listItemChipStyle}
                variant='outlined'
                label='Driver Name'
                size='small'
              />
            </ListItem>
            <ListItem className={classes.listItemStyle}>
              <Typography
                variant='body2'
                className={classes.listItemNumberStyle}
              >
                {orderData.delivery.driver.email}
              </Typography>
              <Chip
                className={classes.listItemChipStyle}
                variant='outlined'
                label='Driver E-mail'
                size='small'
              />
            </ListItem>
          </List>
        );
      case 'DELIVERED':
        if (success)
          return (
            <Box p={2}>
              <Chip
                className={classes.listItemChipStyle}
                variant='outlined'
                label=' Customer Signature:'
                size='small'
              />
              <Box
                display='flex'
                justifyContent='center'
                style={{ height: '200px' }}
                component='div'
              >
                <img src={props.orderData.signature} />
              </Box>
            </Box>
          );
      default:
        return '';
    }
  };

  return (
    <Paper className={classes.root}>
      <Typography variant='h5' component='h3' gutterBottom>
        Order Trace
      </Typography>
      {steps.length === 0 ? (
        [...Array(5)].map((item, idx) => {
          return (
            <Box ml={2} mt={5} key={idx} mb={6}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Skeleton variant='circle' width={35} height={35} />
                <Skeleton
                  variant='rect'
                  height={15}
                  width='80%'
                  style={{ marginBottom: 6, marginLeft: 8 }}
                />
              </Box>
              <Skeleton
                variant='rect'
                height={12}
                width='40%'
                style={{ marginLeft: 40 }}
              />
            </Box>
          );
        })
      ) : (
        <Stepper orientation='vertical' activeStep={steps.length - 1}>
          {steps.map((step, idx) => {
            return (
              <Step key={idx} active={true}>
                <StepLabel
                  StepIconProps={{
                    completed: step.success,
                    error: !step.success,
                  }}
                >
                  {getStepName(
                    step.name,
                    step.success,
                    idx < steps.length - 1 || props.userData.role === 'support'
                  )}
                </StepLabel>
                <StepContent>
                  <Typography variant='caption' display='block' gutterBottom>
                    {moment(step.timestamp).format(
                      process.env.REACT_APP_DATE_FORMAT
                    )}
                  </Typography>
                  <Typography variant='body2'>
                    {getStepContent(step.name, step.success)}
                  </Typography>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      )}
    </Paper>
  );
}
