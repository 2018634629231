import React, { Component } from 'react';
import {
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box,
  Typography,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
const errCodesMapper = {
  2000: 'First Name 2 to 20',
  2001: 'Last Name 2 to 20',
  2002: 'E-mail max 255',
  2004: 'Status is not valid',
  2100: 'Something went wrong!. PLease Try again',
  3000: 'E-mail is not valid',
  5000: 'Customer was not found.',
  666: 'Network Error. Please Try Again!',
};
export default class EditCustomerProfile extends Component {
  state = {
    open: false,
    errorMessages: [],
    isLoading: false,
    successMessages: [],
    demographicData: this.props.data,
    updatedCustomerData: {},
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.data !== prevProps.data) {
      this.setState({ demographicData: this.props.data });
    }
  };

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
  };

  updateCustomer = async (event) => {
    event.preventDefault();
    const { updatedCustomerData } = this.state;
    const customerId = this.props.dataId;

    this.setState({ successMessages: [], errorMessages: [] });
    if (Object.keys(updatedCustomerData).length === 0) return;
    this.setState({ isLoading: true });
    updatedCustomerData.isActive = updatedCustomerData.isActive === 'ACTIVE';
    try {
      const response = await netjeekServer.patch(
        `/customers/${customerId}`,
        {
          ...updatedCustomerData,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        this.props.setSuccessMessages(['Customer Updated Successfully']);
        this.props.getCustomerData(customerId);
        await this.setState({
          updatedCustomerData: {},
          errorMessages: [],
        });
        this.setOpen(false);
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (err.response.status === 404)
          errorMessages.push(errCodesMapper['666']);
        else if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
  };
  handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const updatedCustomerData = { ...this.state.updatedCustomerData };
    const demographicData = { ...this.state.demographicData };
    updatedCustomerData[name] = value;
    demographicData[name] = value;
    this.setState({ updatedCustomerData, demographicData });
  };

  render() {
    const {
      open,
      errorMessages,
      successMessages,
      isLoading,
      updatedCustomerData,
    } = this.state;
    const { demographicData } = this.state;
    return (
      <Dialog
        open={open}
        onClose={() => this.setOpen(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Edit Demographoc Data</DialogTitle>
        {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
        <Snackbar type='error' messages={errorMessages} />
        <Snackbar type='success' messages={successMessages} />
        <ValidatorForm
          ref='form'
          onSubmit={this.updateCustomer}
          style={{ width: 600 }}
        >
          <DialogContent>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                // variant='h6'
                // display='block'
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                First Name:
              </Typography>
              <TextValidator
                fullWidth
                name='first_name'
                validators={['required']}
                errorMessages={['First Name is required']}
                margin='dense'
                type='text'
                id='first_name'
                value={demographicData.first_name}
                onChange={this.handleChange}
                variant='outlined'
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                // variant='h6'
                // display='block'
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                Last Name:
              </Typography>
              <TextValidator
                fullWidth
                name='last_name'
                validators={['required']}
                errorMessages={['Last Name is required']}
                margin='dense'
                type='text'
                id='last_name'
                value={demographicData.last_name}
                onChange={this.handleChange}
                variant='outlined'
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                // variant='h6'
                // display='block'
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                Email Address:
              </Typography>
              <TextValidator
                fullWidth
                validators={['required', 'isEmail']}
                errorMessages={[
                  'Email Address is required',
                  'Email Address is not valid',
                ]}
                name='email'
                margin='dense'
                type='text'
                id='email'
                value={demographicData.email}
                onChange={this.handleChange}
                variant='outlined'
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                // variant='h6'
                // display='block'
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                Phone:
              </Typography>
              <TextField
                fullWidth
                name='phone'
                margin='dense'
                type='text'
                id='phone'
                value={demographicData.phone}
                onChange={this.handleChange}
                variant='outlined'
              />
            </Box>

            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Status:
              </Typography>
              <TextValidator
                select
                margin='dense'
                name='isActive'
                validators={['required']}
                errorMessages={['Status is required']}
                variant='outlined'
                fullWidth
                value={demographicData.isActive}
                onChange={this.handleChange}
                style={{ marginBottom: '10px' }}
              >
                <MenuItem value={'ACTIVE'}>ACTIVE</MenuItem>
                <MenuItem value={'INACTIVE'}>INACTIVE</MenuItem>
              </TextValidator>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setOpen(false);
              }}
              style={{ color: '#000', fontSize: '16px' }}
            >
              Cancel
            </Button>
            <Button
              style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
              disabled={
                isLoading || Object.keys(updatedCustomerData).length === 0
              }
              type='submit'
              variant='contained'
              color='primary'
              // onClick={event => this.updateShipmentData(event)}
            >
              Update
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}
