import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from 'components/Layout/Layout';
import RedirectPage from 'pages/adminPortal/RedirectPage';
import Dashboard from 'pages/adminPortal/dashboard';
import Customers from 'pages/adminPortal/Customers';
import Orders from 'pages/adminPortal/Orders';
import Shipments from 'pages/adminPortal/Shipments';
import Settings from 'pages/adminPortal/settings';
import CustomerProfile from 'components/CustomerProfile/CustomerProfile';
import OrderDetails from 'components/Orders/OrderDetails';
import ShipmentDetails from 'components/Shipments/ShipmentDetails';
import { Route, Switch } from 'react-router-dom';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { toast } from 'react-toastify';

const errCodesMapper = {
  1000: 'E-mail is missing',
  1001: 'Password is missing',
  3000: 'E-mail is not valid',
  666: 'Network Error. Please Try Again!',
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f0f1f7',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
}));

export default function AdminPortal(props) {
  const classes = useStyles(props);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [pageName, setPageName] = useState('');
  const [successMessages, setSuccessMessages] = useState([]);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await netjeekServer.get('/users/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response) {
        const userData = response.data.data;
        setUserData(userData);
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
            if (error.code === 5000) {
              toast.error('Your session has expired. please sign in again.', {
                toastId: 1,
                containerId: 1,
              });
              localStorage.clear();
              props.history.push('/signin');
            }
          });
        }
      }
    }
  };

  const hideContent = () => {
    setIsDrawerOpen(true);
  };
  const showContent = () => {
    setIsDrawerOpen(false);
  };

  const changePageHeader = (name) => {
    setPageName(name);
  };

  return (
    <div className={classes.root}>
      <Layout
        setSuccessMessages={setSuccessMessages}
        userData={userData}
        pageName={pageName}
        hideContent={hideContent}
        showContent={showContent}
      />
      <Snackbar type='success' messages={successMessages} />
      {!isDrawerOpen && (
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Switch>
            {/* <Redirect exact from='/admin-portal' to='/admin-portal/dashboard' /> */}
            <Route
              path={'/admin-portal'}
              exact
              render={(props) => (
                <RedirectPage
                  {...props}
                  setSuccessMessages={setSuccessMessages}
                  changePageHeader={changePageHeader}
                  setUserData={setUserData}
                ></RedirectPage>
              )}
            />
            <Route
              path={'/admin-portal/dashboard'}
              render={(props) => (
                <Dashboard
                  {...props}
                  setSuccessMessages={setSuccessMessages}
                  changePageHeader={changePageHeader}
                ></Dashboard>
              )}
            />
            <Route
              path={`/admin-portal/customers`}
              render={(props) => (
                <Customers
                  {...props}
                  setSuccessMessages={setSuccessMessages}
                  changePageHeader={changePageHeader}
                ></Customers>
              )}
            />
            <Route
              path={`/admin-portal/customer-profile/:id`}
              render={(props) => (
                <CustomerProfile
                  {...props}
                  userData={userData}
                  setSuccessMessages={setSuccessMessages}
                  changePageHeader={changePageHeader}
                ></CustomerProfile>
              )}
            />
            <Route
              path={`/admin-portal/shipments`}
              render={(props) => (
                <Shipments
                  {...props}
                  userData={userData}
                  setSuccessMessages={setSuccessMessages}
                  changePageHeader={changePageHeader}
                ></Shipments>
              )}
            />
            <Route
              path={`/admin-portal/orders`}
              render={(props) => (
                <Orders
                  {...props}
                  setSuccessMessages={setSuccessMessages}
                  changePageHeader={changePageHeader}
                ></Orders>
              )}
            />
            <Route
              path={`/admin-portal/settings`}
              render={(props) => (
                <Settings
                  {...props}
                  setSuccessMessages={setSuccessMessages}
                  changePageHeader={changePageHeader}
                ></Settings>
              )}
            />
            <Route
              path={`/admin-portal/order-details/:id`}
              render={(props) => (
                <OrderDetails
                  {...props}
                  userData={userData}
                  setSuccessMessages={setSuccessMessages}
                  changePageHeader={changePageHeader}
                ></OrderDetails>
              )}
            />
            <Route
              path={`/admin-portal/shipment-details/:id`}
              render={(props) => (
                <ShipmentDetails
                  {...props}
                  userData={userData}
                  setSuccessMessages={setSuccessMessages}
                  changePageHeader={changePageHeader}
                ></ShipmentDetails>
              )}
            />
          </Switch>
        </main>
      )}
    </div>
  );
}
