import React, { Component } from 'react';
import {
  ListItemText,
  ListItem,
  ListItemIcon,
  List,
  Collapse,
  Box,
  Divider,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartPie,
  faDolly,
  faUsers,
  faCogs,
  faClipboardList,
} from '@fortawesome/free-solid-svg-icons';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = (theme) => ({
  oneLineText: {
    overflow: 'hidden',
    display: '-webkit-box',
    lineHeight: '21px',
    maxHeight: '21px',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
  },
  listContainer: {
    overflow: 'hidden',
    height: '100%',
    backgroundColor: '#383838 ',
    borderTop: '3px solid rgba(255,255,255,0.3)',
  },
  listStyle: {
    paddingTop: 0,
    overflow: 'hidden',
  },
  listLink: {
    color: theme.palette.secondary.contrastText,
    fontSize: theme.spacing(2),
    textDecorationLine: 'none',
  },
  listItem: {
    // backgroundColor: 'rgba(0,0,0,0.7) !important',
    '&:hover': {
      transition: 'border 0.25s ease-in-out',
      backgroundColor: `rgba(255,255,255,0.1) !important`,
      borderLeft: '15px solid #fff',
    },
  },
  fontAwesomeIconStyle: {
    color: '#fff',
    fontSize: '22px',
  },
  fontAwesomeIconSelected: {
    color: 'rgb(57,183,210)',
    fontSize: '22px',
  },
  selectedListItem: {
    backgroundColor: `rgba(255,255,255,0.1) !important`,
    borderLeft: '15px solid rgb(57,183,210)',
    '&:hover': { borderLeft: '15px solid rgb(57,183,210)' },
  },
  listItemText: { color: '#fff' },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
});
class ListItems extends Component {
  state = {
    selectedIndex: null,
    pages: [
      {
        idx: 0,
        name: 'Dashboard',
        route: '/admin-portal/dashboard',
        icon: faChartPie,
      },
      {
        idx: 1,
        name: 'Customers',
        route: '/admin-portal/customers',
        icon: faUsers,
      },
      {
        idx: 2,
        name: 'Orders',
        route: '/admin-portal/orders',
        icon: faClipboardList,
        children: [
          {
            idx: 3,
            name: 'Orders Management',
            route: '/admin-portal/orders/orders-management',
          },
          {
            idx: 4,
            name: 'Receive Items',
            route: '/admin-portal/orders/receive-items',
          },
        ],
      },
      {
        idx: 5,
        name: 'Shipments',
        route: '/admin-portal/shipments',
        icon: faDolly,
      },
      {
        idx: 6,
        name: 'Settings',
        route: '/admin-portal/settings',
        icon: faCogs,
        children: [
          {
            idx: 7,
            name: 'Image Slider',
            route: '/admin-portal/settings/slider-management',
          },
          {
            idx: 8,
            name: 'Shortcusts',
            route: '/admin-portal/settings/shortcuts',
          },
          {
            idx: 9,
            name: 'Top Picks',
            route: '/admin-portal/settings/top-picks',
          },
          {
            idx: 10,
            name: 'Currency Exchange',
            route: '/admin-portal/settings/currency-exchange',
          },
          {
            idx: 11,
            name: 'Shipping Fees',
            route: '/admin-portal/settings/shipping-fees',
          },
          {
            idx: 12,
            name: 'Users',
            route: '/admin-portal/settings/user-management',
          },
          {
            idx: 13,
            name: 'Users',
            route: '/admin-portal/settings/discount-management',
          },
        ],
      },
    ],
    isSettingOpen: false,
    isOrderOpen: false,
    open: false,
  };
  componentDidUpdate = (prevProps) => {
    if (this.props.isDrawerOpen !== prevProps.isDrawerOpen) {
      this.componentDidMount();
    }
  };
  componentDidMount = () => {
    const pages = this.state.pages;
    const route = this.props.location.pathname;

    pages.forEach((page) => {
      if (page.children) {
        page.children.forEach((childPage) => {
          if (route === childPage.route) {
            if (!this.props.isDrawerOpen) {
              this.setState({ selectedIndex: page.idx });
              return;
            }
            if (page.name === 'Orders')
              this.setState({
                selectedIndex: childPage.idx,
                isOrderOpen: true,
              });
            else
              this.setState({
                selectedIndex: childPage.idx,
                isSettingOpen: true,
              });
          }
        });
      } else {
        if (route === page.route) {
          this.setState({ selectedIndex: page.idx });
          return;
        }
      }
    });
  };

  handleListItemClick = (index) => {
    if (index === 2) {
      this.setState({
        isOrderOpen: !this.state.isOrderOpen,
        isSettingOpen: false,
      });
      if (!this.props.isDrawerOpen) {
        this.setState({ isOrderOpen: true });
      }
      this.props.handleDrawerOpen();
      return 0;
    }
    if (![3, 4].includes(index)) this.setState({ isOrderOpen: false });
    if (![7, 8, 9, 10, 11, 12, 13].includes(index))
      this.setState({ isSettingOpen: false });
    if (index === 6) {
      this.setState({
        isSettingOpen: !this.state.isSettingOpen,
        isOrderOpen: false,
      });

      if (!this.props.isDrawerOpen) {
        this.setState({ isSettingOpen: true });
      }
      this.props.handleDrawerOpen();
      return 0;
    }

    this.setState({
      selectedIndex: index,
    });
  };

  render() {
    const { classes, isDrawerOpen, userData } = this.props;
    const { isSettingOpen, isOrderOpen, selectedIndex } = this.state;

    const mainListItems = (
      <div>
        {userData.role === 'admin' && (
          <Link
            color='inherit'
            to={`/admin-portal/dashboard`}
            className={classes.listLink}
          >
            <ListItem
              // className={classes.listItem}
              classes={{
                root: classes.listItem,
                selected: classes.selectedListItem,
              }}
              button
              onClick={(event) => {
                this.handleListItemClick(0);
              }}
              selected={selectedIndex === 0}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  className={
                    selectedIndex === 0
                      ? classes.fontAwesomeIconSelected
                      : classes.fontAwesomeIconStyle
                  }
                  icon={faChartPie}
                ></FontAwesomeIcon>
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary='Dashboard'
              />
            </ListItem>
          </Link>
        )}

        {userData.role !== 'logistics' && (
          <Link
            color='inherit'
            to={`/admin-portal/customers`}
            className={classes.listLink}
          >
            <ListItem
              classes={{
                root: classes.listItem,
                selected: classes.selectedListItem,
              }}
              button
              onClick={(event) => {
                this.handleListItemClick(1);
              }}
              selected={selectedIndex === 1}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  className={
                    selectedIndex === 1
                      ? classes.fontAwesomeIconSelected
                      : classes.fontAwesomeIconStyle
                  }
                  icon={faUsers}
                ></FontAwesomeIcon>
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary='Customers'
              />
            </ListItem>
          </Link>
        )}

        <ListItem
          className={classes.listItem}
          classes={{ selected: classes.selectedListItem }}
          button
          onClick={(event) => {
            this.handleListItemClick(2);
          }}
          selected={selectedIndex === 2}
        >
          <ListItemIcon>
            <FontAwesomeIcon
              className={
                selectedIndex === 2
                  ? classes.fontAwesomeIconSelected
                  : classes.fontAwesomeIconStyle
              }
              icon={faClipboardList}
            ></FontAwesomeIcon>
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary='Orders' />
          {isOrderOpen ? (
            <ExpandLess className={classes.listItemText} />
          ) : (
            <ExpandMore className={classes.listItemText} />
          )}
        </ListItem>

        <Collapse in={isOrderOpen && isDrawerOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <Link
              color='inherit'
              to={`/admin-portal/orders/orders-management`}
              className={classes.listLink}
            >
              <ListItem
                classes={{ selected: classes.selectedListItem }}
                button
                onClick={(event) => {
                  this.handleListItemClick(3);
                }}
                selected={selectedIndex === 3}
                className={clsx(classes.nested, classes.listItem)}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary='Orders Management'
                />
              </ListItem>
            </Link>

            {userData.role !== 'support' && (
              <Link
                to='/admin-portal/orders/receive-itmes'
                color='inherit'
                className={classes.listLink}
              >
                <ListItem
                  button
                  onClick={(event) => {
                    this.handleListItemClick(4);
                  }}
                  selected={selectedIndex === 4}
                  className={clsx(classes.listItem, classes.nested)}
                  classes={{ selected: classes.selectedListItem }}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary='Receive Items'
                  />
                </ListItem>
              </Link>
            )}
          </List>
        </Collapse>

        {userData.role !== 'support' && (
          <Link
            color='inherit'
            to={`/admin-portal/shipments`}
            className={classes.listLink}
          >
            <ListItem
              classes={{
                root: classes.listItem,
                selected: classes.selectedListItem,
              }}
              button
              onClick={(event) => {
                this.handleListItemClick(5);
              }}
              selected={selectedIndex === 5}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  className={
                    selectedIndex === 5
                      ? classes.fontAwesomeIconSelected
                      : classes.fontAwesomeIconStyle
                  }
                  icon={faDolly}
                ></FontAwesomeIcon>
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary='Shipments'
              />
            </ListItem>
          </Link>
        )}

        {userData.role === 'admin' && (
          <ListItem
            className={classes.listItem}
            classes={{ selected: classes.selectedListItem }}
            button
            onClick={(event) => {
              this.handleListItemClick(6);
            }}
            selected={selectedIndex === 6}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                className={
                  selectedIndex === 6
                    ? classes.fontAwesomeIconSelected
                    : classes.fontAwesomeIconStyle
                }
                icon={faCogs}
              ></FontAwesomeIcon>
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary='Settings' />
            {isSettingOpen ? (
              <ExpandLess className={classes.listItemText} />
            ) : (
              <ExpandMore className={classes.listItemText} />
            )}
          </ListItem>
        )}

        <Collapse
          in={isSettingOpen && isDrawerOpen}
          timeout='auto'
          unmountOnExit
        >
          <List component='div' disablePadding>
            <Link
              to='/admin-portal/settings/slider-management'
              color='inherit'
              className={classes.listLink}
            >
              <ListItem
                classes={{ selected: classes.selectedListItem }}
                button
                onClick={(event) => {
                  this.handleListItemClick(7);
                }}
                selected={selectedIndex === 7}
                className={clsx(classes.nested, classes.listItem)}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary='Images Slider'
                />
              </ListItem>
            </Link>
            <Link
              to='/admin-portal/settings/shortcuts'
              color='inherit'
              className={classes.listLink}
            >
              <ListItem
                button
                onClick={(event) => {
                  this.handleListItemClick(8);
                }}
                selected={selectedIndex === 8}
                className={clsx(classes.listItem, classes.nested)}
                classes={{ selected: classes.selectedListItem }}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary='Shortcuts'
                />
              </ListItem>
            </Link>
            <Link
              to='/admin-portal/settings/top-picks'
              color='inherit'
              className={classes.listLink}
            >
              <ListItem
                button
                onClick={(event) => {
                  this.handleListItemClick(9);
                }}
                selected={selectedIndex === 9}
                className={clsx(classes.listItem, classes.nested)}
                classes={{ selected: classes.selectedListItem }}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary='Top Picks'
                />
              </ListItem>
            </Link>
            <Link
              to='/admin-portal/settings/currency-exchange'
              color='inherit'
              className={classes.listLink}
            >
              <ListItem
                button
                onClick={(event) => {
                  this.handleListItemClick(10);
                }}
                selected={selectedIndex === 10}
                className={clsx(classes.listItem, classes.nested)}
                classes={{ selected: classes.selectedListItem }}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary='Currency Exchange'
                />
              </ListItem>
            </Link>
            <Link
              to='/admin-portal/settings/shipping-fees'
              color='inherit'
              className={classes.listLink}
            >
              <ListItem
                button
                onClick={(event) => {
                  this.handleListItemClick(11);
                }}
                selected={selectedIndex === 11}
                className={clsx(classes.listItem, classes.nested)}
                classes={{ selected: classes.selectedListItem }}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary='Shipping Fees'
                />
              </ListItem>
            </Link>
            <Link
              to='/admin-portal/settings/discount-management'
              color='inherit'
              className={classes.listLink}
            >
              <ListItem
                button
                onClick={(event) => {
                  this.handleListItemClick(13);
                }}
                selected={selectedIndex === 13}
                className={clsx(classes.listItem, classes.nested)}
                classes={{ selected: classes.selectedListItem }}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary='Discount Management'
                />
              </ListItem>
            </Link>
            <Link
              to='/admin-portal/settings/user-management'
              color='inherit'
              className={classes.listLink}
            >
              <ListItem
                button
                onClick={(event) => {
                  this.handleListItemClick(12);
                }}
                selected={selectedIndex === 12}
                className={clsx(classes.listItem, classes.nested)}
                classes={{ selected: classes.selectedListItem }}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary='Users'
                />
              </ListItem>
            </Link>
          </List>
        </Collapse>
      </div>
    );
    return (
      <Box className={classes.listContainer}>
        <Divider />
        {userData.role === '' ? (
          <Box>
            {[...Array(4)].map((item, idx) => {
              return (
                <Skeleton
                  key={idx}
                  height={45}
                  variant='rect'
                  animation='wave'
                  style={{
                    background: '#b1afaf70',
                    marginTop: 15,
                    borderRadius: 2,
                  }}
                />
              );
            })}
          </Box>
        ) : (
          <List className={classes.listStyle}>{mainListItems}</List>
        )}
      </Box>
    );
  }
}

export default withStyles(styles)(ListItems);
