import React, { Component } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

import netjeekServer from 'api/netjeek';
import { toast } from 'react-toastify';
import Skeleton from '@material-ui/lab/Skeleton';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const errCodesMapper = {
  1001: 'Exchange rate is missing',
  3000: 'Exchange rate is not valid',
  3001: 'Exchange rate must be a positive number',
  2100: 'Something went wrong!',
  5000: 'Unauthenticated',
  5001: 'Unauthorized',
  6000: "ID doesn't exist",
  666: 'Network Error. Please Try Again!',
};
const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  cardStyle: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  button: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
});

/**
 * Component for Currency Exchange.
 *
 * @component
 * @description
 * Well, Hello there!
 */

class CurrencyExchange extends Component {
  state = {
    exchangeRate: '',
    didDataLoad: false,
    isLoading: false,
    usdToLyd: '',
    isUsdToLydLoading: false,
    lydToCad: '',
    isLydToCadLoading: false
  };
  constructor(props) {
    super(props);
    this.createShipmentRef = React.createRef();
  }

  componentDidMount = () => {
    this.getExchangeRate();
    this.getAllCurrencyRates();
    ValidatorForm.addValidationRule('Number>0', (value) => value > 0);
    this.props.changePageHeader('Settings | Currency Exchange');
  };
  deleteShipment = () => {};

  getExchangeRate = async () => {
    try {
      const response = await netjeekServer.get(
        `/settings/cad-usd-exchange-rate`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        const exchangeRate = response.data.data.exchangeRate.rate;
        this.setState({ exchangeRate, didDataLoad: true });
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];

      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 6000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      this.setState({ isLoading: false, exchangeRate: '' });
    }
  };
  getAllCurrencyRates = async () => {
    try {
      const response = await netjeekServer.get(`/settings/currency-converter`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response) {
        const usdToLyd = response.data.data[0].value;
        const lydToCad = response.data.data[1].value;
        this.setState({ usdToLyd, lydToCad, didDataLoad: true });
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];

      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 6000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      this.setState({ isLoading: false, exchangeRate: '' });
    }
  };
  handleChange = (event) => {
    this.setState({ exchangeRate: event.target.value });
  };
  handleusdToLydChange = (event) => {
    this.setState({ usdToLyd: event.target.value });
  };
  handleLydToCadChange = (event) => {
    this.setState({ lydToCad: event.target.value });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { exchangeRate } = this.state;
    try {
      const response = await netjeekServer.post(
        'settings/cad-usd-exchange-rate',
        {
          exchangeRate,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        this.props.setSuccessMessages(['Exchange Rate Updated Successfully.']);
        this.getExchangeRate();
      }
    } catch (err) {}
    this.setState({ isLoading: false });
  };
  handleUsdToLydSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isUsdToLydLoading: true });
    const { usdToLyd } = this.state;
    try {
      const response = await netjeekServer.patch(
        'settings/currency-converter',
        {
          from: 'usd',
          to: 'lyd',
          value: usdToLyd,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        this.props.setSuccessMessages(['Exchange Rate Updated Successfully.']);
        this.getAllCurrencyRates();
      }
    } catch (err) {}
    this.setState({ isUsdToLydLoading: false });
  };
  handleLydToCadSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLydToCadLoading: true });
    const { lydToCad } = this.state;
    try {
      const response = await netjeekServer.patch(
        'settings/currency-converter',
        {
          from: 'lyd',
          to: 'cad',
          value: lydToCad,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        this.props.setSuccessMessages(['Exchange Rate Updated Successfully.']);
        this.getAllCurrencyRates();
      }
    } catch (err) {}
    this.setState({ isLydToCadLoading: false });
  };
  render() {
    const { classes } = this.props;
    const {
      isLoading,
      exchangeRate,
      didDataLoad,
      usdToLyd,
      isUsdToLydLoading,
      lydToCad,
      isLydToCadLoading
    } = this.state;
    return (
      <Grid
        container
        // direction='column'
        justify='center'
        className={classes.root}
      >
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <br></br>
        </Grid>
        {!didDataLoad ? (
          <Grid item xs={12}>
            <Skeleton
              variant='rect'
              height={180}
              className={classes.cardStyle}
            />
          </Grid>
        ) : (
          <Grid container style={{ marginBottom: '8px' }} spacing={2}>
            <Grid item xs={12}>
              <ValidatorForm onSubmit={this.handleSubmit} ref='form'>
                <Paper className={classes.cardStyle}>
                  <Typography variant='h5' component='h3' gutterBottom>
                    CAD to USD
                  </Typography>
                  <Box mt={2}>
                    <TextValidator
                      autoFocus
                      fullWidth
                      id='currency-exchange-input'
                      label='Exchange Rate'
                      color='secondary'
                      name='exchangeRate'
                      validators={['Number>0', 'required']}
                      errorMessages={[
                        'Exchange Rate must be a positive number greater than 0',
                        'Exchange Rate Time is required',
                      ]}
                      value={exchangeRate}
                      onChange={this.handleChange}
                      type='number'
                      inputProps={{
                        step: 0.01,
                      }}
                    />
                  </Box>
                  <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      disabled={isLoading}
                      variant='contained'
                      color='primary'
                      type='submit'
                      className={classes.button}
                      // onClick={event => this.handleSubmit(event)}
                    >
                      Save
                      {isLoading && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 4, position: 'absolute' }}
                        />
                      )}
                    </Button>
                  </Box>
                </Paper>
              </ValidatorForm>
            </Grid>
            <Grid item xs={12}>
              <ValidatorForm onSubmit={this.handleUsdToLydSubmit} ref='form'>
                <Paper className={classes.cardStyle}>
                  <Typography variant='h5' component='h3' gutterBottom>
                    USD to LYD
                  </Typography>
                  <Box mt={2}>
                    <TextValidator
                      autoFocus
                      fullWidth
                      id='currency-exchange-input'
                      label='Exchange Rate'
                      color='secondary'
                      name='exchangeRate'
                      validators={['Number>0', 'required']}
                      errorMessages={[
                        'Exchange Rate must be a positive number greater than 0',
                        'Exchange Rate Time is required',
                      ]}
                      value={usdToLyd}
                      onChange={this.handleusdToLydChange}
                      type='number'
                      inputProps={{
                        step: 0.01,
                      }}
                    />
                  </Box>
                  <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      disabled={isUsdToLydLoading}
                      variant='contained'
                      color='primary'
                      type='submit'
                      className={classes.button}
                      // onClick={event => this.handleSubmit(event)}
                    >
                      Save
                      {isUsdToLydLoading && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 4, position: 'absolute' }}
                        />
                      )}
                    </Button>
                  </Box>
                </Paper>
              </ValidatorForm>
            </Grid>
            <Grid item xs={12}>
              <ValidatorForm onSubmit={this.handleLydToCadSubmit} ref='form'>
                <Paper className={classes.cardStyle}>
                  <Typography variant='h5' component='h3' gutterBottom>
                  LYD to CAD
                  </Typography>
                  <Box mt={2}>
                    <TextValidator
                      autoFocus
                      fullWidth
                      id='currency-exchange-input'
                      label='Exchange Rate'
                      color='secondary'
                      name='exchangeRate'
                      validators={['Number>0', 'required']}
                      errorMessages={[
                        'Exchange Rate must be a positive number greater than 0',
                        'Exchange Rate Time is required',
                      ]}
                      value={lydToCad}
                      onChange={this.handleLydToCadChange}
                      type='number'
                      inputProps={{
                        step: 0.01,
                      }}
                    />
                  </Box>
                  <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      disabled={isLydToCadLoading}
                      variant='contained'
                      color='primary'
                      type='submit'
                      className={classes.button}
                      // onClick={event => this.handleSubmit(event)}
                    >
                      Save
                      {isLydToCadLoading && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 4, position: 'absolute' }}
                        />
                      )}
                    </Button>
                  </Box>
                </Paper>
              </ValidatorForm>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}
export default withStyles(styles)(CurrencyExchange);
