import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core/';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import Autocomplete from '@material-ui/lab/Autocomplete';

const errCodesMapper = {
  1000: 'Driver is missing',
  4001: 'Driver is not valid',
  2100: 'Something went wrong',
  3000: 'Retailer must be one of [AMAZON, WALMART,ALIEXPRESS]',
  4000: 'Order does not exist.',
  4003: 'Order already assigned to driver',
  6000: 'Unauthorized Access',
  666: 'Network Error. Please Try Again!',
};
export default function AddNumberModal(props) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [driverData, setDriverData] = useState({
    name: '',
    id: '',
  });
  const [errMessages, setErrMessages] = useState([]);

  useEffect(() => {
    getDriversData();
    setDriverData({ name: '', id: '' });
  }, []);

  useEffect(() => {
    if (!open) setErrMessages([]);
  }, [open]);
  const parseDrivers = (resData) => {
    let tempDivers = [];
    resData.forEach((driver) => {
      tempDivers.push({
        name: driver.first_name + ' ' + driver.last_name,
        id: driver.id,
      });
    });
    setDrivers(tempDivers);
  };

  const getDriversData = async () => {
    try {
      const response = await netjeekServer.get(`/users/drivers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const resData = response.data.data;
      if (response) parseDrivers(resData);
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      setIsLoading(false);
      setErrMessages(errorMessages);
    }
    setIsLoading(false);
  };
  const handleChangeDriver = (newValue) => {
    if (newValue) setDriverData(newValue);
    else setDriverData({ name: '', id: '' });
  };

  const addNumber = async () => {
    const orderId = props.orderData.id;
    setIsLoading(true);
    try {
      const response = await netjeekServer.post(
        `/orders/${orderId}/assing-to-driver`,
        {
          driver_id: driverData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        props.setSuccessMessages([`Order Assigned to Driver Successfully`]);
        props.getOrderData(orderId);
        setOpen(false);
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      setIsLoading(false);
      setErrMessages(errorMessages);
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <Button
        size='small'
        variant='contained'
        color='primary'
        onClick={() => setOpen(true)}
      >
        Assign to Driver
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='addNumber-dialog-title'
        fullWidth
      >
        <DialogTitle id='addNumber-dialog-title'>Assign To Driver</DialogTitle>
        <Snackbar type='error' messages={errMessages} />
        <ValidatorForm onSubmit={addNumber}>
          <DialogContent>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  fontWeight: 500,
                  width: '30%',
                }}
              >
                Driver Name:
              </Typography>
              <Autocomplete
                id='regionSelect'
                options={drivers}
                getOptionLabel={(option) => option.name}
                style={{ width: '100%' }}
                onChange={(event, newValue) => {
                  handleChangeDriver(newValue);
                }}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    margin='dense'
                    validators={['required']}
                    errorMessages={['Driver is required']}
                    variant='outlined'
                    fullWidth
                    value={driverData.name}
                    style={{ marginBottom: '10px' }}
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions style={{ margin: 10 }}>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              style={{ color: '#000', fontSize: '16px' }}
            >
              Cancel
            </Button>
            <Button
              style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
              disabled={isLoading || !driverData.name}
              type='submit'
              variant='contained'
              color='primary'
            >
              Assign
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </Box>
  );
}
