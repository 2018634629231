import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import {
  SnackbarContent,
  makeStyles,
  Box,
  IconButton,
  Snackbar
} from '@material-ui/core/';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, messages, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      style={{ marginBottom: 5 }}
      className={clsx(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Box style={{ alignItems: 'center', display: 'flex' }}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
          </Box>
          {messages.length === 1 && <Box my={1}>{messages[0]}</Box>}
          {messages.length > 1 && (
            <ul>
              {messages.map((message, idx) => {
                return (
                  <li key={idx} id='client-snackbar'>
                    {message}
                  </li>
                );
              })}
            </ul>
          )}
        </Box>
      }
      action={
        variant === 'success'
          ? [
              <IconButton
                key='close'
                aria-label='close'
                color='inherit'
                onClick={onClose}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
            ]
          : []
      }
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
};

const useStyles2 = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}));

export default function CustomizedSnackbars(props) {
  const classes = useStyles2();
  const [open, setOpen] = React.useState(true);

  const { type, messages } = props;

  const handleClose = (event, reason) => {
    setOpen(false);
  };
  useEffect(() => {
    setOpen(true);
  }, [messages]);
  if (!(messages.length > 0)) {
    return <Box></Box>;
  }
  if (type === 'success') {
    return (
      <Snackbar
        open={messages.length > 0 && open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant='success'
          className={classes.margin}
          messages={messages}
        />
      </Snackbar>
    );
  } else {
    return (
      <div>
        <MySnackbarContentWrapper
          variant={type}
          // autoHideDuration={3000}
          className={classes.margin}
          messages={messages}
        />
      </div>
    );
  }
}
