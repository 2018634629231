/**
 * @module OrderDetails React functional component that contains all the order details components,
 */
import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import DataCard from 'components/DataCard/DataCard';
import TableComponent from 'components/Table/TableComponent';
import OrderTraceCard from 'components/TraceCard/OrderTraceCard';
import netjeekServer from 'api/netjeek';
import { toast } from 'react-toastify';
import moment from 'moment';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import orderStatusMapper from 'shared/orderStatusMapper';
import EditAdditionalFees from 'components/Orders/EditAdditionalFees';
const errCodesMapper = {
  3000: 'ID is not valid',
  4000: 'Order was not found',
  5001: 'Unauthorized',
  6000: "ID doesn't exist",
  666: 'Network Error. Please Try Again!',
};
const tableFields = {
  head: [
    'Image',
    'Item Name',
    'Variant',
    'E-Retailer',
    'Quantity Ordered',
    'Quantity Received',
    'Price',
    'Action',
  ],
  dataKeys: [
    'image_path',
    'item_name',
    'variant',
    'retailer_name',
    'quantity',
    'qty_received',
    'price',
    'printModalAction',
  ],
  search: [
    null,
    'itemName',
    'variant',
    'eRetailer',
    'quantity',
    'qty_received',
    'price',
    null,
  ],
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
    flexGrow: true,
  },
  tableHolderStyle: {
    padding: theme.spacing(1.5),
    paddingBottom: 0,
  },
  headerStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tabelCardHeaderStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  linkStyle: {
    color: '#00f',
    fontSize: theme.spacing(2),
  },
}));

export default function OrderDetails(props) {
  const classes = useStyles();
  const [orderData, setOrderData] = useState({});
  const [basicData, setBasicData] = useState({});
  const [orderPricingData, setOrderPricingData] = useState({});
  const [shippingAddressData, setShippingAddressData] = useState({});
  const [orderStatusData, setOrderStatusData] = useState([]);
  const [geo_location, setGeo_location] = useState({});
  const orderId = props.match.params.id;

  useEffect(() => {
    props.changePageHeader('Order Details');
    getOrderData(orderId);
  }, []);

  const parseData = (orderData) => {
    console.log('orderData', orderData);
    const {
      id,
      order_id,
      customer_id,
      customer,
      createdAt,
      eta,
      status,
      sub_total,
      total,
      shipping_fees,
      order_discounts,
      additional_fees,
      additional_fees_notes,
      original_shipping_fees,
      order_trace,
    } = orderData;
    setBasicData({
      orderNumber: order_id ? order_id : '',
      customer_id,
      customer: `${customer.account_number.toUpperCase()} - ${
        customer.first_name
      }  ${customer.last_name}`,
      date: moment(createdAt).format(process.env.REACT_APP_DATE_FORMAT),
      eta: moment(eta).format(process.env.REACT_APP_DATE_FORMAT),
      status: orderStatusMapper[status],
    });
    let tempOrderPricing = {
      id: id,
      sub_total: numeral(Number(sub_total)).format('($0.00 a)'),
      additional_fees: numeral(Number(additional_fees)).format('($0.00 a)'),
      additional_fees_notes: additional_fees_notes,
    };

    if (order_discounts.length) {
      let tempTotal = sub_total + shipping_fees +  additional_fees
      order_discounts.forEach(discount => {
      if(discount.type.toLowerCase() === 'promo'){
        tempOrderPricing['promo_code'] = discount.code;
        tempOrderPricing['promo_value'] = numeral(Number(discount.amount)).format('($0.00 a)')
        tempOrderPricing['original_shipping_fees'] = numeral(Number(original_shipping_fees)).format('($0.00 a)')
      }else{
        tempOrderPricing['voucher_code'] = discount.code;
        tempOrderPricing['voucher_value'] = numeral(Number(discount.amount)).format('($0.00 a)')
        tempTotal -= parseFloat(discount.amount);
      }
      });
      tempOrderPricing['shipping_fees'] = numeral(Number(shipping_fees)).format('($0.00 a)');
      tempOrderPricing['total'] = numeral(tempTotal < 0 ? 0 : tempTotal).format(
        '($0.0)'
      ); 

    } else {
      tempOrderPricing['shipping_fees'] = numeral(Number(shipping_fees)).format('($0.00 a)');
      tempOrderPricing['total'] = numeral(
        Number(sub_total + shipping_fees +  additional_fees)
      ).format('($0.0)');
    }
    setOrderPricingData(tempOrderPricing);

    const {
      street_address,
      city,
      state,
      // country,
      zip,
      nearest_landmark,
      geo_location,
    } = orderData.shipping_address;

    setShippingAddressData({
      street_address,
      city: city ? city[0].toUpperCase() + city.substr(1) : '',
      state: state ? state[0].toUpperCase() + state.substr(1) : '',
      country: 'Libya',
      zip,
      nearest_landmark,
      // geo_location,
    });
    setGeo_location(geo_location);
    setOrderStatusData(order_trace);
  };

  const getOrderData = async (orderId) => {
    try {
      const response = await netjeekServer.get(`/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const resData = response.data.data;
      setOrderData(resData);
      parseData(resData);
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];

      if (err.code === 'ECONNABORTED') {
        // toast.dismiss();
        toast.update(errCodesMapper['666'], {
          toastId: 1,
          type: 'error',
        });
      } else {
        if (err.response) {
          const isError = err.response ? err.response.data.error : null;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                // toast.dismiss();
                toast.update(
                  'Your session has expired. please sign in again.',
                  {
                    type: 'error',
                  }
                );
                localStorage.clear();
                props.history.push('/signin');
              }
            });
          }
        }
      }
    }
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} className={classes.headerStyle}>
        <Link
          to={`/admin-portal/orders/orders-management`}
          className={classes.linkStyle}
        >{`< Back to List`}</Link>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={6}
        spacing={2}
        direction='column'
        style={{ flexWrap: 'nowrap' }}
      >
        <Grid item xs={12} style={{ flexBasis: 'auto' }}>
          <DataCard {...props} title='Basic Data' data={basicData} />
        </Grid>
        <Grid item xs={12} style={{ flexBasis: 'auto' }}>
          <DataCard
            {...props}
            title='Order Pricing'
            data={orderPricingData}
            isEditable
            editButtonLable='Edit Additional Fees'
            EditComponent={EditAdditionalFees}
            getOrderData={getOrderData}
          />
        </Grid>
        <Grid item xs={12} style={{ flexBasis: 'auto' }}>
          <DataCard
            {...props}
            title='Shipping Address'
            geo_location={geo_location}
            data={shippingAddressData}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={6}>
        <OrderTraceCard
          {...props}
          traceData={orderStatusData}
          orderData={orderData}
          getOrderData={getOrderData}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.tableHolderStyle} elevation={1}>
          <div className={classes.tabelCardHeaderStyle}>
            <Typography variant='h5' component='h3' gutterBottom>
              Items
            </Typography>
          </div>
          <TableComponent
            {...props}
            dataRoute={`orders/${orderId}/items`}
            selectable={true}
            searchable={false}
            tableFields={tableFields}
            pagination={true}
            rowsPerPage={3}
            rowsPerPageOptions={[3, 6, 12]}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
