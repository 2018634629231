import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import AssignToDriverModal from 'components/OrderTraceCardActions/AssignToDriver';
import ShipToMisrataModal from 'components/OrderTraceCardActions/ShipToMisrata';
import ShipToBenghaziModal from 'components/OrderTraceCardActions/ShipToBenghazi';

const options = ['Assign to Driver', 'Ship to Misrata', 'Ship to Benghazi'];

export default function SplitButton(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const getButton = (selectedIndex) => {
    switch (selectedIndex) {
      case 0:
        return <AssignToDriverModal {...props} />;
      case 1:
        return <ShipToMisrataModal {...props} />;
      case 2:
        return <ShipToBenghaziModal {...props} />;
      default:
        return null;
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    // <Grid direction='column' alignItems='flex-end'>
    //   <Grid item xs={12}>
    <Box>
      <Box
        style={{ display: 'flex', alignItems: 'center' }}
        ref={anchorRef}
        aria-label='split button'
      >
        {getButton(selectedIndex)}
        <Button
          color='primary'
          style={{ minWidth: '5px', padding: '3px 6px' }}
          size='small'
          variant='contained'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu'>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
