import React, { Component } from 'react';
import { Grid, Paper, Tooltip } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import ShortcutCard from 'components/ShortCuts/ShortcutCard';
import AddShortcut from 'components/ShortCuts/AddShortcut';
import netjeekServer from 'api/netjeek';
import { toast } from 'react-toastify';
import { Skeleton, SpeedDial, SpeedDialIcon } from '@material-ui/lab';

const errCodesMapper = {
  1000: 'Quantity is missing',
  3000: 'Quantity is not valid',
  666: 'Network Error. Please Try Again!',
};

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    flexGrow: true,
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(6),
      right: theme.spacing(6),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  // paperCard: { height: theme.spacing(55) }
});

class Shortcuts extends Component {
  state = {
    shortcuts: [],
    isLoading: false,
    isButtonLoading: false,
    maxItems: 8,
    isAdddisabled: true,
  };

  componentDidMount = () => {
    this.props.changePageHeader('Settings | Shortcuts');
    this.getShortcuts();
  };

  constructor(props) {
    super(props);
    this.addShortcutRef = React.createRef();
  }
  disableAdd = (count) => {
    const maxItems = this.state.maxItems;
    if (count >= maxItems) this.setState({ isAdddisabled: true });
    else this.setState({ isAdddisabled: false });
  };
  getShortcuts = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await netjeekServer.get(`/settings/shortcuts`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const resData = response.data.data;

      this.disableAdd(resData.length);
      this.setState({ shortcuts: resData, isLoading: false });
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];

      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      this.setState({ shortcuts: [], isLoading: false });
    }
  };
  render() {
    const { classes } = this.props;
    const { shortcuts, isLoading, isAdddisabled } = this.state;
    return (
      <Grid container className={classes.root}>
        <AddShortcut
          {...this.props}
          // shortcutData={{}}
          ref={this.addShortcutRef}
          getShortcuts={this.getShortcuts}
        />
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '25px',
          }}
        >
          <br />
          <Tooltip placement='left' title='Add Shortcut'>
            <SpeedDial
              open={false}
              ariaLabel='SpeedDialShortCuts'
              className={classes.speedDial}
              icon={<SpeedDialIcon />}
              FabProps={{ disabled: isAdddisabled }}
              onClick={() => this.addShortcutRef.current.setOpen(true)}
            />
          </Tooltip>
        </Grid>

        {isLoading ? (
          <Grid
            container
            spacing={2}
            style={{ marginBottom: 15, marginTop: 15 }}
          >
            {[...Array(4)].map((_item, idx) => {
              return (
                <Grid item key={idx} xs={12} md={6} lg={3}>
                  <Skeleton
                    id={idx}
                    // disableAnimate={idx > 5}
                    variant='rect'
                    animation='wave'
                    height={390}
                    style={{ borderRadius: 5 }}
                  ></Skeleton>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            style={{
              marginBottom: 5,
              paddingLeft: '15px',
              paddingRight: '15px',
            }}
          >
            {shortcuts.map((shortcut, idx) => {
              return (
                <Grid key={idx} item xs={12} md={3} lg={3}>
                  <Paper className={classes.paperCard}>
                    <ShortcutCard
                      setSuccessMessages={this.props.setSuccessMessages}
                      getShortcuts={this.getShortcuts}
                      shortcutData={shortcut}
                      shortcutId={shortcut.id}
                    />
                    {/* <ImageDropzone /> */}
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    );
  }
}
export default withStyles(styles)(Shortcuts);
