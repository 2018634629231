const shipmentStatusMapper = {
  CREATED: 'Created',
  PACKED: 'Packed',
  SHIPPED: 'Shipped',
  RECEIVED_IN_TRANSIT_HUB: 'Received In Transit Hub',
  SHIPPED_FROM_TRANSIT_HUB: 'Shipped From Transit Hub',
  RECEIVED_IN_TRIPOLI: 'Received In Tripoli'
};

export default shipmentStatusMapper;
