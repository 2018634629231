/**
 * @module DataCard
 */
import React, { Component } from 'react';
import {
  Paper,
  Typography,
  FormControl,
  Button,
  Box,
  Popper,
  Fade,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';
import GeoMap from 'components/GeoMap/GeoMap';
import InfoIcon from '@material-ui/icons/Info';

const fieldsNameMapper = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'E-mail',
  phone: 'Phone',
  status: 'Status',
  city: 'City',
  isActive: 'Status',
  account_number: 'Account Number',
  country: 'Country',
  nearest_landmark: 'Nearest Landmark',
  state: 'Region',
  street_address: 'Street Address',
  zip: 'PO Box',
  orderNumber: 'Order Number',
  customer: 'Customer',
  date: 'Placement Date',
  orderValue: 'Order Value',
  number: 'Number',
  shippingDate: 'Shipment Date',
  eta: 'Netjeek ETA',
  method: 'Shipping Method',
  notes: 'Notes',
  promo_code:"Promo Code",
  promo_value: "Discount Value",
  discount_code: 'Discount Code',
  voucher_code:"Voucher Code",
  voucher_value:"Voucher Value",
  original_shipping_fees: "Original Shipping Fees",
  sub_total: 'Order Price',
  shipping_fees: 'Shipping Fees',
  additional_fees: 'Additional Fees',
  courier_number: 'Courier Number',
  total: 'Total Price',
};
const styles = (theme) => ({
  form: {
    margin: theme.spacing(1),
    width: '100%',
  },
  field: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
  },
  fieldLable: {
    width: '43%',
    fontWeight: 600,
    fontSize: '16px',
    color: '#000',
    lineHeight: 1.5,
  },
  card: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3.5),
  },
  typography: {
    padding: theme.spacing(2),
  },
});
/**
 * @component
 * @classdesc DataCard component renders the passed data
 * @param {Object} props Component props.
 * @param {Object } props.data
 * @param {Object } props.classes
 * @param {string } props.title
 * @param {boolean } props.isEditable
 * @param {function | class } props.EditComponent
 * @requires {@link GeoMap}
 * @see {@link OrderDetails}
 */

class DataCard extends Component {
  state = {
    isDataLoaded: false,
    data: {},
    additional_fees_notes: '',
    isInfoOpen: false,
    infoAncherEl: null,
  };

  constructor(props) {
    super(props);
    this.editDataRef = React.createRef();
    this.mapRef = React.createRef();
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.data !== prevProps.data) {
      const data = this.props.data;

      if (Object.keys(data).length !== 0) {
        if (data.id) {
          let dataId = data.id;
          await this.setState({ dataId });
        }
        if (data.customer_id) {
          let customerId = data.customer_id;
          await this.setState({ customerId });
        }
        if (data.courier_number === '') {
          delete data.courier_number;
        }
        if (data.additional_fees_notes) {
          let additional_fees_notes = data.additional_fees_notes;
          await this.setState({ additional_fees_notes });
          // delete data.additional_fees_notes;
        }

        this.setState({ data: this.props.data });
      }
      this.setState({ isDataLoaded: true });
    }
  };
  handleInfoClick = (event) => {
    this.setState({
      infoAncherEl: event.currentTarget,
      isInfoOpen: !this.state.isInfoOpen,
    });
  };
  renderAdditionalFeesNotes = (item) => {
    return (
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {this.state.data[item]}
        {this.state.additional_fees_notes && item === 'additional_fees' ? (
          <Box>
            <Button
              style={{
                minWidth: '5px',
                padding: 0,
                marginLeft: '5px',
              }}
              onMouseEnter={this.handleInfoClick}
              onMouseLeave={this.handleInfoClick}
            >
              <InfoIcon />
            </Button>
            <Popper
              open={this.state.isInfoOpen}
              anchorEl={this.state.infoAncherEl}
              placement='right'
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography className={this.props.classes.typography}>
                      {this.state.additional_fees_notes}
                    </Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </Box>
        ) : null}
      </Box>
    );
  };

  render() {
    const {
      classes,
      title,
      isEditable,
      EditComponent,
      editButtonLable,
      geo_location,
      userData,
    } = this.props;
    const { data, dataId, isDataLoaded, customerId } = this.state;
    const dataKeys = data ? Object.keys(data) : [];
    return (
      <Paper className={classes.card}>
        {isDataLoaded ? (
          <Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h5' component='h3' gutterBottom>
                {title}
              </Typography>
              {title === 'Shipping Address' &&
              Object.keys(geo_location).length ? (
                <Box>
                  <Button
                    color='default'
                    variant='outlined'
                    style={{ width: 110, padding: '6.4px' }}
                    onClick={() => this.mapRef.current.setOpen(true)}
                  >
                    Show Map ⇪
                  </Button>

                  <GeoMap
                    ref={this.mapRef}
                    geo_location={geo_location}
                    height={560}
                  />
                </Box>
              ) : (
                <div></div>
              )}
              {isEditable && userData.role !== 'support' && (
                <Button
                  size='small'
                  color='primary'
                  variant='contained'
                  style={{ padding: '4px 7px' }}
                  onClick={() => this.editDataRef.current.setOpen(true)}
                >
                  {editButtonLable ? editButtonLable : 'Edit'}
                </Button>
              )}
            </Box>
            <FormControl className={classes.form}>
              {dataKeys.map((item, idx) => {
                if (
                  item !== 'additional_fees_notes' &&
                  item !== 'id' &&
                  item !== 'orderId' &&
                  item !== 'customer_id'
                )
                  return (
                    <Box className={classes.field} key={idx}>
                      <Typography className={classes.fieldLable}>
                        {fieldsNameMapper[item]}:
                      </Typography>
                      <Typography
                        variant='body1'
                        style={{
                          width: '100%',
                          whiteSpace: 'prewrap',
                          color:
                            item === 'number' ||
                            item === 'orderNumber' ||
                            item === 'customer' ||
                            item === 'account_number'
                              ? '#00f'
                              : '#000',
                          marginLeft: item === 'number' ? 5 : 0,
                        }}
                      >
                        {item === 'customer' &&
                        userData.role !== 'logistics' ? (
                          <Link
                            style={{
                              color: '#00f',
                              cursor: 'pointer',
                            }}
                            to={`/admin-portal/customer-profile/${customerId}`}
                          >
                            {data[item]}
                          </Link>
                        ) : item === 'additional_fees' ? (
                          this.renderAdditionalFeesNotes(item)
                        ) : (
                          data[item]
                        )}
                      </Typography>
                    </Box>
                  );
                else return null;
              })}
            </FormControl>
          </Box>
        ) : (
          <Box style={{ height: '100%', width: '100%' }}>
            <Skeleton variant='rect' width={180} height={38} />
            {[0, 1, 2, 3, 4].map((item) => (
              <Box key={item} my={2}>
                <Skeleton variant='rect' height={25} />
              </Box>
            ))}
          </Box>
        )}

        {isEditable && (
          <EditComponent
            {...this.props}
            data={data}
            dataId={dataId}
            ref={this.editDataRef}
          />
        )}
      </Paper>
    );
  }
}
export default withStyles(styles)(DataCard);
