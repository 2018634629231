import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  InputAdornment,
  CircularProgress,
  Typography,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';

const errCodesMapper = {
  2100: 'Something went wrong',
  3000: 'Region is not valid.',
  3001: 'City is not valid/',
  3002: 'Price Per Kg is not valid',
  // '3002': 'Price Per Kg must be a positive number',
  3003: 'Delivery Time is not valid.',
  4000: 'Shipping fees for this city/region already exist',
  666: 'Network Error. Please Try Again!',
};
const CityinRegion = {
  Tripoli: [
    'Tripoli',
    'Zawiya',
    'Sabratha',
    'Zuwara',
    'Tajura',
    'Tarhuna',
    'Gharyan',
    'Msallata',
    'Jumayl',
    'Sorman',
    'Algseibat',
    'Espiaa',
    'Regdalin',
    'Gasrakhyar',
    'Alqubah',
    'Almaya',
    'Alajaylat',
    'Nalut',
    'Zaltan',
    'Mizda',
    'Alurban',
    'Yafran',
    'Arrayaniya',
    'Teji',
    'Kikla',
    'Alrheibat',
    'Jadu',
    'Ghadames',
    'Arrajban',
    'Mashashita',
    'Zintan',
  ],
  Benghazi: [
    'Benghazi',
    'Bayda',
    'Ajdabiya',
    'Tobruk',
    'Derna',
    'Kufra',
    'Marj',
    'Shahhat',
    'Elagheila',
    'Abyar',
    'Nofaliya',
    'Brega',
    'Jalu',
    'Suluq',
    'Raslanuf',
    'Ummal Rizam',
    'Tocra',
    'Qaminis',
    'Benina',
    'Massah',
    'Binjawad',
    'Alabraq',
    'Awjila',
    'Tolmeita',
    'Zella',
    'Martuba',
    'Qayqab',
    'Musaid',
    'Tacnis',
    'Susa',
    'Tazirbu',
  ],
  Misrata: ['Misrata', 'Zliten', 'Khoms', 'Sirte', 'Baniwalid', 'Tawergha'],
  Sabha: [
    'Sabha',
    'Ubari',
    'Waddan',
    'Murzuk',
    'Hun',
    'Brak',
    'Qatrun',
    'Sokna',
    'Ummalaranib',
    'Ghat',
    'Traghan',
  ],
};
const regions = ['Tripoli', 'Benghazi', 'Misrata', 'Sabha'];

export default class EditShippingFees extends Component {
  state = {
    open: false,
    isLoading: false,
    errMessages: [],
    shippingFeeData: {
      region: '',
      city: '',
      pricePerKg: '',
      avgDT: '',
    },
    updatedShippingFeeData: {},
  };

  componentDidMount = () => {
    ValidatorForm.addValidationRule('Number>0', (value) => value > 0);
    ValidatorForm.addValidationRule('Number>=0', (value) => value >= 0);
  };
  setOpen = (state) => {
    this.setState({ open: state });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.itemData !== prevProps.itemData) {
      this.setState({ shippingFeeData: { ...this.props.itemData } });
    }
  };

  updateShippingFee = async (event) => {
    event.preventDefault();
    const { updatedShippingFeeData } = this.state;
    const shippingFeeId = this.props.itemData.id;
    this.setState({ isLoading: true, errMessages: [] });

    try {
      const response = await netjeekServer.patch(
        `/settings/shipping-fees/${shippingFeeId}`,
        updatedShippingFeeData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        this.props.setSuccessMessages(['Shipping Fee Updated Successfully']);
        this.setState({ errMessages: [], open: false });
        this.props.getItems();
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      this.setState({ isLoading: false, errMessages: errorMessages });
    }
    this.setState({ isLoading: false });
  };

  handleChange = (event) => {
    const name = event.target.name;

    const value = event.target.value;

    const updatedShippingFeeData = { ...this.state.updatedShippingFeeData };
    const shippingFeeData = { ...this.state.shippingFeeData };
    updatedShippingFeeData[name] = value;
    shippingFeeData[name] = value;
    this.setState({ shippingFeeData, updatedShippingFeeData });
  };

  handleChangeRegion = (newValue) => {
    const updatedShippingFeeData = { ...this.state.updatedShippingFeeData };
    const shippingFeeData = { ...this.state.shippingFeeData };
    shippingFeeData['region'] = newValue;
    shippingFeeData['city'] = '';
    updatedShippingFeeData['region'] = newValue;
    this.setState({ shippingFeeData, updatedShippingFeeData });
  };
  handleChangeCity = (newValue) => {
    const updatedShippingFeeData = { ...this.state.updatedShippingFeeData };
    const shippingFeeData = { ...this.state.shippingFeeData };
    shippingFeeData['city'] = newValue;
    updatedShippingFeeData['city'] = newValue;
    this.setState({ updatedShippingFeeData, shippingFeeData });
  };

  render() {
    const { open, errMessages, shippingFeeData, isLoading } = this.state;

    return (
      <Dialog
        open={open}
        onClose={() => this.setOpen(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          <Typography variant='h5'>Edit Shipping Fees</Typography>
        </DialogTitle>
        <Snackbar type='error' messages={errMessages} />
        <ValidatorForm
          ref='form'
          onSubmit={this.updateShippingFee}
          style={{ width: 600 }}
        >
          <DialogContent>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                // className={classes.inputLab}
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Region:
              </Typography>
              <Autocomplete
                id='regionSelect'
                options={regions}
                getOptionLabel={(option) => option}
                style={{ width: '100%' }}
                // classes={{ inputRoot: classes.inputStyle }}
                value={shippingFeeData.region}
                onChange={(event, newValue) => {
                  this.handleChangeRegion(newValue);
                }}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    margin='dense'
                    validators={['required']}
                    errorMessages={['Region is required']}
                    variant='outlined'
                    fullWidth
                    value={shippingFeeData.region}
                    style={{ marginBottom: '10px' }}
                  />
                )}
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                City:
              </Typography>
              <Autocomplete
                id='citySelect'
                options={CityinRegion[shippingFeeData.region]}
                getOptionLabel={(option) => option}
                style={{ width: '100%' }}
                // classes={{ inputRoot: classes.inputStyle }}
                value={shippingFeeData.city}
                onChange={(event, newValue) => {
                  this.handleChangeCity(newValue);
                }}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    margin='dense'
                    validators={['required']}
                    errorMessages={['City is required']}
                    variant='outlined'
                    fullWidth
                    value={shippingFeeData.city}
                    style={{ marginBottom: '10px' }}
                  />
                )}
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                Price Per Kg:
              </Typography>
              <TextValidator
                fullWidth
                name='pricePerKg'
                margin='dense'
                type='number'
                id='pricePerKgInput'
                validators={['Number>=0', 'required']}
                errorMessages={[
                  'Price must be a positive number',
                  'Price is required',
                ]}
                value={shippingFeeData.pricePerKg}
                onChange={this.handleChange}
                InputProps={{
                  step: 0.1,
                  endAdornment: (
                    <InputAdornment position='start'>USD</InputAdornment>
                  ),
                }}
                variant='outlined'
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Delivery Time:
              </Typography>
              <TextValidator
                fullWidth
                name='avgDT'
                margin='dense'
                type='number'
                id='avgDTInput'
                validators={['Number>0', 'required']}
                errorMessages={[
                  'Day(s) must be a positive number greater than 0',
                  'Delivery Time is required',
                ]}
                value={shippingFeeData.avgDT}
                onChange={this.handleChange}
                InputProps={{
                  step: 1,
                  endAdornment: (
                    <InputAdornment position='start'>Days</InputAdornment>
                  ),
                }}
                variant='outlined'
              />
            </Box>
          </DialogContent>
          <DialogActions style={{ margin: 10 }}>
            <Button
              onClick={() => {
                this.setOpen(false);
              }}
              style={{ color: '#000', fontSize: '16px' }}
            >
              Cancel
            </Button>
            <Button
              style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
              disabled={isLoading}
              type='submit'
              variant='contained'
              color='primary'
              // onClick={event => this.createShipment(event)}
            >
              Update
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}
