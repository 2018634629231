import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core/';
// import { withStyles } from '@material-ui/core/styles';
import TableComponent from '../Table/TableComponent';
const tableFields = {
  head: [
    'Order Number',
    'Order Date',
    'Netjeek ETA',
    'Shipping Method',
    'Order Status',
  ],
  search: [
    'Order Number',
    'Date Range',
    'Date Range',
    'Method',
    'Order Status',
  ],
  dataKeys: ['order_id', 'createdAt', 'eta', 'shipping_method', 'status'],
};
const tableSelectOptions = [
  { value: 'ALL', label: 'ALL' },
  {
    label: 'Placed By Customer',
    value: 'PLACED_BY_CUSTOMER',
  },
  {
    label: 'Placed At Amazon',
    value: 'ORDER_PLACED_AMAZON',
  },
  {
    label: 'Placed At Walmart',
    value: 'ORDER_PLACED_WALMART',
  },
  {
    label: 'Placed At AliExpress',
    value: 'ORDER_PLACED_ALIEXPRESS',
  },
  {
    label: 'Partially Received In Canada',
    value: 'PARTIALLY_RECEIVED_CANADA',
  },
  {
    label: 'Fully Received In Canada',
    value: 'FULLY_RECEIVED_CANADA',
  },
  {
    label: 'Shipped',
    value: 'SHIPPED',
  },
  {
    label: 'In Transit',
    value: 'IN_TRANSIT',
  },
  {
    label: 'Shipped to Misrata',
    value: 'SHIPPED_TO_MISRATA',
  },
  {
    label: 'Shipped To Benghazi',
    value: 'Shipped to Benghazi',
  },
  {
    label: 'Received In Tripoli',
    value: 'RECEIVED_IN_TRIPOLI',
  },
  {
    label: 'Received In Misrata',
    value: 'RECEIVED_IN_MISRATA',
  },
  {
    label: 'Received In Benghazi',
    value: 'RECEIVED_IN_BENGHAZI',
  },
  {
    label: 'Assigned To Driver',
    value: 'ASSIGNED_TO_DRIVER',
  },
  {
    label: 'Out For Delivery',
    value: 'OUT_FOR_DELIVERY',
  },
  {
    label: 'Delivered',
    value: 'DELIVERED',
  },
  {
    label: 'Returned',
    value: 'RETURNED',
  },
  {
    label: 'Picked By Customer',
    value: 'PICKED_BY_CUSTOMER',
  },
  {
    label: 'Failed',
    value: 'FAILED',
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
  },

  tableHolderStyle: {
    padding: theme.spacing(1),
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export default function OrdersManagment(props) {
  const classes = useStyles();
  useEffect(() => {
    props.changePageHeader('Orders List');
  }, []);

  return (
    <Grid
      container
      direction='column'
      justify='center'
      className={classes.root}
    >
      <Grid item xs={12}>
        <br />
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.tableHolderStyle} elevation={1}>
          <TableComponent
            {...props}
            dataRoute='orders'
            selectable={true}
            searchable={true}
            tableFields={tableFields}
            selectOptions={tableSelectOptions}
            pagination={true}
            rowsPerPage={10}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
