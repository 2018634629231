import React, { Component } from 'react';
import {
  Button,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box,
  Typography,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
const errCodesMapper = {
  1000: 'Additional Fees is missing',
  2000: 'First Name 2 to 20',
  2001: 'Last Name 2 to 20',
  2002: 'E-mail max 255',
  2004: 'Status is not valid',
  2100: 'Something went wrong!. PLease Try again',
  3000: 'E-mail is not valid',
  5000: 'Customer was not found.',
  666: 'Network Error. Please Try Again!',
};
export default class EditCustomerProfile extends Component {
  state = {
    open: false,
    errorMessages: [],
    isLoading: false,
    successMessages: [],
    orderPricingData: this.props.data,
    updatedOrderPricingData: {},
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.data !== prevProps.data) {
      const data = { ...this.props.data };
      const updatedOrderPricingData = { ...this.state.updatedOrderPricingData };
      const additional_fees = parseFloat(data.additional_fees.substr(1));

      data.additional_fees = additional_fees;
      updatedOrderPricingData.additional_fees = additional_fees;
      this.setState({ orderPricingData: data, updatedOrderPricingData });
    }
  };

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
    if (state === false) this.setState({ updatedOrderPricingData: {} });
  };

  updateAdditionalFees = async (event) => {
    event.preventDefault();
    const { updatedOrderPricingData } = this.state;
    const orderId = this.props.dataId;
    this.setState({ successMessages: [], errorMessages: [] });
    if (Object.keys(updatedOrderPricingData).length === 0) return;
    this.setState({ isLoading: true });

    try {
      const response = await netjeekServer.post(
        `/orders/${orderId}/additional-fees`,
        {
          ...updatedOrderPricingData,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        this.props.setSuccessMessages(['Additional Fees Updated Successfully']);
        this.props.getOrderData(orderId);
        await this.setState({
          updatedOrderPricingData: {},
          errorMessages: [],
        });
        this.setOpen(false);
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (err.response.status === 404)
          errorMessages.push(errCodesMapper['666']);
        else if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
  };
  handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const updatedOrderPricingData = { ...this.state.updatedOrderPricingData };
    const orderPricingData = { ...this.state.orderPricingData };
    updatedOrderPricingData[name] = value;
    orderPricingData[name] = value;
    this.setState({ updatedOrderPricingData, orderPricingData });
  };

  render() {
    const {
      open,
      errorMessages,
      successMessages,
      isLoading,
      updatedOrderPricingData,
    } = this.state;
    const { orderPricingData } = this.state;

    return (
      <Dialog
        open={open}
        onClose={() => this.setOpen(false)}
        aria-labelledby='additionalFees-dialog'
      >
        <DialogTitle id='additionalFees-dialog-title'>
          Edit Additional Fees
        </DialogTitle>
        <Snackbar type='error' messages={errorMessages} />
        <Snackbar type='success' messages={successMessages} />
        <ValidatorForm
          ref='form'
          onSubmit={this.updateAdditionalFees}
          style={{ width: 600 }}
        >
          <DialogContent>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  fontWeight: 500,
                  width: '30%',
                }}
              >
                Additional Fees:
              </Typography>
              <TextValidator
                fullWidth
                autoFocus
                name='additional_fees'
                validators={['required']}
                errorMessages={['Additional Fees are required']}
                margin='dense'
                type='number'
                id='additional_fees'
                value={orderPricingData.additional_fees}
                onChange={this.handleChange}
                variant='outlined'
                InputProps={{
                  step: 0.1,
                  endAdornment: (
                    <InputAdornment position='start'>USD</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  fontWeight: 500,
                  width: '30%',
                }}
              >
                Notes:
              </Typography>
              <TextValidator
                fullWidth
                // validators={['required']}
                // errorMessages={[
                //   'Notes are required',
                // ]}
                name='additional_fees_notes'
                margin='dense'
                type='text'
                multiline
                rows={3}
                id='additional_fees_notes'
                value={orderPricingData.additional_fees_notes}
                onChange={this.handleChange}
                variant='outlined'
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setOpen(false);
              }}
              style={{ color: '#000', fontSize: '16px' }}
            >
              Cancel
            </Button>
            <Button
              style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
              disabled={
                isLoading || Object.keys(updatedOrderPricingData).length === 0
              }
              type='submit'
              variant='contained'
              color='primary'
              // onClick={event => this.updateShipmentData(event)}
            >
              Edit
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}
