import React, { useEffect } from 'react';
import netjeekServer from 'api/netjeek';
import { toast } from 'react-toastify';

const errCodesMapper = {
  1000: 'E-mail is missing',
  1001: 'Password is missing',
  3000: 'E-mail is not valid',
  666: 'Network Error. Please Try Again!',
};

export default function RedirectPage(props) {
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await netjeekServer.get('/users/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response) {
        const userData = response.data.data;
        const role = userData.role;
        if (role === 'admin') props.history.push('/admin-portal/dashboard');
        else if (role === 'support' || role === 'logistics')
          props.history.push('/admin-portal/orders/orders-management');
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
            if (error.code === 5000) {
              toast.error('Your session has expired. please sign in again.', {
                toastId: 1,
                containerId: 1,
              });
              localStorage.clear();
              props.history.push('/signin');
            }
          });
        }
      }
    }
  };
  return <div></div>;
}
