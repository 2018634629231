import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Drawer,
  IconButton,
  CssBaseline,
  Typography,
  MenuItem,
  Menu,
  Avatar,
  CircularProgress,
  Divider,
  Toolbar,
  AppBar,
  Box,
  Button,
} from "@material-ui/core/";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import ResetIcon from "@material-ui/icons/Autorenew";
import ListItems from "../ListItems/ListItems";
import AccountSetting from "components/AccountSetting";
import { Link, withRouter } from "react-router-dom";
import seedrandom from "seedrandom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import doknanekLogo from "assets/images/logo.png";
import netjeekFullLogo from "assets/images/netjeekFullLogo.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed,
    paddingLeft: 0,
    alignItems: "stretch",
    justifyContent: "center",
    backgroundColor: "#383838",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    // backgroundColor: 'rgba(0,0,0,0.7)',
    padding: "5px 0px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  companyName: {
    letterSpacing: 2,
    fontWeight: 600,
    color: "#fff",
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none !important",
  },
  title: {
    alignSelf: "center",
    color: "rgb(57,183,210)",
    fontWeight: 550,
    // fontWeight: 'bold',
    flexGrow: 1,
    marginLeft: "30px",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  avatar: {
    backgroundColor: (props) => props.userData.imgColor,
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  menuItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoutIcon: {
    color: "#f00",
  },
  userNameStyle: {
    fontWeight: 500,
    letterSpacing: 1,
    color: "#fff",
    alignSelf: "center",
  },
  listText: {
    color: "#000",
    width: "100%",
    fontSize: theme.spacing(2),
    textDecorationLine: "none",
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  logoContainer: {
    width: "71px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
}));

function Layout(props) {
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    role: "",
    initials: "",
    imgColor: "",
  });
  const accountSettingRef = React.createRef();

  useEffect(() => {
    parseUser(props.userData);
  }, [props.userData]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogOut = () => {
    props.history.push("/signin");
    localStorage.clear();
  };

  const parseUser = (userData) => {
    if (Object.keys(userData).length) {
      var rngB = seedrandom(
        `${userData.last_name}Red${userData.first_name[1]}`
      );
      const B = Math.floor(rngB() * 240);

      var rngR = seedrandom(
        `${userData.last_name}Green${userData.first_name[1]}`
      );
      const R = Math.floor(rngR() * 100);

      var rngG = seedrandom(
        `${userData.last_name}Blue${userData.first_name[1]}`
      );
      const G = Math.floor(rngG() * 160);

      userData.imgColor = `rgb(${R}, ${G}, ${B})`;
      userData.initials =
        userData.first_name.toUpperCase()[0] +
        userData.last_name.toUpperCase()[0];
      setUserData(userData);
    } else {
      setUserData({
        first_name: "",
        last_name: "",
        role: "",
        initials: "",
        imgColor: "",
      });
    }
  };
  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem disabled>
        {userData.first_name
          ? `${userData.role[0].toUpperCase()}${userData.role.substr(1)}`
          : " "}
      </MenuItem>

      {userData.role === "logistics" && (
        <MenuItem
          className={classes.listText}
          onClick={() =>
            window.open(process.env.REACT_APP_DOWNLOAD_APP_LINL, "_blank")
          }
        >
          <Box className={classes.menuItem}>
            <Typography>Download App</Typography>
            <DownloadIcon />
          </Box>
        </MenuItem>
      )}

      <MenuItem
        onClick={(e) => {
          accountSettingRef.current.setOpen(true);
          setAnchorEl(null);
        }}
      >
        <Box className={classes.menuItem}>
          <Typography>Reset Password</Typography>
          <ResetIcon style={{ marginLeft: "8px" }} />
        </Box>
      </MenuItem>

      <Divider />

      <MenuItem onClick={handleLogOut}>
        <Link color="inherit" to={`/signin`} className={classes.listText}>
          <Box className={classes.menuItem}>
            <Typography>Logout</Typography>
            <FontAwesomeIcon
              className={classes.logoutIcon}
              icon={faSignOutAlt}
            ></FontAwesomeIcon>
          </Box>
        </Link>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          {/* <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={() => setOpen(true)}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <img src={doknanekLogo} width={30} height={30} />
          </IconButton> */}
          <Box
            className={clsx(
              classes.logoContainer,
              open && classes.menuButtonHidden
            )}
          >
            <img alt="netjeek-logo" src={doknanekLogo} height={24} />
          </Box>
          <Typography
            component="h1"
            variant="h5"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {props.pageName}
          </Typography>
          {Object.keys(userData).length ? (
            <Typography
              component="h4"
              color="secondary"
              noWrap
              className={classes.userNameStyle}
            >
              {userData.first_name
                ? `${userData.first_name} ${userData.last_name}`
                : " "}
            </Typography>
          ) : (
            <div></div>
          )}
          <IconButton
            aria-owns={isMenuOpen ? "material-appbar" : undefined}
            className={classes.iconButton}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            {Object.keys(userData).length ? (
              <Avatar className={classes.avatar}>{userData.initials}</Avatar>
            ) : (
              <CircularProgress
                color="secondary"
                size={25}
                className={classes.avatar}
              />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderProfileMenu}
      <AccountSetting {...props} ref={accountSettingRef} userData={userData} />
      <Drawer
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img
            alt="netjeek-logo2"
            src={netjeekFullLogo}
            style={{ width: "90%" }}
          />
        </div>

        <ListItems
          {...props}
          userData={userData}
          handleDrawerOpen={() => setOpen(true)}
          handleDrawerClose={() => setOpen(false)}
          isDrawerOpen={open}
        />
      </Drawer>
    </div>
  );
}
export default withRouter(Layout);
