import React, { Component } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  IconButton,
  Box,
  Tooltip,
  Fade,
  Popper,
  CircularProgress,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import AddTopPicks from 'components/TopPicks/AddTopPicks';
import { toast } from 'react-toastify';
import netjeekServer from 'api/netjeek';
import DeleteIcon from '@material-ui/icons/Delete';
import { Skeleton, SpeedDial, SpeedDialIcon } from '@material-ui/lab/';

const errCodesMapper = {
  1000: 'Quantity is missing',
  3000: 'Quantity is not valid',
  666: 'Network Error. Please Try Again!',
};
const eRetailerNameMapper = {
  AMAZON_CA: 'AMAZON',
  WALMART_CA: 'WALMART',
  ALIEXPRESS: 'ALIEXPRESS',
};
const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
    paddingLeft: '30px',
    paddingRight: '15px',
  },

  modalButton: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      opacity: 0.7,
    },
  },
  button: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    margin: theme.spacing(1),
  },

  paperCard: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconButton: {
    paddingRight: theme.spacing(0.9),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(6),
      right: theme.spacing(6),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
});

class TopPicks extends Component {
  state = {
    topPicks: [],
    modalType: 'Add',
    selectedIdx: 0,
    anchorEl: null,
    maxItems: 3,
    isAdddisabled: true,
  };

  constructor(props) {
    super(props);
    this.addTopPicksRef = React.createRef();
  }
  componentDidMount = () => {
    this.getTopPicks();
    this.props.changePageHeader('Settings | Top Picks');
  };
  disableAdd = (count) => {
    const maxItems = this.state.maxItems;
    if (count >= maxItems) this.setState({ isAdddisabled: true });
    else this.setState({ isAdddisabled: false });
  };
  handleDelete = async (idx) => {
    this.setState({ isButtonLoading: true });
    const topPicks = this.state.topPicks;
    topPicks[idx].isDeleteOpen = true;
    const topPickId = topPicks[idx].id;

    try {
      const response = await netjeekServer.delete(
        `/settings/top-picks/${topPickId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        this.props.setSuccessMessages(['Top Pick Deleted Successfully']);
        this.getTopPicks();
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];

      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      this.setState({
        isLoading: false,
      });
    }
    this.setState({ topPicks, isButtonLoading: false });
  };
  handleEdit = async (idx) => {
    await this.setState({ selectedIdx: idx, modalType: 'Edit' });
    this.addTopPicksRef.current.setOpen(true);
  };
  toggleModal = (idx, state) => {
    const topPicks = this.state.topPicks;
    topPicks[idx].isDeleteOpen = state;
    this.setState({ topPicks });
  };
  getTopPicks = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await netjeekServer.get(`/settings/top-picks`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response) {
        const resData = response.data.data;
        resData.forEach((item, idx) => {
          resData[idx].isDeleteOpen = false;
        });
        this.disableAdd(response.data.data.length);

        this.setState({ topPicks: resData, isLoading: false });
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];

      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      topPicks,
      selectedIdx,
      modalType,
      isLoading,
      isButtonLoading,
      isAdddisabled,
    } = this.state;

    const renderTopPick = topPicks.map((topPick, idx) => {
      return (
        <Grid item xs={12} md={6} lg={3} key={idx}>
          <Paper className={classes.paperCard}>
            <Box mt={1}>
              <Typography
                style={{ fontWeight: 600, letterSpacing: 1, marginBottom: 15 }}
                variant='h5'
                gutterBottom
              >
                {topPick.title}
              </Typography>

              <Typography variant='body1' gutterBottom>
                <b> Query:</b> &nbsp; {topPick.query}
              </Typography>
              <Typography variant='body1' gutterBottom>
                <b> E-Retailer:</b> &nbsp;{' '}
                {eRetailerNameMapper[topPick.eRetailer]}
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
              }}
            >
              <Popper
                open={topPick.isDeleteOpen}
                anchorEl={this.state.anchorEl}
                placement='bottom'
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper style={{ display: 'flex' }}>
                      <Typography className={classes.typography}>
                        Delete this item?
                      </Typography>
                      <Button
                        className={classes.modalButton}
                        onClick={() => this.toggleModal(idx, false)}
                      >
                        No
                      </Button>
                      <Button
                        className={classes.modalButton}
                        disabled={isButtonLoading}
                        onClick={() => this.handleDelete(idx)}
                      >
                        {isButtonLoading && (
                          <CircularProgress
                            size={22}
                            style={{ marginLeft: 4, position: 'absolute' }}
                          />
                        )}
                        Yes
                      </Button>
                    </Paper>
                  </Fade>
                )}
              </Popper>
              <Tooltip title='Delete'>
                <IconButton
                  className={classes.iconButton}
                  onClick={(event) => {
                    this.setState({ anchorEl: event.currentTarget });
                    this.toggleModal(idx, true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Paper>
        </Grid>
      );
    });
    return (
      <Grid
        container
        spacing={2}
        // direction='column'
        justify='flex-start'
        className={classes.root}
      >
        <br />
        <Grid item xs={12}>
          <Tooltip placement='left' title='Add Top Pick'>
            <SpeedDial
              open={false}
              ariaLabel='SpeedDialShortCuts'
              className={classes.speedDial}
              icon={<SpeedDialIcon />}
              FabProps={{ disabled: isAdddisabled }}
              onClick={() => this.addTopPicksRef.current.setOpen(true)}
            />
          </Tooltip>
        </Grid>

        {isLoading ? (
          <Grid
            container
            spacing={3}
            style={{ marginBottom: 15, marginTop: 15 }}
          >
            {[...Array(6)].map((_item, idx) => {
              return (
                <Grid item key={idx} xs={4} lg={3}>
                  <Skeleton
                    id={idx}
                    // disableAnimate={idx > 5}
                    variant='rect'
                    animation='wave'
                    height={215}
                    style={{ borderRadius: 5 }}
                  ></Skeleton>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid container spacing={2} style={{ marginBottom: 5 }}>
            {renderTopPick}
          </Grid>
        )}
        <AddTopPicks
          {...this.props}
          topPickData={modalType === 'Edit' ? topPicks[selectedIdx] : {}}
          modalType={modalType}
          ref={this.addTopPicksRef}
          getTopPicks={this.getTopPicks}
        />
      </Grid>
    );
  }
}
export default withStyles(styles)(TopPicks);
