import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core/';
import TableComponent from 'components/Table/TableComponent';
import CreateShipmentModal from 'components/Shipments/CreateShipmentModal';

const tableFields = {
  head: [
    'Shipment Number',
    'Shipping Date',
    'ETA',
    'Shipping Mode',
    'Shipment Status',
    'Notes',
  ],
  search: [
    'Number',
    'Date Range',
    'Date Range',
    'Method',
    'Shipment Status',
    null,
  ],
  dataKeys: ['number', 'date', 'eta', 'method', 'status', 'notes'],
};

const tableSelectOptions = [
  {
    label: 'ALL',
    value: 'ALL',
  },
  {
    label: 'Created',
    value: 'CREATED',
  },
  {
    label: 'Shipped',
    value: 'SHIPPED',
  },
  {
    label: 'Packed',
    value: 'PACKED',
  },
  {
    label: 'Received In Transit Hub',
    value: 'RECEIVED_IN_TRANSIT_HUB',
  },
  {
    value: 'SHIPPED_FROM_TRANSIT_HUB',
    label: 'Shipped From Transit Hub',
  },
  {
    label: 'Received In Tripoli',
    value: 'RECEIVED_IN_TRIPOLI',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
  },
  tableHolderStyle: {
    padding: theme.spacing(1),
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0.8),
  },
  tabelCardHeaderStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}));

export default function Shipments(props) {
  const classes = useStyles();
  const tableRef = useRef();
  // constructor(props) {
  //   super(props);
  //   this.createShipmentRef = React.createRef();
  //   this.tableRef = React.createRef();
  // }

  useEffect(() => {
    props.changePageHeader('Shipments List');
  }, []);

  const getShipments = () => {
    tableRef.current.getItems();
  };

  return (
    <Grid
      container
      direction='column'
      justify='center'
      className={classes.root}
    >
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* <Tooltip placement='left' title='Add Shipment'>
            <SpeedDial
              ariaLabel='SpeedDialShortCuts'
              className={classes.speedDial}
              icon={<SpeedDialIcon />}
              onClick={() => this.createShipmentRef.current.setOpen(true)}
            />
          </Tooltip> */}
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.tableHolderStyle} elevation={1}>
          <TableComponent
            {...props}
            dataRoute='shipments'
            selectable={true}
            searchable={true}
            tableFields={tableFields}
            selectOptions={tableSelectOptions}
            rowsPerPage={10}
            rowsPerPageOptions={[5, 10, 25]}
            pagination={true}
            ref={tableRef}
          />
        </Paper>
      </Grid>
      <CreateShipmentModal {...props} getShipments={getShipments} />
    </Grid>
  );
}

// export default withStyles(styles)(Shipments);
