import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
  InputAdornment,
  Divider,
  Tooltip,
  MenuItem,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';

import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Snackbar from 'components/Snackbar';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

const errCodesMapper = {
  3000: 'Code is not valid',
  3001: 'Start Date is not valid',
  3002: 'End Date is not valid',
  3003: 'Amount is not valid',
  2100: 'Something went wrong',
  4000: 'Voucher/Promo code already exists',
  4001: "Voucher/Promo doesn't exists",
  666: 'Network Error. Please Try Again!',
};

const styles = (theme) => ({
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(6),
      right: theme.spacing(6),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
});
class AddDiscountModal extends Component {
  state = {
    open: false,
    isLoading: false,
    errorMessages: [],
    successMessages: [],
    discountData: {
      number: '',
      type: '',
      value: '',
      end_date: moment().add(1, 'day'),
      start_date: moment(),
    },
  };

  componentDidMount = () => {
    ValidatorForm.addValidationRule('Number>0', (value) => value > 0);
    ValidatorForm.addValidationRule('Number>=0', (value) => value >= 0);
    ValidatorForm.addValidationRule('Promo<=100', (value) => {
     return this.state.discountData.type === 'PROMO'? value <=100 : value <= 100000
    })
  
    this.setState({
      discountData: {
        number: '',
        type: '',
        value: '',
        end_date: moment().add(1, 'day'),
        start_date: moment(),
      },
    });
  };

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
  };

  addDiscountCode = async (event) => {
    event.preventDefault();
    const { discountData } = this.state;
    this.setState({ isLoading: true });
    this.props.rerenderTable(false);
    try {
      let response = null;
      if (discountData.type === 'PROMO') {
        delete discountData.type;
        response = await netjeekServer.post(`/discounts/promos`, discountData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } else {
        delete discountData.type;
        response = await netjeekServer.post(
          `/discounts/vouchers`,
          discountData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
      }

      if (response) {
        let successMessages = [];
        this.props.setSuccessMessages(['Discount Add Successfully']);
        this.setOpen(false);
        this.props.rerenderTable(true);
        await this.setState({
          successMessages,
          errorMessages: [],
          discountData: {
            number: '',
            type: '',
            value: '',
            end_date: moment().add(1, 'day'),
            start_date: moment(),
          },
        });
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
    this.props.rerenderTable(true);
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const discountData = { ...this.state.discountData };
    discountData[name] = value;
    this.setState({ discountData });
  };

  handleDiscountStartDateChange = (date) => {
    const discountData = { ...this.state.discountData };
    discountData['start_date'] = date.toISOString();
    this.setState({ discountData });
  };
  handleDiscountEndDateChange = (date) => {
    const discountData = { ...this.state.discountData };
    discountData['end_date'] = date.toISOString();
    this.setState({ discountData });
  };
  render() {
    const { open, errorMessages, discountData, isLoading } = this.state;
    const { classes } = this.props;
    console.log('discountData', discountData);
    return (
      <Box>
        <Tooltip placement='left' title='Add Discount Code'>
          <SpeedDial
            open={false}
            ariaLabel='SpeedDialShortCuts'
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClick={() => this.setOpen(true)}
          />
        </Tooltip>
        <Dialog
          open={open}
          onClose={() => this.setOpen(false)}
          aria-labelledby='add-discount-dialog-title'
        >
          <DialogTitle id='add-discount-dialog-title'>
            Add Discount Code
          </DialogTitle>
          <Divider />
          <Snackbar type='error' messages={errorMessages} />
          <ValidatorForm
            ref='form'
            onSubmit={this.addDiscountCode}
            style={{ width: 600 }}
          >
            <DialogContent>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    width: '30%',
                  }}
                >
                  Code:
                </Typography>
                <TextValidator
                  autoFocus
                  fullWidth
                  variant='outlined'
                  validators={['required']}
                  errorMessages={['Code is required']}
                  margin='dense'
                  name='number'
                  value={discountData.number}
                  onChange={this.handleChange}
                  type='text'
                />
              </Box>

              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    width: '30%',
                  }}
                >
                  Type
                </Typography>
                <TextValidator
                  select
                  margin='dense'
                  name='type'
                  variant='outlined'
                  validators={['required']}
                  errorMessages={['Type is required']}
                  fullWidth
                  value={discountData.type}
                  onChange={this.handleChange}
                  style={{ marginBottom: '10px' }}
                >
                  <MenuItem value={'PROMO'}>Promo Code</MenuItem>
                  <MenuItem value={'VOUCHER'}>Voucher</MenuItem>
                  {/* <MenuItem value={'Ali Express'}>Ali Express</MenuItem> */}
                </TextValidator>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    //   marginRight: 15,
                    width: '30%',
                  }}
                >
                  Amount:
                </Typography>
                <TextValidator
                  fullWidth
                  name='value'
                  margin='dense'
                  type='number'
                  id='pricePerKgInput'
                  disabled={this.state.discountData.type===''}
                  validators={['Number>0', 'required','Promo<=100']}
                  errorMessages={[
                    'Amount must be a positive number',
                    'Amount is required',
                    'Promo Code max value is 100%'
                  ]}
                  value={discountData.value}
                  onChange={this.handleChange}
                  InputProps={{
                    step: 0.1,
                    endAdornment: (
                      <InputAdornment position='start'>
                        {discountData.type
                          ? discountData.type === 'PROMO'
                            ? '%'
                            : 'USD'
                          : ''}
                      </InputAdornment>
                    ),
                  }}
                  variant='outlined'
                />
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    //   marginRight: 15,
                    width: '30%',
                  }}
                >
                  Start Date:
                </Typography>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                >
                  <DatePicker
                    disableToolbar
                    fullWidth
                    autoFocus
                    autoOk
                    // keyboard
                    inputVariant='outlined'
                    variant='inline'
                    format={process.env.REACT_APP_DATE_FORMAT}
                    margin='dense'
                    value={discountData.start_date}
                    onChange={this.handleDiscountStartDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    //   marginRight: 15,
                    width: '30%',
                  }}
                >
                  End Date:
                </Typography>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                >
                  <DatePicker
                    disableToolbar
                    fullWidth
                    autoFocus
                    autoOk
                    // keyboard
                    inputVariant='outlined'
                    variant='inline'
                    format={process.env.REACT_APP_DATE_FORMAT}
                    margin='dense'
                    value={discountData.end_date}
                    onChange={this.handleDiscountEndDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </DialogContent>
            <DialogActions style={{ margin: 10 }}>
              <Button
                onClick={() => {
                  this.setOpen(false);
                  this.setState({
                    discountData: {
                      number: '',
                      type: '',
                      value: '',
                      end_date: moment().add(1, 'day'),
                      start_date: moment(),
                    }});
                }}
                style={{ color: '#000', fontSize: '16px' }}
              >
                Cancel
              </Button>
              <Button
                style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
                disabled={isLoading}
                type='submit'
                variant='contained'
                color='primary'
              >
                ADD
                {isLoading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 4, position: 'absolute' }}
                  />
                )}
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </Box>
    );
  }
}
export default withStyles(styles)(AddDiscountModal);
