import React, { useState, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Paper,
  IconButton,
  Button,
  Box,
  Popper,
  Fade,
  Tooltip,
  CircularProgress,
} from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import netjeekServer from 'api/netjeek';
import AddEditUser from 'components/UserManagement/AddEditUser';
import Avatar from '@material-ui/core/Avatar';

const errCodesMapper = {
  1000: 'Quantity is missing',
  3000: 'Quantity is not valid',
  666: 'Network Error. Please Try Again!',
};
const useStyles = makeStyles((theme) => ({
  paperCard: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paadingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    // marginBottom: theme.spacing(1)
  },
  userInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 2,
    marginLeft: 10,
  },
  userInfoHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    fontSize: theme.spacing(5),
    backgroundColor: (props) => props.user.imgColor,
    color: '#fff',
    // mixBlendMode: 'difference',
    borderRadius: theme.spacing(10),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  typography: {
    padding: theme.spacing(2),
  },
  userNameStyle: {
    fontSize: 'medium',
    fontWeight: 600,
  },
  iconButton: {
    padding: '5px 6px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  progressorStyle: {
    marginLeft: 4,
    position: 'absolute',
  },
  modalButton: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      opacity: 0.7,
    },
  },
}));

export default function UserCard(props) {
  const classes = useStyles(props);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const addEditUserRef = createRef();
  // const { deleteUser, getUsers } = useContext(UsersConext);
  const { user } = props;

  const handleDelete = async (userId) => {
    setIsButtonLoading(true);
    try {
      const response = await netjeekServer.delete(`/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response) {
        props.setSuccessMessages(['User Deleted Successfully']);
        setIsDeleteOpen(false);
        props.getUsers();
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];

      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      setIsDeleteOpen(false);
    }
    // this.setState({ usersData: usersData });
    setIsButtonLoading(false);
  };
  return (
    <Paper className={classes.paperCard}>
      <AddEditUser
        {...props}
        userData={user}
        modalType='Edit'
        ref={addEditUserRef}
        getUsers={props.getUsers}
      />
      <Avatar className={classes.avatar}>{user.initials}</Avatar>
      <Box className={classes.userInfoContainer}>
        <Box className={classes.userInfoHeader} style={{}}>
          <Typography
            className={classes.userNameStyle}
            variant='h6'
            gutterBottom
          >
            {user.first_name + ' ' + user.last_name}
          </Typography>
          <Box>
            <Tooltip title='Edit'>
              <IconButton
                className={classes.iconButton}
                onClick={() => addEditUserRef.current.setOpen(true)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Popper
              open={isDeleteOpen}
              anchorEl={anchorEl}
              placement='bottom'
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography className={classes.typography}>
                      Delete this user?
                    </Typography>
                    <Button
                      className={classes.modalButton}
                      onClick={() => setIsDeleteOpen(false)}
                    >
                      No
                    </Button>
                    <Button
                      className={classes.modalButton}
                      disabled={isButtonLoading}
                      onClick={() => {
                        handleDelete(user.id);
                      }}
                    >
                      {isButtonLoading && (
                        <CircularProgress
                          size={22}
                          className={classes.progressorStyle}
                        />
                      )}
                      Yes
                    </Button>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <Tooltip title='Delete'>
              <IconButton
                className={classes.iconButton}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setIsDeleteOpen(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Typography variant='body1' gutterBottom>
          {user.role[0].toUpperCase() + user.role.substr(1)}
        </Typography>
        <Typography variant='body2' gutterBottom>
          {user.email}
        </Typography>
      </Box>
    </Paper>
  );
}
