import React, { Component } from 'react';
import {
  Button,
  TextField,
  Input,
  InputAdornment,
  Typography,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm } from 'react-material-ui-form-validator';

const errCodesMapper = {
  1001: 'Quantity is missing',
  2100: 'Something went wrong',
  3000: 'Quantity is not valid',
  3001: 'Quantity must be a positive number',
  5000: 'This item was not found',
  666: 'Network Error. Please Try Again!',
};
export default class ReceivItemModal extends Component {
  state = {
    open: false,
    errorMessages: [],
    successMessages: [],
    isLoading: false,
    itemData: this.props.itemData ? this.props.itemData : {},
    toBeReceived: null,
    updatedItemData: {
      qty_received: null,
    },
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.itemData !== prevProps.itemData) {
      const itemData = this.props.itemData;
      this.setState({
        toBeReceived: itemData.quantity - itemData.qty_received,
        itemData: this.props.itemData,
      });
    }
  };

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
  };

  updateItem = async (event) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    const { itemData, toBeReceived } = this.state;

    const itemId = this.state.itemData.id;
    try {
      const response = await netjeekServer.post(
        `/items/receive/${itemId}`,
        {
          quantity: toBeReceived + itemData.qty_received,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        this.props.setSuccessMessages([
          'Quantity Received Updated Successfully',
        ]);
        this.setOpen(false);
        this.props.getItems();
        this.setState({ errorMessages: [] });
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
  };
  handleChange = (event) => {
    const name = event.target.name;
    let value = parseInt(event.target.value);
    const itemData = { ...this.state.itemData };
    let errorMessages = [];
    // const updatedItemData = { ...this.state.updatedItemData };
    if (name === 'toBeReceived' && (value < 0 || !value)) value = 0;
    if (itemData.quantity === itemData.qty_received) return;

    if (value > itemData.quantity - itemData.qty_received)
      errorMessages.push(
        `Quantitiy must be less than or equal to ${
          itemData.quantity - itemData.qty_received
        }`
      );
    // updatedItemData[name] = value;

    this.setState({ toBeReceived: value, errorMessages });
  };

  render() {
    const {
      open,
      errorMessages,
      isLoading,
      toBeReceived,
      itemData,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={() => this.setOpen(false)}
        aria-labelledby='receive-item-dialog-title'
      >
        <DialogTitle id='receive-item-dialog-title'>Receive Item</DialogTitle>
        <Snackbar type='error' messages={errorMessages} />

        <ValidatorForm ref='form' onSubmit={this.updateItem}>
          <DialogContent
            style={{ display: 'flex', flexDirection: 'column', width: 520 }}
          >
            <Input
              readOnly
              fullWidth
              disableUnderline
              value={itemData.item_name}
              multiline
              startAdornment={
                <InputAdornment
                  position='start'
                  style={{
                    whiteSpace: 'nowrap',
                    marginRight: 15,
                    width: '25%',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '16px',
                    }}
                  >
                    Item:
                  </Typography>
                </InputAdornment>
              }
            />
            <Input
              readOnly
              fullWidth
              disableUnderline
              value={itemData.qty_received}
              multiline
              startAdornment={
                <InputAdornment
                  position='start'
                  style={{
                    whiteSpace: 'nowrap',
                    marginRight: 15,
                    width: '27%',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '16px',
                    }}
                  >
                    In Hub:
                  </Typography>
                </InputAdornment>
              }
            />
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: '16px',
                  marginRight: 15,
                  width: '28%',
                }}
              >
                Recieve New:
              </Typography>
              <TextField
                fullWidth
                autoFocus
                name='toBeReceived'
                margin='dense'
                type='Number'
                id='toBeReceived'
                defaultValue={itemData.quantity - itemData.qty_received}
                // value={itemData.quantity - itemData.qty_received}
                onChange={this.handleChange}
                variant='outlined'
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setOpen(false);
              }}
              style={{ color: '#000', fontSize: '16px' }}
            >
              Cancel
            </Button>
            <Button
              disabled={
                isLoading || toBeReceived === 0 || errorMessages.length > 0
              }
              type='submit'
              variant='contained'
              color='primary'
            >
              Receive
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}
