import axios from 'axios';
import jwt_decode from 'jwt-decode';
const apiEndpoint = process.env.REACT_APP_ENDPOINT;
export let cancelTokenSource = axios.CancelToken.source();

const netjeekServer = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  timeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
});

netjeekServer.interceptors.request.use(
  async function (config) {
    if (
      !config.url.endsWith('customers/refresh-token') &&
      config.headers.Authorization &&
      localStorage.getItem('token') &&
      new Date().getTime() / 1000 >
        jwt_decode(localStorage.getItem('token')).exp
    ) {
      const refreshTokenResult = await axios({
        method: 'post',
        url: apiEndpoint + 'users/refresh-token',
        data: {
          refresh_token: localStorage.getItem('refresh_token'),
        },
      });

      if (refreshTokenResult.status && refreshTokenResult.status === 200) {
        //save new session
        localStorage.setItem(
          'refresh_token',
          refreshTokenResult.data.data.refresh_token
        );
        localStorage.setItem('token', refreshTokenResult.data.data.token);

        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${refreshTokenResult.data.data.token}`,
        };
        localStorage.setItem(
          'refresh_token',
          refreshTokenResult.data.data.refresh_token
        );
        localStorage.setItem('token', refreshTokenResult.data.data.token);
      }

      return config;
    } else {
      return config;
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
export default netjeekServer;
