import React from 'react';

export const getPath = (x, y, width, height) => {
  return `M${x},${y + height}
          L${x},${y + 10}
          C${x},${y}
          ${x + width}, ${y}
          ${x + width},${y + 10}
          L${x + width},${y + height}
          Z`;
};

export const errCodesMapper = {
  '1000': 'Start Date is missing',
  '1001': 'End Date is missing ',
  '2000': 'Maximum Date Range is 100 Days!',

  '3000': 'Start Date is not valid ',
  '3001': 'End Date in not valid ',
  '5000': 'Unauthenticated',
  '5001': 'Unauthorized',
  '666': 'Network Error. Please Try Again!',
};
export const RoundTopBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke='none' fill={fill} />;
};
