import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Paper,
  Typography,
  CssBaseline,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import netjeekServer from 'api/netjeek';
import Background from 'assets/images/cart.jpg';
import netjeekLogo from 'assets/images/logo.png';
import Snackbar from 'components/Snackbar';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';

const errCodesMapper = {
  1000: 'E-mail is missing',
  1001: 'Password is missing',
  1002: 'Verification Code is missing',
  2000: 'Password is less than 8 characters or more than 255 characters',
  3000: 'E-mail is not valid',
  3001: 'Password is not valid',
  3002: 'Code is not valid',
  666: 'Network Error. Please Try Again!',
};
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1)
  },
  submit: {
    padding: theme.spacing(1),
    margin: theme.spacing(3, 0, 2),
  },
  loginNowStyle: {
    color: '#3d67f2',
  },
  progressor: {
    marginLeft: 4,
    position: 'absolute',
  },
  netjeekLogo: {
    height: '140px',
  },
}));

export default function ResetPassword(props) {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      'isPasswordMatch',
      (value) => password !== value
    );
  }, []);

  const submitHandler = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    try {
      const email = localStorage.getItem('email');
      const response = await netjeekServer.post('/users/reset-password', {
        email,
        token: code,
        password,
      });

      if (response) {
        let messages = [];
        messages.push('Your password was reset successfully.');
        setIsLoading(false);
        setSuccessMessages(messages);
        setErrorMessages([]);
        localStorage.clear();
        setTimeout(() => {
          props.history.push('/signin');
        }, 1000);
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      setIsLoading(false);
      setErrorMessages(errorMessages);
      setSuccessMessages([]);
    }
  };

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img
            alt='netjeek-logo'
            src={netjeekLogo}
            className={classes.netjeekLogo}
          />
          <Typography gutterBottom component='h1' variant='h3' align='center'>
            NETJEEK | ADMIN
          </Typography>
          <Typography component='h1' variant='h6'>
            Enter the verification code and your new password.
          </Typography>
          <ValidatorForm className={classes.form} onSubmit={submitHandler}>
            <Snackbar type='error' messages={errorMessages} />
            <Snackbar type='success' messages={successMessages} />
            <TextValidator
              variant='outlined'
              validators={['required']}
              errorMessages={['Code is required']}
              value={code}
              onChange={(event) => setCode(event.target.value)}
              margin='normal'
              required
              fullWidth
              name='code'
              label='Verification Code'
              color='secondary'
              id='verificationCode'
            />
            <TextValidator
              variant='outlined'
              validators={[
                'required',
                'matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$',
              ]}
              errorMessages={[
                'Password is required.',
                'For your own safety, your password must contain at least 1 uppercase, 1 lowercase and 1 special character.',
              ]}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              color='secondary'
              type='password'
              id='password'
            />
            <TextValidator
              variant='outlined'
              validators={['isPasswordMatch', 'required']}
              errorMessages={["Passwords doesn't match", 'Field is required']}
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              margin='normal'
              required
              fullWidth
              name='confirmPassword'
              label='Confirm Password'
              type='password'
              color='secondary'
              id='confirmPassword'
            />

            {isLoading ? (
              <Button
                disabled
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                Please Wait
                <CircularProgress size={24} className={classes.progressor} />
              </Button>
            ) : (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                Reset password
              </Button>
            )}
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Link to={`/signin`} className={classes.loginNowStyle}>
                  {`Login Now >`}
                </Link>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </Grid>
    </Grid>
  );
}
