import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SignIn from 'pages/auth/SignIn';
import ResetPassword from 'pages/auth/ResetPassword';
import ForgotPassword from 'pages/auth/ForgotPassword';
import AdminPortal from 'pages/adminPortal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Exo',
  },
  palette: {
    primary: {
      main: 'rgb(57,183,210)',

      contrastText: '#fff',
    },
    secondary: {
      light: '#eaeaea',
      main: '#59595b',
      dark: '#414142',
      contrastText: '#000',
    },
  },
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <Route exact path='/' component={SignIn} />
          <Route exact path='/signin' component={SignIn} />
          <Route path='/reset-password' component={ResetPassword} />
          <Route path='/forgot-password' component={ForgotPassword} />
          <Route path='/admin-portal' component={AdminPortal} />
        </Router>
        <ToastContainer
          position='top-right'
          autoClose={4000}
          hideProgressBar={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </MuiThemeProvider>
    );
  }
}
export default App;
