import React, { Component } from 'react';
import {
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { toast } from 'react-toastify';
import ImageDropzone from 'components/ImageDropzone/ImageDropzone';

const errCodesMapper = {
  1000: 'Image is missing',
  1001: 'Retailer is missing',
  1002: 'Query is missing',
  2000: 'Query is not valid',
  2100: 'Something went wrong',
  3000: 'Retailer must be one of [AMAZON, WALMART,ALIEXPRESS]',
  666: 'Network Error. Please Try Again!',
};
export default class AddShortcut extends Component {
  state = {
    open: false,
    isLoading: false,
    errorMessages: [],
    successMessages: [],
    shortcutsFormData: new FormData(),
    shortcutData: {
      query: '',
      eRetailer: '',
    },
  };

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.addShortcut();
  };

  setimage = (image) => {
    const shortcutsFormData = this.state.shortcutsFormData;
    this.setState({ successMessages: [], errorMessages: [] });

    if (image) shortcutsFormData.set('image', image.file);
    else shortcutsFormData.delete('image');
  };

  addShortcut = async () => {
    const { shortcutData } = this.state;
    const shortcutsFormData = this.state.shortcutsFormData;
    this.setState({ isLoading: true });
    try {
      Object.keys(shortcutData).forEach((key) => {
        shortcutsFormData.set(key, shortcutData[key]);
      });
      const response = await netjeekServer.post(
        `/settings/shortcuts`,
        shortcutsFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response) {
        this.props.setSuccessMessages(['Shortcut Added Successfully']);
        await this.setState({
          errorMessages: [],
          shortcutData: {
            query: '',
            eRetailer: '',
          },
        });
        this.setOpen(false);
        this.props.getShortcuts();
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const shortcutData = { ...this.state.shortcutData };
    shortcutData[name] = value;
    this.setState({ shortcutData });
  };

  render() {
    // const { modalType } = this.props;
    const {
      open,
      errorMessages,
      successMessages,
      shortcutData,
      isLoading,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={() => this.setOpen(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          <Typography variant='h5'>Add Shortcut</Typography>
        </DialogTitle>
        <Snackbar type='error' messages={errorMessages} />
        <Snackbar type='success' messages={successMessages} />
        <ValidatorForm
          ref='form'
          onSubmit={this.handleSubmit}
          style={{ width: 600 }}
        >
          <DialogContent>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Image:
              </Typography>
              <ImageDropzone setimage={this.setimage} />
            </Box>
            {/* <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%'
                }}
              >
                Order:
              </Typography>
              <TextValidator
                autoFocus
                fullWidth
                variant='outlined'
                validators={['required']}
                errorMessages={['Title is required']}
                margin='dense'
                name='order'
                value={shortcutData.order}
                onChange={this.handleChange}
                type='text'
              />
            </Box> */}
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Query:
              </Typography>
              <TextValidator
                autoFocus
                fullWidth
                name='query'
                margin='dense'
                validators={['required']}
                errorMessages={['Query is required']}
                type='text'
                id='query'
                value={shortcutData.query}
                onChange={this.handleChange}
                variant='outlined'
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Retailer
              </Typography>
              <TextValidator
                select
                margin='dense'
                name='eRetailer'
                variant='outlined'
                validators={['required']}
                errorMessages={['Retailer is required']}
                fullWidth
                value={shortcutData.eRetailer}
                onChange={this.handleChange}
                style={{ marginBottom: '10px' }}
              >
                <MenuItem value={'AMAZON_CA'}>AMAZON</MenuItem>
                <MenuItem value={'WALMART_CA'}>WALMART</MenuItem>
                <MenuItem value={'ALIEXPRESS'}>ALIEXPRESS</MenuItem>
              </TextValidator>
            </Box>
          </DialogContent>
          <DialogActions style={{ margin: 10 }}>
            <Button
              onClick={() => {
                this.setOpen(false);
              }}
              style={{ color: '#000', fontSize: '16px' }}
            >
              Cancel
            </Button>
            <Button
              style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
              disabled={isLoading}
              type='submit'
              variant='contained'
              color='primary'
              // onClick={event => this.addShortcut(event)}
            >
              Add
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}
