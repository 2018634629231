import React, { Component } from 'react';
import {
  Typography,
  Dialog,
  DialogContent,
  CircularProgress,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

const GoogleMapURL = process.env.REACT_APP_GMAP_API_KEY;
const styles = (theme) => ({
  form: {
    width: '100%',
  },
  valueField: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  iconHover: {
    margin: theme.spacing(1),
    '&:hover': {
      color: '#ffeb3b',
      cursor: 'pointer',
    },
  },
});
export default class GeoMap extends Component {
  state = { open: false, geo_location: this.props.geo_location };

  setOpen = (state) => {
    this.setState({ open: state });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const demographicData = { ...this.state.demographicData };
    demographicData[name] = value;
    this.setState({ demographicData });
  };

  render() {
    const { open, geo_location } = this.state;
    const { height } = this.props;

    const DialogTitle = withStyles(styles)((props) => {
      const { children, classes, onClose, ...other } = props;
      return (
        <MuiDialogTitle
          disableTypography
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          {...other}
        >
          {children}
          {onClose ? (
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    });

    const MapComponent = compose(
      withProps({
        googleMapURL: GoogleMapURL,
        loadingElement: (
          <div
            style={{
              height: `100%`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'ceter',
            }}
          >
            <CircularProgress size={30} />
          </div>
        ),
        containerElement: <div style={{ height: `${height}px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{
          lng: Number(geo_location.lng),
          lat: Number(geo_location.lat),
        }}
      >
        {props.isMarkerShown && (
          <Marker
            position={{
              lng: Number(geo_location.lng),
              lat: Number(geo_location.lat),
            }}
          />
        )}
      </GoogleMap>
    ));

    return (
      <Dialog
        onClose={() => this.setOpen(false)}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={() => this.setOpen(false)}
        >
          <Typography variant='h5' component='h3' gutterBottom>
            Shipping Location
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{ width: 600, padding: '10px' }}>
          <MapComponent height={this.props.height} isMarkerShown />
        </DialogContent>
      </Dialog>
    );
  }
}
