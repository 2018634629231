import React, { Component } from 'react';
import {
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
  Tooltip,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { SpeedDial, SpeedDialIcon } from '@material-ui/lab/';

const errCodesMapper = {
  1000: 'Quantity is missing',
  3000: 'Quantity is not valid',
  5000: 'Unauthenticated',
  666: 'Network Error. Please Try Again!',
};
const styles = (theme) => ({
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(6),
      right: theme.spacing(6),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
});

class AddEditUser extends Component {
  state = {
    open: false,
    isLoading: false,
    errorMessages: [],
    successMessages: [],
    userData: this.props.userData,
    updatedUserData: {},
  };

  componentDidMount = () => {};

  // componentDidUpdate = prevProps => {
  //   if (this.props.userData !== prevProps.userData) {
  //     if (!this.props.userData) this.setState({ userData: {} });
  //     else this.setState({ userData: this.props.userData });
  //   }
  // };

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.props.modalType === 'Add') {
      this.addUser();
    } else {
      this.updateUser();
    }
  };

  updateUser = async () => {
    const { updatedUserData } = this.state;
    const userId = this.state.userData.id;

    if (Object.keys(updatedUserData).length === 0) return;
    this.setState({ isLoading: true });
    try {
      const response = await netjeekServer.patch(
        `/users/${userId}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        this.props.setSuccessMessages(['User Updated Successfully']);
        this.setOpen(false);
        await this.setState({
          errorMessages: [],
        });
        this.props.getUsers();
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
  };
  addUser = async () => {
    const { userData } = this.state;
    this.setState({ isLoading: true });
    try {
      const response = await netjeekServer.post(`/users`, userData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response) {
        this.props.setSuccessMessages(['User Added Successfully']);
        this.setOpen(false);
        this.props.getUsers();
        await this.setState({
          errorMessages: [],
          userData: {
            name: '',
            first_name: '',
            last_name: '',
            email: '',
            role: '',
          },
        });
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (err.response.status === 404)
          errorMessages.push(errCodesMapper['666']);
        else if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const userData = { ...this.state.userData };
    const updatedUserData = { ...this.state.updatedUserData };
    if (this.props.modalType === 'Edit') {
      updatedUserData[name] = value;
    }
    userData[name] = value;
    this.setState({ userData, updatedUserData });
  };

  render() {
    const { modalType, classes } = this.props;
    const {
      open,
      errorMessages,
      successMessages,
      userData,
      isLoading,
      updatedUserData,
    } = this.state;

    return (
      <Box>
        {modalType === 'Add' && (
          <Tooltip placement='left' title='Add User'>
            <SpeedDial
              open={false}
              ariaLabel='SpeedDialUsers'
              className={classes.speedDial}
              icon={<SpeedDialIcon />}
              onClick={async () => this.setOpen(true)}
            />
          </Tooltip>
        )}
        <Dialog
          open={open}
          onClose={() => this.setOpen(false)}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            <Typography
              variant='h5'
              style={
                {
                  //   fontWeight: 700
                  //   fontSize: '16px'
                }
              }
            >
              {this.props.modalType === 'Add' ? 'Add New User' : 'Update User'}
            </Typography>
          </DialogTitle>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <Snackbar type='error' messages={errorMessages} />

          <Snackbar type='success' messages={successMessages} />
          <ValidatorForm
            ref='form'
            onSubmit={this.handleSubmit}
            style={{ width: 600 }}
          >
            <DialogContent>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '16px',
                    width: '30%',
                  }}
                >
                  First Name:
                </Typography>
                <TextValidator
                  autoFocus
                  fullWidth
                  variant='outlined'
                  validators={['required']}
                  errorMessages={['First Name is required']}
                  margin='dense'
                  name='first_name'
                  value={userData.first_name}
                  onChange={this.handleChange}
                  type='text'
                />
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '16px',
                    width: '30%',
                  }}
                >
                  Last Name:
                </Typography>
                <TextValidator
                  fullWidth
                  variant='outlined'
                  validators={['required']}
                  errorMessages={['Last Name is required']}
                  margin='dense'
                  name='last_name'
                  value={userData.last_name}
                  onChange={this.handleChange}
                  type='text'
                />
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  // variant='h6'
                  // display='block'
                  style={{
                    fontSize: '16px',
                    //   marginRight: 15,
                    width: '30%',
                  }}
                >
                  Email Address:
                </Typography>
                <TextValidator
                  fullWidth
                  name='email'
                  margin='dense'
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'Email Address is required',
                    'Email Address is not valid',
                  ]}
                  type='text'
                  id='email'
                  value={userData.email}
                  onChange={this.handleChange}
                  variant='outlined'
                />
              </Box>
              {/* <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                // variant='h6'
                // display='block'
                style={{
                  fontSize: '16px',
                  //   marginRight: 15,
                  width: '30%'
                }}
              >
                Phone:
              </Typography>
              <TextValidator
                fullWidth
                name='phone'
                margin='dense'
                // validators={['required']}
                // errorMessages={['phone is required']}
                type='text'
                id='phone'
                value={userData.phone}
                onChange={this.handleChange}
                variant='outlined'
              />
            </Box> */}
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '16px',
                    width: '30%',
                  }}
                >
                  Role:
                </Typography>
                <TextValidator
                  select
                  margin='dense'
                  name='role'
                  validators={['required']}
                  errorMessages={['Role is required']}
                  variant='outlined'
                  fullWidth
                  value={userData.role}
                  onChange={this.handleChange}
                  style={{ marginBottom: '10px' }}
                >
                  <MenuItem value={'admin'}>Admin</MenuItem>
                  <MenuItem value={'logistics'}>Logistics</MenuItem>
                  <MenuItem value={'driver'}>Driver</MenuItem>
                  <MenuItem value={'support'}>Support</MenuItem>
                </TextValidator>
              </Box>
            </DialogContent>
            <DialogActions style={{ margin: 10 }}>
              <Button
                onClick={() => {
                  this.setOpen(false);
                }}
                style={{ color: '#000', fontSize: '16px' }}
              >
                Cancel
              </Button>
              <Button
                style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
                disabled={
                  isLoading ||
                  (modalType === 'Edit' &&
                    Object.keys(updatedUserData).length === 0)
                }
                type='submit'
                variant='contained'
                color='primary'
                // onClick={event => this.addUser(event)}
              >
                {modalType === 'Add' ? 'Add' : 'Update'}
                {isLoading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 4, position: 'absolute' }}
                  />
                )}
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </Box>
    );
  }
}
export default withStyles(styles)(AddEditUser);
