import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
  InputAdornment,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { toast } from 'react-toastify';

const errCodesMapper = {
  3000: 'Code is not valid',
  3001: 'Start Date is not valid',
  3002: 'End Date is not valid',
  3003: ' Amount is not valid',
  2100: 'Something went wrong',
  4000: 'Voucher/Promo code already exists',
  4001: "Voucher/Promo doesn't exists",
  666: 'Network Error. Please Try Again!',
};

export default class EditDiscount extends Component {
  state = {
    open: false,
    isLoading: false,
    errMessages: [],
    discountData: {
      number: '',
      type: '',
      value: '',
      end_date: moment(),
      start_date: moment(),
    },
    updatedDiscountData: {},
  };

  componentDidMount = () => {
    console.log('AAAAAAAAAAA')
    ValidatorForm.addValidationRule('Number>0', (value) => value > 0);
    ValidatorForm.addValidationRule('Number>=0', (value) => value >= 0);
    ValidatorForm.addValidationRule('editPromo<=100', (value) => {
      return this.props.itemData.type === 'PROMO'? value <=100 : value <= 100000
     })
  };
  setOpen = (state) => {
    this.setState({ open: state });
  };

  componentDidUpdate = (prevProps) => {
   
    if (this.props.itemData !== prevProps.itemData) {
      this.setState({ discountData: { ...this.props.itemData } });
    }
  };

  updateDiscount = async (event) => {
    event.preventDefault();
    const { updatedDiscountData, discountData } = this.state;

    const discountId = this.props.itemData.id;
    this.setState({ isLoading: true, errMessages: [] });

    try {
      let response = null;
      if (discountData.type === 'PROMO') {
        response = await netjeekServer.patch(
          `/discounts/promos/${discountId}`,
          updatedDiscountData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
      } else {
        response = await netjeekServer.patch(
          `/discounts/vouchers/${discountId}`,
          updatedDiscountData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
      }
      if (response) {
        this.props.setSuccessMessages(['Discount Code Updated Successfully']);
        this.setState({ errMessages: [], open: false });
        this.props.getItems();
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      this.setState({ isLoading: false, errMessages: errorMessages });
    }
    this.setState({ isLoading: false });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedDiscountData = { ...this.state.updatedDiscountData };
    const discountData = { ...this.state.discountData };
    updatedDiscountData[name] = value;
    discountData[name] = value;
    this.setState({ discountData, updatedDiscountData });
  };
  handleDiscountStartDateChange = (date) => {
    const updatedDiscountData = { ...this.state.updatedDiscountData };
    const discountData = { ...this.state.discountData };
    discountData['start_date'] = date.toISOString();
    updatedDiscountData['start_date'] = date.toISOString();
    this.setState({ discountData, updatedDiscountData });
  };
  handleDiscountEndDateChange = (date) => {
    const updatedDiscountData = { ...this.state.updatedDiscountData };
    const discountData = { ...this.state.discountData };
    discountData['end_date'] = date.toISOString();
    updatedDiscountData['end_date'] = date.toISOString();
    this.setState({ discountData, updatedDiscountData });
  };
  render() {
    const { open, errMessages, discountData, isLoading } = this.state;

    return (
      <Dialog
        open={open}
        onClose={() => this.setOpen(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          <Typography variant='h5'>Edit Discount Code</Typography>
        </DialogTitle>
        <Snackbar type='error' messages={errMessages} />
        <ValidatorForm
          ref='form'
          onSubmit={this.updateDiscount}
          style={{ width: 600 }}
        >
          <DialogContent>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Code:
              </Typography>
              <TextValidator
                autoFocus
                fullWidth
                variant='outlined'
                validators={['required']}
                errorMessages={['Code is required']}
                margin='dense'
                name='number'
                value={discountData.number}
                onChange={this.handleChange}
                type='text'
              />
            </Box>

            {/* <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Type
              </Typography>
              <TextValidator
                select
                margin='dense'
                name='type'
                variant='outlined'
                validators={['required']}
                errorMessages={['Type is required']}
                fullWidth
                value={discountData.type}
                onChange={this.handleChange}
                style={{ marginBottom: '10px' }}
              >
                <MenuItem value={'PROMO'}>Promo Code</MenuItem>
                <MenuItem value={'VOUCHER'}>Voucher</MenuItem>
              </TextValidator>
            </Box> */}
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                Amount:
              </Typography>
              <TextValidator
                fullWidth
                name='value'
                margin='dense'
                type='number'
                id='pricePerKgInput'
                disabled={this.state.discountData.type===''}
                validators={['Number>0', 'required','editPromo<=100']}
                errorMessages={[
                  'Amount must be a positive number',
                  'Amount is required',
                  'Promo Code max value is 100%'
                ]}
                value={discountData.value}
                onChange={this.handleChange}
                InputProps={{
                  step: 0.1,
                  endAdornment: (
                    <InputAdornment position='start'>
                      {discountData.type
                        ? discountData.type === 'PROMO'
                          ? '%'
                          : 'USD'
                        : ''}
                    </InputAdornment>
                  ),
                }}
                variant='outlined'
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                Start Date:
              </Typography>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker
                  disableToolbar
                  fullWidth
                  autoFocus
                  autoOk
                  // keyboard
                  inputVariant='outlined'
                  variant='inline'
                  format={process.env.REACT_APP_DATE_FORMAT}
                  margin='dense'
                  value={discountData.start_date}
                  onChange={this.handleDiscountStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                End Date:
              </Typography>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker
                  disableToolbar
                  fullWidth
                  autoFocus
                  autoOk
                  // keyboard
                  inputVariant='outlined'
                  variant='inline'
                  format={process.env.REACT_APP_DATE_FORMAT}
                  margin='dense'
                  value={discountData.end_date}
                  onChange={this.handleDiscountEndDateChange}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </DialogContent>
          <DialogActions style={{ margin: 10 }}>
            <Button
              onClick={() => {
                this.setOpen(false);
              }}
              style={{ color: '#000', fontSize: '16px' }}
            >
              Cancel
            </Button>
            <Button
              style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
              disabled={isLoading}
              type='submit'
              variant='contained'
              color='primary'
              // onClick={event => this.createShipment(event)}
            >
              Update
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}
