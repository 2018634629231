import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  TableHead,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Input,
  MenuItem,
  Select,
  Box,
  Grid,
  CircularProgress,
  Button,
  Popover,
  IconButton,
  InputAdornment,
  Popper,
  Fade,
  Paper,
  Typography,
} from '@material-ui/core/';
import Slider from '@material-ui/core/Slider';
import axios from 'axios';
import { Link } from 'react-router-dom';
import TablePaginationActionsWrapped from './TablePaginationActions';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { faSort, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import netjeekServer from 'api/netjeek';
import { toast } from 'react-toastify';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import Image from 'material-ui-image';
import ReceivItemModal from '../Orders/ReceivItemModal';
import PrintLabelsModal from '../Orders/PrintLabelsModal';
import EditShippingFees from '../ShippingFees/EditShippingFees';
import EditDiscount from 'components/Discounts/EditDiscount';
import numeral from 'numeral';
import CloseIcon from '@material-ui/icons/Close';
import qs from 'qs';
import orderStatusMapper from 'shared/orderStatusMapper';
import shipmentStatusMapper from 'shared/shipmentStatusMapper';
import ShowNotes from 'components/Table/ShowNotes';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { red } from '@material-ui/core/colors';


const errCodesMapper = {
  3000: 'Search is not valid',
  3001: 'Sort is not valid',
  3002: 'Page is not valid',
  5000: 'Unauthenticated',
  5001: 'Unauthorized',
  666: 'Network Error. Please Try Again!',
};

const CustomTableFilterCell = withStyles({
  head: {
    padding: 4,
    backgroundColor: '#eee',
    fontWeight: 100,
    fontSize: 14,
  },
})(TableCell);

let cancelTokenSource = null;

const eRetailerNameMapper = {
  AMAZON_CA: 'AMAZON',
  WALMART_CA: 'WALMART',
  ALIEXPRESS: 'ALIEXPRESS',
};

const queryNameMapper = {
  ID: '_id',
  Name: 'name',
  'First Name': 'first_name',
  'Last Name': 'last_name',
  City: 'city',
  Status: 'isActive',
  'Order Status': 'status',
  'Shipment Status': 'status',
  Email: 'email',
  'Order Number': 'order_id',
  'Shipping Date': 'date',
  'Start Date': 'start_date',
  'End Date': 'end_date',
  ETA: 'eta',
  Date: 'createdAt',
  'Order Date': 'createdAt',
  'Netjeek ETA': 'eta',
  'Shipping Method': 'shipping_method',
  'Shipping Mode': 'method',
  'E-Retailer Price': 'price',
  Price: 'price',
  Retailer: 'retailer_name',
  'Quantity Ordered': 'quantity',
  'Quantity Received': 'qty_received',
  Region: 'region',
  Type: 'type',
  'Price Per Kg': 'pricePerKg',
  'Delivery Time': 'avgDT',
  'Account Number': 'account_number',
  'Shipment Number': 'number',
  'Unknown Weight Fees': 'unknowmWeightFees',
  Variant: 'variant',
  'Item Name': 'item_name',
  Mobile: 'phone',
  Code: 'number',
  Amount: 'value',
};

const styles = (theme) => ({
  table: {
    minWidth: 600,
    // minHeight: 600
  },
  tableWrapper: {
    marginTop: theme.spacing(0.5),
    // overflowX: 'auto'
  },
  margin: {
    margin: 0,
  },
  dateRange: {
    marginBottom: 15,
  },
  dataCell: {
    whiteSpace: 'nowrap',
  },
  newCell: { padding: 14 },
  searchCell: {
    padding: '7px 7px !important',
    backgroundColor: '#eee',
    fontWeight: 100,
    fontSize: 10,
  },
  popoverContainer: {
    width: 230,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2.2),
    paddingBottom: theme.spacing(0.5),
  },
  popoverMark: {
    backgroundColor: '#fee666',
  },
  deleteButton: {
    backgroundColor: '#d41323',
    padding: '4px 30px',
    color: '#fff',
    width: 75,
    marginLeft: 15,
    '&:hover': {
      backgroundColor: '#9e0d19',
    },
  },
  clearFiltterButton: {
    transition: 'all 0.4s ease-in-out',
    '&:hover': {
      color: '#fff',
      backgroundColor: 'rgba(200,0,0,0.7)',
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconButton: {
    padding: '5px 6px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  modalButton: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      opacity: 0.7,
    },
  },
});

class TableComponent extends Component {
  state = {
    rows: this.props.data,
    rowsPerPage: this.props.rowsPerPage,
    status: 'ALL',

    sortList: {},
    sortQuery: {},
    filterQuery: {},
    selectedIdx: null,

    focusedInput: null,
    tableData: [],
    dateRanges: {
      'Order Date': {
        startDate: null,
        endDate: null,
        focusedInput: null,
      },
      Date: {
        startDate: null,
        endDate: null,
        focusedInput: null,
      },
      ETA: {
        startDate: null,
        endDate: null,
        focusedInput: null,
      },
      'Netjeek ETA': {
        startDate: null,
        endDate: null,
        focusedInput: null,
      },
      'Shipping Date': {
        startDate: null,
        endDate: null,
        focusedInput: null,
      },
      'Start Date': {
        startDate: null,
        endDate: null,
        focusedInput: null,
      },
      'End Date': {
        startDate: null,
        endDate: null,
        focusedInput: null,
      },
    },
    filterData: {
      'Order Status': 'ALL',
      'Shipment Status': 'ALL',
      Status: 'ALL',
      Region: 'ALL',
      Type: 'ALL',
      Retailer: 'ALL',
      price: [],
    },
    paginationOptions: {
      page: 0,
      limit: this.props.rowsPerPage,
      total: 0,
      skip: 0,
    },
    selectedReigon: '',
    popoverAnchorEl: [null, null, null],
    isPopoverOpen: [false, false, false],
    deleteAnchorEl: null,
    isDeletePopoverOpen: false,
    isDeleteButtonLoading: false,
    didSubmitFilter: false,
    sliderSetting: {
      'Delivery Time': {
        step: 1,
        min: 0,
        max: null,
        marks: [],
        defaultValue: [],
      },
      'Quantity Received': {
        step: 1,
        min: 0,
        max: null,
        marks: [],
        defaultValue: [],
      },
      'Quantity Ordered': {
        step: 1,
        min: 0,
        max: null,
        marks: [],
        defaultValue: [],
      },
      Price: {
        step: 0.1,
        min: 0,
        max: null,
        marks: [],
        defaultValue: [],
      },
      Amount: {
        step: 0.1,
        min: 0,
        max: null,
        marks: [],
        defaultValue: [],
      },
    },
    isAddLoading: false,
  };
  constructor(props) {
    super(props);
    this.receiveItemRef = React.createRef();
    this.printItemsRef = React.createRef();
    this.editShippingFeesRef = React.createRef();
    this.editDiscountRef = React.createRef();
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.doneAdd !== prevProps.doneAdd) {
      setTimeout(() => {
        this.componentDidMount();
      }, 400);
    }
  };
  componentDidMount = async () => {
    const { tableFields } = this.props;
    cancelTokenSource = axios.CancelToken.source();

    let sortList = {};
    let sortQuery = {};
    sortQuery['$sort'] = {};
    tableFields.head.forEach((item, idx) => {
      sortList[item] = tableFields.search[idx] === null ? null : 0;
      if (item === 'Item Name') {
        sortQuery['$sort']['item_name'] = 1;
        sortList[item] = tableFields.search[idx] === null ? null : 1;
      }
      if (item === 'Shipping Date') {
        sortQuery['$sort']['date'] = -1;
        sortList[item] = tableFields.search[idx] === null ? null : -1;
      }
      if (item === 'Order Date') {
        sortQuery['$sort']['createdAt'] = -1;
        sortList[item] = tableFields.search[idx] === null ? null : -1;
      }
      // sortQuery['$sort'][`${queryNameMapper[item]}`] = 0;
    });
    await this.setState({
      sortList,
      sortQuery,
      doRenderComponent: true,
      dateRanges: {
        'Order Date': {
          startDate: null,
          endDate: null,
          focusedInput: null,
        },
        Date: {
          startDate: null,
          endDate: null,
          focusedInput: null,
        },
        ETA: {
          startDate: null,
          endDate: null,
          focusedInput: null,
        },
        'Netjeek ETA': {
          startDate: null,
          endDate: null,
          focusedInput: null,
        },
        'Shipping Date': {
          startDate: null,
          endDate: null,
          focusedInput: null,
        },
        'Start Date': {
          startDate: null,
          endDate: null,
          focusedInput: null,
        },
        'End Date': {
          startDate: null,
          endDate: null,
          focusedInput: null,
        },
      },
      filterData: {
        'Order Status': 'ALL',
        'Shipment Status': 'ALL',
        Status: 'ALL',
        Region: 'ALL',
        Type: 'ALL',
        Retailer: 'ALL',
        price: [],
      },
      filterQuery: { $search: {} },
    });
    this.getItems();
  };
  addOrderToShipment = async (row, orderId) => {
    const tableData = [...this.state.tableData];
    const idx = tableData.indexOf(row);
    try {
      tableData[idx].isAddLoading = true;
      this.setState({ tableData });

      const response = await netjeekServer.post(
        `/shipments/assign-order`,
        {
          order_id: orderId,
          shipment_id: this.props.shipmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        tableData[idx].isAddLoading = false;
        this.setState({ tableData });
        this.props.setSuccessMessages(['Order Added To Shipment Successfully']);
        this.getItems();
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];

      if (err.code === 'ECONNABORTED') {
        // toast.dismiss();
        toast.error(errCodesMapper['666'], {
          containerId: 1,
          toastId: 1,
        });
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                // toast.dismiss();
                toast.error('Your session has expired. please sign in again.', {
                  containerId: 1,
                  toastId: 1,
                });
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      tableData[idx].isAddLoading = false;
      this.setState({ tableData });
    }
  };
  removeOrderFromShipment = async (row, orderId) => {
    const tableData = [...this.state.tableData];
    const idx = tableData.indexOf(row);
    try {
      tableData[idx].isRemoveLoading = true;
      this.setState({ tableData });

      const response = await netjeekServer.post(
        `/shipments/remove-order`,
        {
          order_id: orderId,
          shipment_id: this.props.shipmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        tableData[idx].isRemoveLoading = false;
        this.setState({ tableData });
        this.props.setSuccessMessages([
          'Order Removed From Shipment Successfully',
        ]);
        this.getItems();
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];

      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666'], {
          containerId: 1,
          toastId: 1,
        });
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.', {
                  containerId: 1,
                  toastId: 1,
                });
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      tableData[idx].isRemoveLoading = false;
      this.setState({ tableData });
    }
  };
  getItems = async () => {
    const { dataRoute } = this.props;
    //
    const { paginationOptions, sortQuery, filterQuery } = this.state;

    const query = {
      $skip: paginationOptions.limit * paginationOptions.page,
      $limit: paginationOptions.limit,
      ...sortQuery,
      ...filterQuery,
    };
    this.setState({ isLoading: true });
    try {
      if (this.props.customerId && this.props.customerDetails)
        query['customer_id'] = this.props.customerId;
      if (this.props.shipmentId && this.props.shipmentDetails)
        query['shipment_id'] = this.props.shipmentId;
      if (this.props.addOrderToShipment) {
        query['shipment_id'] = 'null';
        query['items_fully_received'] = 1;
      }

      const queryStr = qs.stringify(query);
      //

      const response = await netjeekServer.get(`/${dataRoute}?${queryStr}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        cancelToken: cancelTokenSource.token,
      });
      if (response) {
        const newPaginationOptions = {
          ...paginationOptions,
          // skip: response.meta_data.skip,
          total: response.data.meta_data.total,
          // limit: response.data.meta_data.limit
          // skip: response.data.meta_data.skip
        };
        this.setState({ paginationOptions: newPaginationOptions });
        this.parseResponse(dataRoute, response);
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];
      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666'], {
          containerId: 1,
          toastId: 1,
        });
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;

            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                // toast.dismiss();
                toast.error('Your session has expired. please sign in again.', {
                  containerId: 1,
                  toastId: 1,
                });
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      this.setState({ isLoading: false, customersData: [] });
    }
    this.setState({
      popoverAnchorEl: [null, null, null],
      isPopoverOpen: [false, false, false],
    });
  };

  parseResponse = (dataRoute, response) => {
    const resData = response.data.data;
    const sliderSetting = { ...this.state.sliderSetting };
    if (dataRoute === 'settings/shipping-fees') {
      const metaData = response.data.meta_data;
      const maxPrice = metaData.maxPricePerKg;
      const maxDVT = metaData.maxAvgDT;

      sliderSetting['Price'].min = 0;
      sliderSetting['Price'].max = maxPrice;
      sliderSetting['Delivery Time'].min = 0;
      sliderSetting['Delivery Time'].max = maxDVT;
      sliderSetting['Price'].marks = [
        {
          value: maxPrice,
          label: `$${maxPrice}`,
        },
        {
          value: 0,
          label: `$0`,
        },
      ];
      sliderSetting['Delivery Time'].marks = [
        {
          value: maxDVT,
          label: `${maxDVT} Day(s)`,
        },
        {
          value: 0,
          label: `0 Day(s)`,
        },
      ];
      sliderSetting['Price'].defaultValue = [0, maxPrice / 4];
      sliderSetting['Delivery Time'].defaultValue = [0, maxDVT / 4];
      this.setState({ sliderSetting });
    } else if (dataRoute === 'items') {
      const metaData = response.data.meta_data;
      const maxQtyReceived = metaData.maxQtyReceived;
      const maxQtyOrdered = metaData.maxQtyOrdered;

      const maxPrice = metaData.maxPrice;
      sliderSetting['Quantity Received'].min = 0;
      sliderSetting['Quantity Received'].max = maxQtyReceived;
      sliderSetting['Quantity Ordered'].min = 0;
      sliderSetting['Quantity Ordered'].max = maxQtyOrdered;
      sliderSetting['Price'].min = 0;
      sliderSetting['Price'].max = maxPrice;
      sliderSetting['Quantity Received'].marks = [
        {
          value: maxQtyReceived,
          label: `${maxQtyReceived}`,
        },
        {
          value: 0,
          label: `0`,
        },
      ];
      sliderSetting['Quantity Ordered'].marks = [
        {
          value: maxQtyOrdered,
          label: `${maxQtyOrdered}`,
        },
        {
          value: 0,
          label: `0`,
        },
      ];
      sliderSetting['Price'].marks = [
        {
          value: maxPrice,
          label: `$${maxPrice}`,
        },
        {
          value: 0,
          label: `$0`,
        },
      ];
      sliderSetting['Quantity Received'].defaultValue = [0, maxQtyReceived / 3];
      sliderSetting['Quantity Ordered'].defaultValue = [0, maxQtyOrdered / 3];
      sliderSetting['Price'].defaultValue = [0, maxPrice / 3];

      this.setState({ sliderSetting });
    }
    if (dataRoute === 'customers') {
      resData.forEach((customer, idx) => {
        resData[idx].account_number = resData[idx].account_number.toUpperCase();
        resData[idx].name = customer.first_name + ' ' + customer.last_name;
        resData[idx].status = customer.isActive ? 'ACTIVE' : 'INACTIVE';
      });
      setTimeout(() => {
        this.setState({ tableData: resData, isLoading: false });
      }, 200);

      return;
    } else if (dataRoute.includes('items')) {
      // case /[orders/*/items]/.test(dataRoute):
      resData.forEach((order, idx) => {
        resData[idx].price = numeral(resData[idx].price).format('($0.00 a)');
        resData[idx].retailer_name =
          eRetailerNameMapper[resData[idx].retailer_name];
      });
      this.setState({ tableData: resData, isLoading: false });
      return;
    } else if (dataRoute.includes('orders')) {
      resData.forEach((order, idx) => {
        resData[idx].createdAt = moment(order.createdAt).format(
          process.env.REACT_APP_DATE_FORMAT
        );
        resData[idx].eta = moment(order.eta).format(
          process.env.REACT_APP_DATE_FORMAT
        );
        
        resData[idx].success = true;

        for(let i = 0; i < order.order_trace.length; i++)
        {
          if(order.order_trace[i].name === resData[idx].status)
          {
            resData[idx].success = order.order_trace[i].success;
          }
        }

        resData[idx].status = orderStatusMapper[resData[idx].status];

        if(!resData[idx].success) resData[idx].status = <div style={{display: "flex", "alignItems": "flex-end"}}><span style={{ color: red[500] }}>{resData[idx].status}</span> <ReportProblemIcon style={{ color: red[500] }}></ReportProblemIcon></div>;
        
        if (this.props.addOrderToShipment) resData[idx].isAddLoading = false;
        if (this.props.shipmentDetails) resData[idx].isRemoveLoading = false;
      });

      this.setState({ tableData: resData, isLoading: false });
      return;
    } else if (dataRoute.includes('shipments')) {
      resData.forEach((shipment, idx) => {
        resData[idx].number = resData[idx].number
          ? resData[idx].number.toUpperCase()
          : '';
        // resData[idx].shippingMethod = 'Express';
        resData[idx].date = moment(shipment.date).format(
          process.env.REACT_APP_DATE_FORMAT
        );

        resData[idx].eta = moment(shipment.eta).format(
          process.env.REACT_APP_DATE_FORMAT
        );

        resData[idx].status = shipmentStatusMapper[resData[idx].status];
      });
      this.setState({ tableData: resData, isLoading: false });
      return;
    } else if (dataRoute === 'settings/shipping-fees') {
      resData.forEach((order, idx) => {
        resData[idx].pricePerKgStr = numeral(resData[idx].pricePerKg).format(
          '($0.00 a)'
        );
        resData[idx].avgDTStr = resData[idx].avgDT + ' Day(s)';
      });
      this.setState({ tableData: resData, isLoading: false });
      return;
    } else if (dataRoute === 'discounts') {
      resData.forEach((discount, idx) => {
        resData[idx].code = resData[idx].number ? resData[idx].number : '';
        resData[idx].valueStr =
          resData[idx].type === 'PROMO'
            ? `${numeral(resData[idx].value).format('(0.00)')}%`
            : numeral(resData[idx].value).format('($0.00 a)');
        resData[idx].typeStr =
          resData[idx].type === 'PROMO' ? 'Promo Code' : 'Voucher';

        resData[idx].start_date = moment(discount.start_date).format(
          process.env.REACT_APP_DATE_FORMAT
        );
        resData[idx].end_date = moment(discount.end_date).format(
          process.env.REACT_APP_DATE_FORMAT
        );
        if (discount.type === 'PROMO') {
          if (moment(discount.end_date) < moment())
            resData[idx].used = 'Expired';
          else resData[idx].used = 'Available';
        } else {
          if (discount.used >= 1) resData[idx].used = 'Used';
          else if (moment(discount.end_date) < moment())
            resData[idx].used = 'Expired';
          else resData[idx].used = 'Available';
        }
      });
      this.setState({ tableData: resData, isLoading: false });
      return;
    } else {
      resData.forEach((order, idx) => {
        resData[idx].date = moment(order.date).format(
          process.env.REACT_APP_DATE_FORMAT
        );
        resData[idx].netjeekETA = moment(order.netjeekETA).format(
          process.env.REACT_APP_DATE_FORMAT
        );
      });
      this.setState({ tableData: resData, isLoading: false });
      return;
    }
  };

  handleSubmit = async (event) => {
    if (event.key === 'Enter') {
      const filterData = { ...this.state.filterData };
      const filterQuery = { ...this.state.filterQuery };
      const paginationOptions = { ...this.state.paginationOptions };
      paginationOptions.page = 0;
      Object.keys(filterData).forEach((item) => {
        if (
          ![
            'Order Status',
            'Shipment Status',
            'Status',
            'Region',
            'Retailer',
            'price',
            'Price Per Kg',
            'pricePerKg',
            'Type',
          ].includes(item) &&
          filterData[item]
        ) {
          filterQuery['$search'][`${queryNameMapper[item]}`] = filterData[item];
        }
      });
      await this.setState({ filterQuery, paginationOptions });
      this.getItems();
    }
  };
  handleChange = async (event) => {
    const filterQuery = { ...this.state.filterQuery };
    const filterData = { ...this.state.filterData };
    const name = event.target.name;
    const value = event.target.value;
    filterData[name] = value;
    if (value === '') {
      delete filterData[name];
    }

    await this.setState({ filterQuery, filterData });
  };

  clearInput = async (event, name) => {
    const filterQuery = { ...this.state.filterQuery };
    const filterData = { ...this.state.filterData };
    filterData[name] = '';
    await this.setState({ filterData });
    if (filterQuery['$search'][queryNameMapper[name]]) {
      delete filterQuery['$search'][`${queryNameMapper[name]}`];
      await this.setState({ filterQuery });
      this.getItems();
    }
  };

  handleSelectChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const filterQuery = { ...this.state.filterQuery };
    const filterData = { ...this.state.filterData };
    const paginationOptions = { ...this.state.paginationOptions };
    paginationOptions.page = 0;

    if (name === 'Region') this.setState({ selectedReigon: value });

    if (filterQuery['failed']) delete filterQuery['failed'];
    if (name === 'Order Status' && value === 'FAILED') {
      delete filterQuery['status'];
      filterQuery['failed'] = 1;
    } else filterQuery[`${queryNameMapper[name]}`] = value;
    filterData[name] = value;
    if (value === '' || value === 'ALL') {
      delete filterQuery[`${queryNameMapper[name]}`];
    }

    await this.setState({ filterQuery, filterData, paginationOptions });
    this.getItems();
  };

  handleSliderChange = (event, values, name) => {
    const filterQuery = { ...this.state.filterQuery };
    const filterData = { ...this.state.filterData };
    const paginationOptions = { ...this.state.paginationOptions };
    paginationOptions.page = 0;
    if (values.length === 0) {
      delete filterQuery[`${queryNameMapper[name]}`];
      delete filterData[name];
      this.setState(
        {
          filterQuery,
          filterData,
        },
        () => {
          this.getItems();
        }
      );
      return;
    }

    filterQuery[`${queryNameMapper[name]}`] = {
      $gte: values[0],
      $lte: values[1],
    };
    filterData[name] = values;
    this.setState({
      filterQuery,
      paginationOptions,
      filterData,
    });
  };

  handleDateChange = ({ startDate, endDate }) => {};

  handleChangePage = (event, page) => {
    this.setState(
      {
        paginationOptions: { ...this.state.paginationOptions, page },
      },
      () => {
        this.getItems();
      }
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: event.target.value,
        paginationOptions: {
          ...this.state.paginationOptions,
          limit: event.target.value,
          page: 0,
        },
      },
      () => {
        this.getItems();
      }
    );
  };
  togglePopover = (state) => {
    this.setState({ isDeletePopoverOpen: state });
  };
  handleDelete = async (id) => {
    this.setState({ isDeleteButtonLoading: true });

    try {
      const response = await netjeekServer.delete(
        `/settings/shipping-fees/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        await this.togglePopover(false);
        this.props.setSuccessMessages(['Shipping Fee Deleted Successfully']);
        this.getItems();
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;
      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666'], {
          containerId: 1,
          toastId: 1,
        });
      }
      await this.togglePopover(false);
    }
    // this.setState({ usersData: usersData });
    this.setState({ isDeleteButtonLoading: false });
  };
  handleDiscountDelete = async (id) => {
    this.setState({ isDeleteButtonLoading: true });

    try {
      const response = await netjeekServer.delete(`/discounts/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response) {
        await this.togglePopover(false);
        this.props.setSuccessMessages(['Discount Code Deleted Successfully']);
        this.getItems();
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;
      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666'], {
          containerId: 1,
          toastId: 1,
        });
      }
      await this.togglePopover(false);
    }
    // this.setState({ usersData: usersData });
    this.setState({ isDeleteButtonLoading: false });
  };
  sortData = (itemName) => {
    if (itemName === 'Action' || itemName === 'Status') {
      return;
    }
    const sortList = { ...this.state.sortList };
    const sortQuery = { ...this.state.sortQuery };
    let currentState = sortList[itemName];
    let newState = (currentState + 1) % 3;
    sortList[itemName] = newState;
    if (itemName === 'Name') {
      sortQuery['$sort'][`first_name`] = newState === 2 ? -1 : newState;
      if (newState === 0) delete sortQuery['$sort'][`first_name`];
    } else {
      sortQuery['$sort'][`${queryNameMapper[itemName]}`] =
        newState === 2 ? -1 : newState;
    }
    if (newState === 0) {
      delete sortQuery['$sort'][`${queryNameMapper[itemName]}`];
    }
    this.setState(
      {
        sortList,
        sortQuery,
        paginationOptions: {
          ...this.state.paginationOptions,
          page: 0,
        },
      },
      () => {
        this.getItems();
      }
    );
  };

  setSelectedItem = async (item) => {
    await this.setState({ selectedItem: item });
  };
  setTextValue = (searchItem, firstItem, secondItem) => {
    switch (searchItem) {
      case 'Price':
        return `$${firstItem} - $${secondItem}`;
      case 'Delivery Time':
        return `${firstItem} Day(s) - ${secondItem} Day(s)`;
      default:
        return `${firstItem} - ${secondItem}`;
    }
  };

  handleClick = (event, idx) => {
    const popoverAnchorEl = [...this.state.popoverAnchorEl];
    const isPopoverOpen = [...this.state.isPopoverOpen];
    popoverAnchorEl[idx] = event.target;
    isPopoverOpen[idx] = true;
    this.setState({ popoverAnchorEl, isPopoverOpen });
  };

  handleSliderClose = (idx) => {
    const popoverAnchorEl = [...this.state.popoverAnchorEl];
    const isPopoverOpen = [...this.state.isPopoverOpen];
    popoverAnchorEl[idx] = null;
    isPopoverOpen[idx] = false;
    this.setState({ popoverAnchorEl, isPopoverOpen });
  };
  clearAllFilters = () => {
    this.componentDidMount();
  };
  componentWillUnmount = () => {
    cancelTokenSource.cancel('Canceling The request...');
    cancelTokenSource = axios.CancelToken.source();
  };
  render() {
    const {
      paginationOptions,
      rowsPerPage,
      dateRanges,
      tableData,
      isLoading,
      filterData,
      selectedItem,
      popoverAnchorEl,
      isDeletePopoverOpen,
      isDeleteButtonLoading,
      isPopoverOpen,
      sliderSetting,
    } = this.state;

    const {
      classes,
      tableFields,
      selectOptions,
      searchable,
      pagination,
      openModal,
    } = this.props;
    //

    const { page } = paginationOptions;
    const tabelHeadNames = tableFields.head;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableData.length);
    let renderFilterRow = null;
    if (searchable) {
      renderFilterRow = (
        <TableRow>
          {tableFields.search.map((searchItem, idx) => {
            if (
              searchItem === 'Status' ||
              searchItem === 'Order Status' ||
              searchItem === 'Shipment Status' ||
              searchItem === 'Retailer' ||
              searchItem === 'Region' ||
              searchItem === 'Type'
            ) {
              return (
                <CustomTableFilterCell
                  className={classes.searchCell}
                  align='left'
                  key={idx}
                >
                  <Select
                    disableUnderline
                    className={classes.margin}
                    name={searchItem}
                    fullWidth
                    // multiple
                    value={[filterData[searchItem]]}
                    onChange={this.handleSelectChange}
                  >
                    {selectOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {/* <ListItemText primary={name} /> */}
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </CustomTableFilterCell>
              );
            } else if (
              searchItem === 'Price' ||
              searchItem === 'Quantity Ordered' ||
              searchItem === 'Quantity Received' ||
              searchItem === 'Delivery Time' ||
              searchItem === 'Amount'
            ) {
              return (
                <CustomTableFilterCell
                  className={classes.searchCell}
                  align='left'
                  key={idx}
                >
                  <Input
                    disableUnderline
                    id={`SearchField${idx}`}
                    inputProps={{ 'aria-label': 'naked' }}
                    name={`${tableFields.head[idx]}`}
                    style={{ fontSize: '13px' }}
                    value={
                      filterData[tableFields.head[idx]]
                        ? this.setTextValue(
                            searchItem,
                            filterData[tableFields.head[idx]][0],
                            filterData[tableFields.head[idx]][1]
                          )
                        : ''
                    }
                    placeholder={`Search ${searchItem}`}
                    onKeyDown={this.handleSubmit}
                    onClick={(event) => this.handleClick(event, 6 - idx)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          style={{
                            visibility: filterData[tableFields.head[idx]]
                              ? 'unset'
                              : 'hidden',
                            padding: 0,
                          }}
                          onClick={(event) => {
                            this.handleSliderChange(
                              event,
                              [],
                              tableFields.head[idx]
                            );
                          }}
                          aria-label='clearFilter'
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  <Popover
                    id={`popover${searchItem}-${idx}`}
                    open={isPopoverOpen[6 - idx]}
                    anchorEl={popoverAnchorEl[6 - idx]}
                    onClose={() => this.handleSliderClose(6 - idx)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box className={classes.popoverContainer}>
                      <Slider
                        name={`${tableFields.head[idx]}`}
                        {...sliderSetting[searchItem]}
                        classes={{ thumb: classes.popoverMark }}
                        value={filterData[tableFields.head[idx]]}
                        onChange={(event, value) =>
                          this.handleSliderChange(
                            event,
                            value,
                            tableFields.head[idx]
                          )
                        }
                        onChangeCommitted={() => {
                          this.handleSliderClose(6 - idx);
                          this.getItems();
                        }}
                      />
                    </Box>
                  </Popover>
                </CustomTableFilterCell>
              );
            } else if (searchItem === 'Date Range') {
              return (
                <CustomTableFilterCell
                  className={classes.searchCell}
                  align='left'
                  key={idx}
                >
                  <DateRangePicker
                    small
                    daySize={35}
                    firstDayOfWeek={6}
                    noBorder
                    showClearDates
                    reopenPickerOnClearDates
                    displayFormat={process.env.REACT_APP_DATE_FORMAT}
                    hideKeyboardShortcutsPanel
                    isOutsideRange={() => false}
                    startDatePlaceholderText='From Date'
                    endDatePlaceholderText='To Date'
                    startDateId={`startDate${tabelHeadNames[idx]}`}
                    endDateId={`endDate${tabelHeadNames[idx]}`}
                    // name={tabelHeadNames[idx]}
                    startDate={dateRanges[tabelHeadNames[idx]].startDate}
                    endDate={dateRanges[tabelHeadNames[idx]].endDate}
                    onDatesChange={async ({ startDate, endDate }) => {
                      const dateRanges = { ...this.state.dateRanges };
                      const filterQuery = { ...this.state.filterQuery };
                      switch (dateRanges[tabelHeadNames[idx]].focusedInput) {
                        case 'startDate':
                          if (startDate !== null) {
                            const start = new Date(
                              startDate.clone().toISOString()
                            );
                            dateRanges[
                              tabelHeadNames[idx]
                            ].startDate = startDate;
                            filterQuery[tabelHeadNames[idx]] = {
                              ...filterQuery[tabelHeadNames[idx]],
                            };
                            filterQuery[
                              queryNameMapper[tabelHeadNames[idx]]
                            ] = {
                              ...filterQuery[
                                queryNameMapper[tabelHeadNames[idx]]
                              ],
                              $gte: start,
                            };
                          } else {
                            dateRanges[tabelHeadNames[idx]].startDate = null;
                            delete filterQuery[
                              queryNameMapper[tabelHeadNames[idx]]
                            ];
                          }
                          break;
                        case 'endDate':
                          if (endDate !== null) {
                            const end = new Date(endDate.clone().toISOString());
                            dateRanges[tabelHeadNames[idx]].endDate = endDate;
                            filterQuery[tabelHeadNames[idx]] = {
                              ...filterQuery[tabelHeadNames[idx]],
                            };
                            filterQuery[
                              queryNameMapper[tabelHeadNames[idx]]
                            ] = {
                              ...filterQuery[
                                queryNameMapper[tabelHeadNames[idx]]
                              ],
                              $lte: end,
                            };
                          } else {
                            dateRanges[tabelHeadNames[idx]].endDate = null;
                            delete filterQuery[
                              queryNameMapper[tabelHeadNames[idx]]
                            ];
                          }
                          break;
                        default:
                          break;
                      }
                      // if (endDate !== null && startDate !== null)
                      await this.setState({
                        dateRanges,
                        filterQuery,
                      });
                      this.getItems();
                    }}
                    focusedInput={dateRanges[tabelHeadNames[idx]].focusedInput}
                    onFocusChange={(focusedInput) => {
                      const dateRanges = { ...this.state.dateRanges };
                      dateRanges[
                        tabelHeadNames[idx]
                      ].focusedInput = focusedInput;
                      this.setState({
                        dateRanges,
                      });
                    }}
                  />
                </CustomTableFilterCell>
              );
            } else if (searchItem !== null) {
              return (
                <CustomTableFilterCell
                  className={classes.searchCell}
                  align='left'
                  key={idx}
                >
                  <Input
                    disableUnderline
                    id={`SearchField${idx}`}
                    style={{ fontSize: '13px' }}
                    inputProps={{ 'aria-label': 'naked' }}
                    name={`${tableFields.head[idx]}`}
                    value={filterData[tableFields.head[idx]]}
                    type='text'
                    placeholder={`Search ${searchItem}`}
                    onKeyDown={this.handleSubmit}
                    onChange={this.handleChange}
                    endAdornment={
                      // filterData[tableFields.head[idx]] && (
                      <InputAdornment
                        position='end'
                        style={{
                          visibility: filterData[tableFields.head[idx]]
                            ? 'unset'
                            : 'hidden',
                          padding: 0,
                        }}
                      >
                        <IconButton
                          // onClick={event => this.clearFieldData(event, 6 - idx)}
                          onClick={(event) =>
                            this.clearInput(event, tableFields.head[idx])
                          }
                          aria-label='clearFilter'

                          // onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                      // )
                    }
                  />
                </CustomTableFilterCell>
              );
            } else {
              return (
                <CustomTableFilterCell
                  className={classes.searchCell}
                  key={idx}
                />
              );
            }
          })}
        </TableRow>
      );
    }
    return (
      <Box>
        <div
          className={classes.tableWrapper}
          style={{ overflowX: isLoading ? 'unset' : 'auto', width: '100%' }}
        >
          {searchable && (
            <Box
              style={{
                display: 'flex',
                width: '99%',
                justifyContent: 'flex-end',
              }}
              mb={1}
            >
              <Button
                variant='outlined'
                className={classes.clearFiltterButton}
                onClick={this.clearAllFilters}
                endIcon={
                  <img
                    alt='search-icon'
                    heigt={20}
                    width={20}
                    src='https://img.icons8.com/ios/50/000000/broom.png'
                  />
                }
              >
                Clear All Filters
              </Button>
            </Box>
          )}

          {isLoading ? (
            <Grid container spacing={1}>
              {[
                ...Array(
                  tabelHeadNames.length < 5 ? tabelHeadNames.length : 6
                ).keys(),
              ].map((item) => {
                return (
                  <Grid
                    item
                    key={item}
                    xs={
                      12 /
                      (tabelHeadNames.length < 5 ? tabelHeadNames.length : 6)
                    }
                  >
                    <Skeleton
                      variant='rect'
                      style={{ borderRadius: 5 }}
                      disableAnimate
                      height={40}
                      width='100%'
                    />
                  </Grid>
                );
              })}
              {[...Array(rowsPerPage).keys()].map((item) => {
                return (
                  <Grid item key={item} xs={12}>
                    <Skeleton height={45} />
                  </Grid>
                );
              })}
              {pagination && (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    marginRight: 10,
                    marginBottom: 5,
                  }}
                >
                  {[...Array(4).keys()].map((item) => {
                    return (
                      <Box ml={2} key={item}>
                        <Skeleton variant='rect' height={37} width={35} />
                      </Box>
                    );
                  })}
                  <Box ml={2}>
                    <Skeleton variant='rect' height={37} width={100} />
                  </Box>
                  <Box ml={2}>
                    <Skeleton variant='rect' height={37} width={130} />
                  </Box>
                </Grid>
              )}
            </Grid>
          ) : (
            <Table className={classes.table}>
              <TableHead>
                <TableRow
                  style={{
                    // backgroundColor: "#525252",
                    whiteSpace: 'nowrap',
                  }}
                >
                  {tableFields.head.map((item, idx) => {
                    return (
                      <TableCell
                        key={idx}
                        style={{
                          fontSize: 16,
                          // color: '#fff',
                          cursor:
                            item !== 'Mobile' &&
                            item !== 'Action' &&
                            item !== 'Status'
                              ? 'pointer'
                              : 'default',
                        }}
                        onClick={() => this.sortData(item, idx)}
                        align='left'
                      >
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent:
                              item === 'Action' ? 'center' : 'flex-start',
                          }}
                        >
                          {item}
                          {this.state.sortList[item] !== null ? (
                            this.state.sortList[item] === 0 ? (
                              <Box ml={1}>
                                {item !== 'Action' && (
                                  <FontAwesomeIcon
                                    icon={faSort}
                                  ></FontAwesomeIcon>
                                )}
                              </Box>
                            ) : (
                              <Box ml={1}>
                                {item !== 'Action' && (
                                  <FontAwesomeIcon
                                    rotation={
                                      this.state.sortList[item] === 1
                                        ? null
                                        : 180
                                    }
                                    icon={faSortUp}
                                  />
                                )}
                              </Box>
                            )
                          ) : null}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
                {/* && tableData.length !== 0 &&  */}
                {searchable && renderFilterRow}
              </TableHead>

              <TableBody>
                {tableData.map((row) => (
                  <TableRow hover key={row.id} style={{ whiteSpace: 'nowrap' }}>
                    {tableFields.dataKeys.map((key, idx) => {
                      if (key === 'status') {
                        return (
                          <TableCell
                            component='th'
                            scope='row'
                            key={idx}
                            style={{
                              color:
                                row[key] === 'ACTIVE'
                                  ? '#008000'
                                  : row[key] === 'INACTIVE'
                                  ? '#f00'
                                  : '#000',
                            }}
                          >
                            {row[key]}
                          </TableCell>
                        );
                      } else if (key === 'account_number') {
                        return (
                          <TableCell key={idx} component='th' scope='row'>
                            <Link
                              style={{ color: '#00f' }}
                              to={`/admin-portal/customer-profile/${row['id']}`}
                            >
                              {row[key]}
                            </Link>
                          </TableCell>
                        );
                      } else if (key === 'item_name') {
                        return (
                          <TableCell
                            key={idx}
                            style={{ whiteSpace: 'pre-wrap', width: '220px' }}
                            component='th'
                            scope='row'
                          >
                            {row[key]}
                          </TableCell>
                        );
                      } else if (key === 'variant') {
                        return (
                          <TableCell
                            key={idx}
                            style={{ whiteSpace: 'pre-wrap' }}
                            component='th'
                            scope='row'
                          >
                            {row[key].length > 1
                              ? row[key].map((item, idx) => {
                                  return (
                                    <Box key={idx}>
                                      {item}
                                      <br />
                                      <br />
                                    </Box>
                                  );
                                })
                              : row[key]}
                          </TableCell>
                        );
                      } else if (key === 'image_path') {
                        return (
                          <TableCell
                            key={idx}
                            className='tableImage'
                            component='th'
                            scope='row'
                          >
                            <Image
                              style={{
                                backgroundColor: 'transparent',
                                padding: 0,
                              }}
                              // aspectRatio={3 / 4}
                              src={row[key]}
                              imageStyle={{
                                width: '80px',
                                position: 'unset',
                                height: 'unset',
                                maxWeight: '100%',
                                maxHeight: '100%',
                              }}
                              alt={idx}
                              loading={
                                <CircularProgress
                                  size={20}
                                  style={{ padding: 0 }}
                                />
                              }
                            />
                          </TableCell>
                        );
                      } else if (key === 'action') {
                        return (
                          <TableCell
                            key={idx}
                            component='th'
                            scope='row'
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Button
                              color='primary'
                              variant='contained'
                              disabled={row['qty_received'] === row['quantity']}
                              onClick={() => {
                                this.receiveItemRef.current.setOpen(true);
                                this.setSelectedItem(row);
                              }}
                              style={{ padding: '4px 30px' }}
                            >
                              {row['qty_received'] === row['quantity']
                                ? 'Received'
                                : 'Receive'}
                            </Button>
                          </TableCell>
                        );
                      } else if (key === 'addAction') {
                        return (
                          <TableCell key={idx} component='th' scope='row'>
                            <Button
                              color='primary'
                              variant='contained'
                              disabled={row.isAddLoading}
                              onClick={() => {
                                this.addOrderToShipment(row, row.id);
                              }}
                              style={{ padding: '4px 30px', width: 120 }}
                            >
                              Add
                              {row.isAddLoading && (
                                <CircularProgress
                                  size={24}
                                  style={{
                                    marginLeft: 4,
                                    position: 'absolute',
                                  }}
                                />
                              )}
                            </Button>
                          </TableCell>
                        );
                      } else if (key === 'printModalAction') {
                        return (
                          <TableCell
                            key={idx}
                            component='th'
                            scope='row'
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() => {
                                this.printItemsRef.current.setOpen(true);
                                this.setSelectedItem(row);
                              }}
                              style={{ padding: '4px 30px', width: 120 }}
                            >
                              Print Label
                            </Button>
                          </TableCell>
                        );
                      } else if (key === 'printAction') {
                        return (
                          <TableCell
                            key={idx}
                            component='th'
                            scope='row'
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() => {
                                // this.printItemsRef.current.setOpen(true);
                                // this.setSelectedItem(row);
                              }}
                              style={{ padding: '4px 30px', width: 120 }}
                            >
                              Print
                            </Button>
                          </TableCell>
                        );
                      } else if (key === 'removeAction') {
                        return (
                          <TableCell
                            key={idx}
                            component='th'
                            scope='row'
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Button
                              color='primary'
                              variant='contained'
                              disabled={row.isRemoveLoading}
                              onClick={() => {
                                this.removeOrderFromShipment(row, row.id);
                              }}
                              style={{ padding: '4px 30px', width: 120 }}
                            >
                              Remove
                              {row.isRemoveLoading && (
                                <CircularProgress
                                  size={22}
                                  style={{
                                    marginLeft: 4,
                                    position: 'absolute',
                                  }}
                                />
                              )}
                            </Button>
                          </TableCell>
                        );
                      } else if (key === 'editFeesAction') {
                        return (
                          <TableCell
                            key={idx}
                            component='th'
                            scope='row'
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() => {
                                // this.receiveItemRef.current.setOpen(true);
                                // this.setSelectedItem(row);
                                //Remove Order from Shipment
                                openModal.current.setOpen(true);
                              }}
                              style={{ padding: '4px 30px', width: 120 }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        );
                      } else if (key === 'editAndeDeleteDiscountAction') {
                        return (
                          <TableCell
                            key={idx}
                            component='th'
                            scope='row'
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() => {
                                // this.receiveItemRef.current.setOpen(true);
                                this.setSelectedItem(row);
                                //Remove Order from Shipment
                                this.editDiscountRef.current.setOpen(true);
                              }}
                              style={{ padding: '4px 30px', width: 75 }}
                            >
                              Edit
                            </Button>
                            <Button
                              variant='contained'
                              onClick={(event) => {
                                this.setSelectedItem(row);

                                this.setState({
                                  deleteAnchorEl: event.currentTarget,
                                });

                                this.togglePopover(true);
                              }}
                              className={classes.deleteButton}
                            >
                              Delete
                            </Button>
                            <Popper
                              open={isDeletePopoverOpen}
                              anchorEl={this.state.deleteAnchorEl}
                              placement='bottom'
                              transition
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper style={{ display: 'flex' }}>
                                    <Typography className={classes.typography}>
                                      Delete this Discount Code?
                                    </Typography>
                                    <Button
                                      className={classes.modalButton}
                                      onClick={(event) => {
                                        this.setState({
                                          deleteAnchorEl: null,
                                        });

                                        this.togglePopover(false);
                                      }}
                                    >
                                      No
                                    </Button>
                                    <Button
                                      className={classes.modalButton}
                                      disabled={isDeleteButtonLoading}
                                      onClick={() => {
                                        this.handleDiscountDelete(
                                          selectedItem.id
                                        );
                                      }}
                                    >
                                      {isDeleteButtonLoading && (
                                        <CircularProgress
                                          size={22}
                                          style={{
                                            marginLeft: 4,
                                            position: 'absolute',
                                          }}
                                        />
                                      )}
                                      Yes
                                    </Button>
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </TableCell>
                        );
                      } else if (key === 'editAndeDeleteAction') {
                        return (
                          <TableCell
                            key={idx}
                            component='th'
                            scope='row'
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() => {
                                // this.receiveItemRef.current.setOpen(true);
                                this.setSelectedItem(row);
                                //Remove Order from Shipment
                                this.editShippingFeesRef.current.setOpen(true);
                              }}
                              style={{ padding: '4px 30px', width: 75 }}
                            >
                              Edit
                            </Button>
                            <Button
                              variant='contained'
                              onClick={(event) => {
                                this.setState({
                                  deleteAnchorEl: event.currentTarget,
                                });
                                this.togglePopover(true);
                              }}
                              className={classes.deleteButton}
                            >
                              Delete
                            </Button>
                            <Popper
                              open={isDeletePopoverOpen}
                              anchorEl={this.state.deleteAnchorEl}
                              placement='bottom'
                              transition
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper style={{ display: 'flex' }}>
                                    <Typography className={classes.typography}>
                                      Delete this Shipping Fee?
                                    </Typography>
                                    <Button
                                      className={classes.modalButton}
                                      onClick={(event) => {
                                        this.setState({
                                          deleteAnchorEl: null,
                                        });

                                        this.togglePopover(false);
                                      }}
                                    >
                                      No
                                    </Button>
                                    <Button
                                      className={classes.modalButton}
                                      disabled={isDeleteButtonLoading}
                                      onClick={() => this.handleDelete(row.id)}
                                    >
                                      {isDeleteButtonLoading && (
                                        <CircularProgress
                                          size={22}
                                          style={{
                                            marginLeft: 4,
                                            position: 'absolute',
                                          }}
                                        />
                                      )}
                                      Yes
                                    </Button>
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </TableCell>
                        );
                      } else if (key === 'order_id') {
                        return (
                          <TableCell key={idx} component='th' scope='row'>
                            <Link
                              style={{ color: '#00f' }}
                              to={`/admin-portal/order-details/${row['id']}`}
                            >
                              {row[key]}
                            </Link>
                          </TableCell>
                        );
                      } else if (key === 'notes') {
                        return (
                          <TableCell
                            key={idx}
                            component='th'
                            scope='row'
                            style={{
                              whiteSpace: 'pre',
                              wordWrap: 'break-word',
                            }}
                          >
                            <ShowNotes notes={row[key]} />
                          </TableCell>
                        );
                      } else if (key === 'number') {
                        return (
                          <TableCell key={idx} component='th' scope='row'>
                            <Link
                              style={{ color: '#00f' }}
                              to={`/admin-portal/shipment-details/${row['id']}`}
                            >
                              {row[key]}
                            </Link>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={idx} component='th' scope='row'>
                            {row[key]}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 45 * emptyRows,
                    }}
                  >
                    {emptyRows >= rowsPerPage ? (
                      <TableCell colSpan={7}>
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontSize: 22,
                            fontWeight: 500,
                          }}
                        >
                          {emptyRows >= rowsPerPage
                            ? 'No records to display'
                            : null}
                        </Box>
                      </TableCell>
                    ) : null}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
          <ReceivItemModal
            {...this.props}
            getItems={this.getItems}
            itemData={selectedItem}
            customerId={this.props.customerId}
            ref={this.receiveItemRef}
          />
          <PrintLabelsModal
            {...this.props}
            // getItems={this.getItems}
            itemData={selectedItem}
            // customerId={this.props.customerId}
            ref={this.printItemsRef}
          />
          <EditShippingFees
            {...this.props}
            getItems={this.getItems}
            itemData={selectedItem}
            ref={this.editShippingFeesRef}
          />
          <EditDiscount
            {...this.props}
            getItems={this.getItems}
            itemData={selectedItem}
            ref={this.editDiscountRef}
          />
        </div>
        {pagination && !isLoading && (
          <TablePagination
            style={{
              borderWidth: 0,
              paddingTop: 17,
              fontSize: 14,
            }}
            rowsPerPageOptions={this.props.rowsPerPageOptions}
            component='div'
            count={paginationOptions.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActionsWrapped}
          />
        )}
      </Box>
    );
  }
}

export default withStyles(styles)(TableComponent);
