import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core/';
import { toast } from 'react-toastify';
import SearchBar from 'material-ui-search-bar';
import netjeekServer from 'api/netjeek';
import AddEditUser from 'components/UserManagement/AddEditUser';
import seedrandom from 'seedrandom';
import UserCard from 'components/UserManagement/UserCard';
import { Skeleton } from '@material-ui/lab/';

const errCodesMapper = {
  1000: 'Quantity is missing',
  3000: 'Quantity is not valid',
  5000: 'Unauthenticated Action',
  666: 'Network Error. Please Try Again!',
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
    justifyContent: 'center',
    height: '100%',
  },
  skeletonContainer: {
    marginBottom: 15,
    marginTop: 15,
  },
  skeleton: {
    borderRadius: 5,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
  },

  searchBarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  searchBar: {
    width: 'auto',
    maxWidth: 700,
    flexGrow: 1,
  },
  userCardsContainer: {
    marginBottom: 15,
    marginTop: 5,
  },
  button: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    margin: theme.spacing(1),
  },
}));

export default function UserManagement(props) {
  const classes = useStyles();
  const [usersData, setUsersData] = useState([]);

  const [tempUsersData, setTempUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUsers();
    props.changePageHeader('Settings | Users Management');
  }, []);

  const parseUsersData = (resData) => {
    // const usersData = [...this.state.usersData];
    resData.forEach((user, idx) => {
      var rngB = seedrandom(`${user.last_name}Red${user.first_name[1]}`);
      const B = Math.floor(rngB() * 240);

      var rngR = seedrandom(`${user.last_name}Green${user.first_name[1]}`);
      const R = Math.floor(rngR() * 100);

      var rngG = seedrandom(`${user.last_name}Blue${user.first_name[1]}`);
      const G = Math.floor(rngG() * 160);

      resData[idx].imgColor = `rgb(${R}, ${G}, ${B})`;
      resData[idx].initials =
        user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0];
    });
    setTempUsersData(resData);
    setUsersData(resData);
  };

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await netjeekServer.get(`/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const resData = response.data.data;

      parseUsersData(resData);
      // this.setState({ usersData: resData });
      setIsLoading(false);
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];

      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                props.history.push('/signin');
              }
            });
          }
        }
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const searchUsers = (searchWord) => {
    if (searchWord === '') {
      setUsersData(tempUsersData);
      // this.setState({ usersData: this.state.tempUsersData });
    } else {
      searchWord = searchWord.toLowerCase();
      const usersData = tempUsersData.filter(
        (user) =>
          user.first_name.toLowerCase().includes(searchWord) ||
          user.last_name.toLowerCase().includes(searchWord) ||
          user.email.toLowerCase().includes(searchWord)
      );
      setUsersData(usersData);
      // this.setState({ usersData: usersData });
    }
  };

  const renderUserCard = usersData.map((user, idx) => {
    return (
      <Grid item xs={12} md={6} lg={4} xl={3} key={user.id}>
        <UserCard user={user} getUsers={getUsers} {...props} />
      </Grid>
    );
  });

  return (
    <Grid container className={classes.root}>
      <AddEditUser
        {...props}
        userData={{}}
        modalType='Add'
        getUsers={getUsers}
      />
      <Grid item container xs={12} className={classes.header}>
        <Grid item xs={12} lg={12} className={classes.searchBarContainer}>
          <SearchBar
            placeholder='Search'
            onChange={(searchWord) => {
              searchUsers(searchWord);
            }}
            className={classes.searchBar}
          />
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container spacing={3} className={classes.skeletonContainer}>
          {[...Array(11)].map((item, idx) => {
            return (
              <Grid item key={idx} xs={12} md={6} lg={4} xl={3}>
                <Skeleton
                  variant='rect'
                  animation='wave'
                  height={100}
                  className={classes.skeleton}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid
          item
          container
          xs={12}
          spacing={2}
          className={classes.userCardsContainer}
        >
          {renderUserCard}
        </Grid>
      )}
    </Grid>
  );
}

// export default withStyles(styles)(UserManagement);
