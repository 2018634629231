const orderStatusMapper = {
  PLACED_BY_CUSTOMER: 'Placed By Customer',
  ORDER_PLACED_AMAZON: 'Placed At Amazon',
  ORDER_PLACED_WALMART: 'Placed At Walmart',
  ORDER_PLACED_ALIEXPRESS: 'Placed At AliExpress',
  PARTIALLY_RECEIVED_CANADA: 'Partially Received In Canada',
  FULLY_RECEIVED_CANADA: 'Fully Received In Canada',
  ASSIGNED_TO_SHIPMENT: 'Assigned To Shipment',
  SHIPPED: 'Shipped',
  IN_TRANSIT: 'In Transit',
  SHIPPED_TO_MISRATA: 'Shipped to Misrata',
  SHIPPED_TO_BENGHAZI: 'Shipped to Benghazi',
  RECEIVED_IN_TRIPOLI: 'Received In Tripoli',
  RECEIVED_IN_MISRATA: 'Received In Misrata',
  RECEIVED_IN_BENGHAZI: 'Received In Benghazi',
  ASSIGNED_TO_DRIVER: 'Assigned To Driver',
  OUT_FOR_DELIVERY: 'Out For Delivery',
  DELIVERED: 'Delivered',
  RETURNED: 'Returned',
  PICKED_BY_CUSTOMER: 'Picked By Customer',
};

export default orderStatusMapper;
