import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { Paper, Box } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import shipmentStatusMapper from 'shared/shipmentStatusMapper';
import PackShipmentModal from 'components/ShipmentTraceCardAtions/PackShipment';
import ShipShipmentModal from 'components/ShipmentTraceCardAtions/ShipShipment';
import ReceiveInTransitModal from 'components/ShipmentTraceCardAtions/ReceiveInTransit';
import ShipFromTransitModal from 'components/ShipmentTraceCardAtions/ShipFromTransit';
import ReceiveInTripoliModal from 'components/ShipmentTraceCardAtions/ReceiveInTripoli';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(3.5),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  completed: {
    backgroundImage: '#0f0',
  },
}));

export default function TraceCard(props) {
  const classes = useStyles();
  const [steps, setSteps] = React.useState([]);
  const shipmentData = props.shipmentData;
  //
  useEffect(() => {
    setSteps(props.traceData);
  }, [props]);

  const getStepName = (name, success, hidden) => {
    switch (name) {
      case 'CREATED':
        return success ? (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='body1'>
              {shipmentStatusMapper[name]}
            </Typography>
            {!hidden && <PackShipmentModal {...props} />}
          </Box>
        ) : (
          shipmentStatusMapper[name]
        );
      case 'PACKED':
        return success ? (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{shipmentStatusMapper[name]}</Typography>
            {!hidden && <ShipShipmentModal {...props} />}
          </Box>
        ) : (
          shipmentStatusMapper[name]
        );
      case 'SHIPPED':
        return success ? (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{shipmentStatusMapper[name]}</Typography>
            {!hidden && <ReceiveInTransitModal {...props} />}
          </Box>
        ) : (
          shipmentStatusMapper[name]
        );
      case 'RECEIVED_IN_TRANSIT_HUB':
        return success ? (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{shipmentStatusMapper[name]}</Typography>
            {!hidden && <ShipFromTransitModal {...props} />}
          </Box>
        ) : (
          shipmentStatusMapper[name]
        );
      case 'SHIPPED_FROM_TRANSIT_HUB':
        return success ? (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{shipmentStatusMapper[name]}</Typography>
            {!hidden && <ReceiveInTripoliModal {...props} />}
          </Box>
        ) : (
          shipmentStatusMapper[name]
        );
      default:
        return shipmentStatusMapper[name];
    }
  };

  const getStepContent = (step, success) => {
    switch (step) {
      case 'ASSIGNED_TO_SHIPMENT':
        return success ? '' : `Assign To Shipment Manually`;
      case 'ORDER_PLACED_AMAZON':
        return (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '15px',
            }}
          >
            <Typography>{shipmentData.order_numbers[0].type}</Typography>
            <Typography>{shipmentData.order_numbers[0].number}</Typography>
          </Box>
        );
      case 2:
        return ``;
      default:
        return '';
    }
  };

  return (
    <Paper className={classes.root}>
      <Typography variant='h5' component='h3' gutterBottom>
        Shipment Trace
      </Typography>
      {steps.length === 0 ? (
        [...Array(5)].map((item, idx) => {
          return (
            <Box ml={2} mt={4} key={idx} mb={6}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Skeleton variant='circle' width={30} height={30} />
                <Skeleton
                  height={15}
                  variant='rect'
                  width='80%'
                  style={{ marginBottom: 6, marginLeft: 8 }}
                />
              </Box>
              <Skeleton
                height={12}
                variant='rect'
                width='40%'
                style={{ marginLeft: 40 }}
              />
            </Box>
          );
        })
      ) : (
        <Stepper orientation='vertical' activeStep={steps.length - 1}>
          {steps.map((step, idx) => {
            return (
              <Step key={idx} active={true}>
                <StepLabel
                  StepIconProps={{
                    completed: step.success,
                    error: !step.success,
                  }}
                >
                  {getStepName(step.name, step.success, idx < steps.length - 1)}
                </StepLabel>
                <StepContent>
                  <Typography variant='caption' display='block' gutterBottom>
                    {moment(step.timestamp).format(
                      process.env.REACT_APP_DATE_FORMAT
                    )}
                  </Typography>
                  <Typography variant='body2'>
                    {getStepContent(step.name, step.success)}
                  </Typography>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      )}
    </Paper>
  );
}
