import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from '@material-ui/core/';
import TableComponent from 'components/Table/TableComponent';

export default function AddOrdersModal(props) {
  const [open, setOpen] = useState(false);

  const tableFields = {
    head: ['Order Number', 'Date', 'Netjeek ETA', 'Shipping Method', 'Action'],
    search: ['Order Number', 'Date Range', 'Date Range', 'Method', null],
    dataKeys: ['order_id', 'createdAt', 'eta', 'shipping_method', 'addAction'],
  };

  return (
    <Box>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          setOpen(true);
          props.setDoneAdd(false);
        }}
      >
        Add Orders
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='add-orders-dialog-title'
        maxWidth='xl'
      >
        <DialogTitle id='add-orders-dialog-title'>
          Add Orders To This Shipment
        </DialogTitle>

        <DialogContent style={{ width: 1110 }}>
          <TableComponent
            {...props}
            dataRoute='orders'
            selectable={false}
            searchable={true}
            tableFields={tableFields}
            selectOptions={[]}
            pagination={true}
            rowsPerPage={5}
            rowsPerPageOptions={[5, 10, 25]}
            shipmentId={props.shipmentId}
            addOrderToShipment
          />
        </DialogContent>
        <DialogActions style={{ margin: 10 }}>
          <Button
            style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
            type='submit'
            variant='contained'
            color='primary'
            onClick={() => {
              setOpen(false);
              props.setDoneAdd(true);
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
