import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core/';
import TableComponent from '../Table/TableComponent';
import DataCard from '../DataCard/DataCard';
import netjeekServer from 'api/netjeek';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import EditCustomerProfile from './EditCustomerProfile';

const errCodesMapper = {
  3000: 'ID is not valid',
  5000: 'Unauthenticated',
  5001: 'Unauthorized',
  6000: "ID doesn't exist",
  666: 'Network Error. Please Try Again!',
};
const tableFields = {
  head: [
    'Order Number',
    'Order Date',
    'Netjeek ETA',
    'Shipping Method',
    // 'Status'
  ],
  search: [
    'Order Number',
    'Date Range',
    'Date Range',
    'Method',
    // 'Status'
  ],
  dataKeys: [
    'order_id',
    'createdAt',
    'eta',
    'shipping_method',
    // 'status'
  ],
};
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
    flexGrow: true,
  },
  tableHolderStyle: {
    padding: theme.spacing(1.5),
    paddingBottom: 0,
  },
  tabelCardHeaderStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  headerStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  linkStyle: {
    color: '#00f',
    fontSize: theme.spacing(2),
  },
}));

export default function CustomerProfile(props) {
  const classes = useStyles();
  const [basicData, setBasicData] = useState({});
  const [shippingAddressData, setShippingAddressData] = useState({});
  const [hasAddress, setHasAddress] = useState(true);
  const customerId = props.match.params.id;
  const [geo_location, setGeo_location] = useState({});

  useEffect(() => {
    props.changePageHeader('Customer Profile');
    getCustomerData(customerId);
  }, []);
  const parseData = (resData) => {
    const {
      id,
      first_name,
      account_number,
      last_name,
      email,
      phone,
      isActive,
      shipping_address,
    } = resData;
    setBasicData({
      account_number: account_number ? account_number.toUpperCase() : '',
      id,
      first_name,
      last_name,
      email,
      phone,
      isActive: isActive ? 'ACTIVE' : 'INACTIVE',
    });

    if (shipping_address) {
      const {
        street_address,
        country,
        city,
        state,
        zip,
        nearest_landmark,
        geo_location,
      } = shipping_address;

      setShippingAddressData({
        street_address,
        country: country ? country : 'Libya',
        state: state ? state[0].toUpperCase() + state.substr(1) : '',
        city: city ? city[0].toUpperCase() + city.substr(1) : '',
        zip,
        nearest_landmark,
        // geo_location,
      });
      setGeo_location(geo_location);
    } else setHasAddress(false);
  };
  const getCustomerData = async (customerId) => {
    try {
      const response = await netjeekServer.get(`/customers/${customerId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const resData = response.data.data;
      parseData(resData);
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];

      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                props.history.push('/signin');
              }
            });
          }
        }
      }
    }
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} className={classes.headerStyle}>
        {/* <Typography variant='h4'>Customer Profile</Typography> */}
        <Link
          to={`/admin-portal/customers`}
          className={classes.linkStyle}
        >{`< Back to List`}</Link>
      </Grid>

      <Grid item xs={12} spacing={2} container>
        <Grid item xs={12} md={12} lg={6}>
          <DataCard
            {...props}
            title='Demographic Data'
            isEditable={true}
            getCustomerData={getCustomerData}
            EditComponent={EditCustomerProfile}
            data={basicData}
          />
        </Grid>

        {hasAddress && (
          <Grid item xs={12} md={12} lg={6}>
            <DataCard
              {...props}
              title='Shipping Address'
              geo_location={geo_location}
              data={shippingAddressData}
            />
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.tableHolderStyle} elevation={1}>
          <div className={classes.tabelCardHeaderStyle}>
            <Typography variant='h5' component='h3' gutterBottom>
              Orders
            </Typography>
          </div>
          <TableComponent
            {...props}
            dataRoute={`orders`}
            selectable={true}
            searchable={false}
            tableFields={tableFields}
            pagination={true}
            rowsPerPage={10}
            rowsPerPageOptions={[]}
            customerId={customerId}
            customerDetails
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
