import React, { Component } from 'react';
import {
  MenuItem,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import download from 'downloadjs';
import sanitize from 'sanitize-filename';
const errCodesMapper = {
  1000: 'Quantity is missing',
  3000: 'Quantity is not valid',
  666: 'Network Error. Please Try Again!',
};
export default class PrintLabelsModal extends Component {
  state = {
    open: false,
    isLoading: false,
    errorMessages: [],
    successMessages: [],
    packageType: '',

    itemData: {},
    itemsPerPackage: 0,
  };

  componentDidMount = () => {};

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
    if (state === false) this.setState({ isLoading: false });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.itemData !== prevProps.itemData) {
      this.setState({ itemData: this.props.itemData });
    }
  };

  generatelabels = async (event) => {
    this.setState({ isLoading: true, successMessages: [], errorMessages: [] });
    const { itemsPerPackage, packageType, itemData } = this.state;
    const itemId = itemData.id;

    console.log('itemId', itemId);
    try {
      let response = null;
      if (packageType === 'single')
        response = await netjeekServer.post(
          `/items/print-label`,
          { type: 'single', itemId },
          {
            responseType: 'arraybuffer',

            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
      else
        response = await netjeekServer.post(
          `/items/print-label`,
          { type: 'package', itemsPerPack: itemsPerPackage, itemId },
          {
            responseType: 'arraybuffer',

            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

      if (response) {
        this.props.setSuccessMessages(['Labels Created Updated Successfully']);

        var file = new Blob([response.data], {
          type: 'application/pdf',
        });
        download(file, `label-${sanitize(itemData.id)}.pdf`, 'application/pdf');
        await this.setState({
          errorMessages: [],
        });
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (err.response.status === 404)
          errorMessages.push(errCodesMapper['666']);
        else if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log('name', name);
    if (name === 'itemsPerPackage' && value < 0) return;
    else this.setState({ [name]: value });
  };
  componentWillUnmount = () => {};
  render() {
    const {
      open,
      errorMessages,
      itemData,
      itemsPerPackage,
      isLoading,
      packageType,
    } = this.state;
    console.log('itemData', itemData);

    return (
      <Dialog
        open={open}
        onClose={() => this.setOpen(false)}
        aria-labelledby='form-dialog-title'
        maxWidth='xl'
      >
        <DialogTitle id='form-dialog-title' style={{ padding: '10px' }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant='h5'>Print labels</Typography>
            <IconButton aria-label='close' onClick={() => this.setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <Snackbar type='error' messages={errorMessages} />

        <DialogContent
          dividers
          style={{ display: 'flex', flexDirection: 'column', width: 610 }}
        >
          <ValidatorForm ref='form' onSubmit={this.generatelabels}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: '16px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                Packing:
              </Typography>
              <TextValidator
                select
                autoFocus
                margin='dense'
                name='packageType'
                validators={['required']}
                errorMessages={['package Type is required']}
                variant='outlined'
                fullWidth
                value={packageType}
                onChange={this.handleChange}
                style={{ marginBottom: '10px' }}
              >
                <MenuItem value={'package'}>Package</MenuItem>
                <MenuItem value={'single'}>Single</MenuItem>
              </TextValidator>
            </Box>

            {packageType === 'package' ? (
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  // variant='h6'
                  // display='block'
                  style={{
                    fontWeight: 600,
                    fontSize: '16px',
                    //   marginRight: 15,
                    width: '30%',
                  }}
                >
                  Items Per pack:
                </Typography>
                <TextValidator
                  fullWidth
                  name='itemsPerPackage'
                  margin='dense'
                  type='Number'
                  validators={['required']}
                  errorMessages={['This field is required']}
                  id='itemsPerPackage'
                  value={itemsPerPackage}
                  onChange={this.handleChange}
                  variant='outlined'
                />
              </Box>
            ) : null}

            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 10,
              }}
            >
              <Button
                style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
                disabled={
                  isLoading ||
                  packageType === '' ||
                  (itemsPerPackage <= 1 && packageType === 'package')
                }
                type='submit'
                variant='contained'
                color='primary'
                // onClick={() => {
                //   this.setOpen(false);
                // }}
                // onClick={event => this.generatelabels(event)}
              >
                Generate Labels
                {isLoading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 4, position: 'absolute' }}
                  />
                )}
              </Button>
            </Box>
          </ValidatorForm>
        </DialogContent>
      </Dialog>
    );
  }
}
