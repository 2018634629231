import React, { useState, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import TableComponent from 'components/Table/TableComponent';
import AddDiscountModal from 'components/Discounts/AddDiscountModal';

const tableFields = {
  head: [
    'Code',
    'Type',
    'Amount',
    'Start Date',
    'End Date',
    'Status',
    'Action',
  ],
  search: ['Code', 'Type', null, 'Date Range', 'Date Range', null, null],
  dataKeys: [
    'code',
    'typeStr',
    'valueStr',
    'start_date',
    'end_date',
    'used',
    'editAndeDeleteDiscountAction',
  ],
};

const tableSelectOptions = [
  {
    label: 'ALL',
    value: 'ALL',
  },
  {
    label: 'PROMO CODE',
    value: 'PROMO',
  },
  {
    label: 'VOUCHER',
    value: 'VOUCHER',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
  },
  tableHolderStyle: {
    padding: theme.spacing(1),
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  tabelCardHeaderStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}));

export default function Discounts(props) {
  const classes = useStyles();
  const [isTableLoading, setITableLoading] = useState(true);
  useEffect(() => {
    props.changePageHeader('Settings | Discount Managment');
  }, []);

  const rerenderTable = (state) => {
    setITableLoading(state);
  };

  return (
    <Grid container justify='center' className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.tableHolderStyle} elevation={1}>
          {isTableLoading && (
            <TableComponent
              {...props}
              dataRoute='discounts'
              selectable={true}
              searchable={true}
              tableFields={tableFields}
              selectOptions={tableSelectOptions}
              rowsPerPage={10}
              rowsPerPageOptions={[5, 10, 25]}
              pagination={true}
            />
          )}
        </Paper>
      </Grid>
      <AddDiscountModal
        {...props}
        rerenderTable={rerenderTable}
        // ref={this.createShippingFeeRef}
      />
    </Grid>
  );
}
