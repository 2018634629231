import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ImageDropzone from '../ImageDropzone/ImageDropzone';

const errCodesMapper = {
  1000: 'Image is missing',
  2000: 'Query is missing',
  3000: 'E-Retailer must be one of [AMAZON, WALMART,ALIEXPRESS]',
  666: 'Network Error. Please Try Again!',
};
export default class AddShortcut extends Component {
  state = {
    open: false,
    isLoading: false,
    errorMessages: [],
    successMessages: [],
    sliderImageFormData: new FormData(),
    imageFile: null,
  };

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.addShortcut();
  };

  setimage = (image) => {
    const imageFile = this.state.imageFile;
    this.setState({ successMessages: [], errorMessages: [] });

    if (image) this.setState({ imageFile: image.file });
    else this.setState({ imageFile: null });
    // sliderImageFormData.set('image', image.file);
    // else sliderImageFormData.delete('image');

    // this.setState({ sliderImageFormData });
  };

  addShortcut = async () => {
    const imageFile = this.state.imageFile;
    const sliderImageFormData = new FormData();
    sliderImageFormData.set('image', imageFile);

    this.setState({ isLoading: true });
    try {
      const response = await netjeekServer.post(
        `/settings/slider`,
        sliderImageFormData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response) {
        this.props.setSuccessMessages(['Slider Image Added Successfully']);
        await this.setState({
          errorMessages: [],
        });
        this.setOpen(false);
        this.props.getImages();
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (err.response.status === 404)
          errorMessages.push(errCodesMapper['666']);
        else if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
  };

  render() {
    // const { modalType } = this.props;
    const { open, errorMessages, successMessages, isLoading } = this.state;
    return (
      <Dialog
        open={open}
        onClose={() => this.setOpen(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          <Typography variant='h5'>Add Slider Image</Typography>
        </DialogTitle>
        <Snackbar type='error' messages={errorMessages} />
        <Snackbar type='success' messages={successMessages} />
        <ValidatorForm
          ref='form'
          onSubmit={this.handleSubmit}
          style={{ width: 500 }}
        >
          <DialogContent>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Slider Image:
              </Typography>
              <ImageDropzone setimage={this.setimage} />
            </Box>
          </DialogContent>
          <DialogActions style={{ margin: 10 }}>
            <Button
              onClick={() => {
                this.setOpen(false);
              }}
              style={{ color: '#000', fontSize: '16px' }}
            >
              Cancel
            </Button>
            <Button
              style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
              disabled={isLoading || !this.state.imageFile}
              type='submit'
              variant='contained'
              color='primary'

              // onClick={event => this.addShortcut(event)}
            >
              Add
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}
