import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, Box, Button } from '@material-ui/core';
import { Cell, Tooltip, Legend, PieChart, Pie, Sector } from 'recharts';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import Paper from '@material-ui/core/Paper';
import withWidth from '@material-ui/core/withWidth';
import { DateRangePicker } from 'react-dates';
import netjeekServer from 'api/netjeek';
import { toast } from 'react-toastify';
import { errCodesMapper } from './chartCommon';
import Skeleton from '@material-ui/lab/Skeleton';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';

const retailerNameMapper = {
  AMAZON_CA: 'AMAZON',
  WALMART_CA: 'WALMART',
  ALIEXPRESS: 'AliExpress',
};
const pieChartColors = ['#ff9900', '#0072CE', '#E52F20'];

class OrdersDistributionPieChart extends React.Component {
  state = {
    pieChartInterval: '1',
    smallWidths: ['sm', 'xs', 'md'],
    isLoading: false,
    isError: false,
    dateRange: {
      startDate: moment().subtract('days', 30),
      endDate: moment(),
      focusedInput: null,
    },
    errorMessages: [],
    successMessages: [],
    itemsDistributionData: [],
    activeIndex: 0,
    filterQuery: {},
  };

  componentDidMount = async () => {
    const filterQuery = {
      startDate: moment().subtract('days', 30).format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    };
    await this.setState({ filterQuery });
    this.getData();
  };
  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  // onPieLeave = () => {
  //   this.setState({
  //     activeIndex: null,
  //   });
  // };
  isOutSideRange = (day) => {
    const dateRange = this.state.dateRange;
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    if (startDate && endDate)
      return (
        day > startDate.clone().add('days', 100) ||
        day < endDate.clone().subtract('days', 100)
      );
    else if (startDate) return day > startDate.clone().add('days', 100);
    else if (endDate) return day < endDate.clone().subtract('days', 100);
    else return false;
  };

  handleDateChange = async ({ startDate, endDate }) => {
    const dateRange = this.state.dateRange;
    const filterQuery = { ...this.state.filterQuery };

    switch (dateRange.focusedInput) {
      case 'startDate':
        if (startDate !== null) {
          dateRange['startDate'] = startDate;
          filterQuery['startDate'] = startDate.format('YYYY-MM-DD');
        } else {
          dateRange.startDate = null;
          delete filterQuery['startDate'];
        }
        break;
      case 'endDate':
        if (endDate !== null) {
          dateRange['endDate'] = endDate;
          filterQuery['endDate'] = endDate.format('YYYY-MM-DD');
        } else {
          dateRange.endDate = null;
          delete filterQuery['endDate'];
        }
        break;
      default:
        break;
    }
    await this.setState({
      dateRange,
      filterQuery,
    });
    this.getData();
  };

  getData = async () => {
    const filterQuery = this.state.filterQuery;

    const { errorMessages } = this.state;
    this.setState({ isLoading: true, isError: false });
    try {
      const response = await netjeekServer.post(
        '/dashboard',
        { itemsDistribution: filterQuery },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response) {
        const resData = response.data.data.itemsDistribution;
        let itemsDistributionData = [];
        resData.forEach((item) => {
          itemsDistributionData.push({
            name: retailerNameMapper[item.retailerName],
            value: item.itemsCount,
          });
        });
        this.setState({ isLoading: false, itemsDistributionData });
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];
      if (err.code === 'ECONNABORTED') {
        // toast.error(errCodesMapper['666']);
        this.setState({ isLoading: true, isError: true });
        return;
      } else {
        const isError = err.response ? err.response.data.error : null;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
            if (error.code === 5000) {
              toast.error('Your session has expired. please sign in again.');
              localStorage.clear();

              this.props.history.push('/signin');
            }
          });
        }
      }
    }
    this.setState({ isLoading: false, isError: true, errorMessages });
  };
  render() {
    const { width } = this.props;
    const {
      smallWidths,
      dateRange,
      isLoading,
      isError,
      itemsDistributionData,
    } = this.state;

    const renderActiveShape = (props) => {
      const RADIAN = Math.PI / 180;
      const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value,
      } = props;
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const textAnchor = cos >= 0 ? 'start' : 'end';

      return (
        <g>
          <text
            x={cx}
            y={cy}
            dy={8}
            fontSize={width === 'md' ? 16 : 18}
            textAnchor='middle'
            fill={fill}
          >
            {payload.name}
          </text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill='none'
          />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill='#333'
          >{`${value} Itmes `}</text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill='#999'
          >
            {`(Rate ${(percent * 100).toFixed(2)}%)`}
          </text>
        </g>
      );
    };

    return (
      // {/* Pie Chart */ }
      <Grid item xs={12} sm={12} md={6}>
        <Paper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 15,
            }}
          >
            <Typography
              style={{ marginTop: '15px', marginLeft: '15px' }}
              variant={width === 'xs' ? 'h6' : 'h5'}
            >
              Orders Distribution
            </Typography>
            <Box mr={2} mt={2} hidden={isLoading}>
              <DateRangePicker
                small
                noBorder
                daySize={27}
                firstDayOfWeek={6}
                showClearDates
                // reopenPickerOnClearDates
                hideKeyboardShortcutsPanel
                showDefaultInputIcon
                isOutsideRange={this.isOutSideRange}
                startDatePlaceholderText='From Date'
                endDatePlaceholderText='To Date'
                startDateId='startDateId1'
                endDateId='endDateId1'
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                onDatesChange={this.handleDateChange}
                focusedInput={dateRange.focusedInput}
                onFocusChange={(focusedInput) => {
                  const dateRange = { ...this.state.dateRange };
                  dateRange.focusedInput = focusedInput;
                  this.setState({
                    dateRange,
                  });
                }}
                displayFormat={process.env.REACT_APP_DATE_FORMAT}
              />
            </Box>
          </div>
          <ResponsiveContainer aspect={2}>
            {isLoading ? (
              <Box
                style={{
                  padding: 15,
                }}
              >
                <Skeleton
                  variant='rect'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  disableAnimate={isError}
                  height='100%'
                  width='100%'
                >
                  {!isError ? (
                    <CircularProgress size={50} />
                  ) : (
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography> Something went wrong</Typography>
                      <Button variant='outlined' onClick={() => this.getData()}>
                        ↻ Try again
                      </Button>
                    </Box>
                  )}
                </Skeleton>
              </Box>
            ) : itemsDistributionData.length ? (
              <PieChart>
                <Pie
                  onMouseEnter={this.onPieEnter}
                  onMouseLeave={this.onPieLeave}
                  activeIndex={this.state.activeIndex}
                  activeShape={renderActiveShape}
                  data={itemsDistributionData}
                  cx={'66%'}
                  // cy={200}
                  innerRadius={
                    width === 'md'
                      ? //width = md
                        '35%'
                      : width === 'sm' || width === 'xs'
                      ? //width = xs/sm
                        '35%'
                      : //width = lg/xl
                        '39%'
                  }
                  outerRadius={
                    width === 'md'
                      ? //width = md
                        '55%'
                      : width === 'sm' || width === 'xs'
                      ? //width = xs/sm
                        '60%'
                      : //width = lg/xl
                        '67%'
                  }
                  fill='#8884d8'
                  dataKey='value'
                >
                  {itemsDistributionData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieChartColors[index % pieChartColors.length]}
                    />
                  ))}
                </Pie>
                <Legend
                  wrapperStyle={{
                    bottom: '25px',
                    top: '20px',
                    width:
                      width === 'md'
                        ? //width = md
                          '40%'
                        : width === 'sm' || width === 'xs'
                        ? //width = xs/sm
                          '30%'
                        : //width = lg/xl
                          '35%',
                  }}
                  formatter={(vaue, entry) => {
                    //
                    return width === 'xs' ? (
                      <Fragment>
                        <div
                          style={{
                            width: `calc(100% - ${
                              (smallWidths.indexOf(width) !== -1 ? 10 : 25) + 5
                            }px)`,
                            display: 'inline-flex',
                          }}
                        >
                          <div style={{ color: '#43425d', fontWeight: 600 }}>
                            {entry.payload.name}
                          </div>
                        </div>
                        <div style={{ color: '#515151' }}>
                          {entry.payload.value} items
                        </div>
                      </Fragment>
                    ) : (
                      <span
                        style={{
                          width: `calc(100% - ${
                            (smallWidths.indexOf(width) !== -1 ? 10 : 25) + 5
                          }px)`,
                          display: 'inline-flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span
                          style={{
                            color: '#43425d',
                            fontWeight: 600,
                            width: 80,
                            marginLeft: 10,
                          }}
                        >
                          {entry.payload.name}
                        </span>
                        <span style={{ color: '#515151' }}>
                          {entry.payload.value} items
                        </span>
                        <br />
                        <br />
                      </span>
                    );
                  }}
                  iconType='circle'
                  layout='vertical'
                  verticalAlign='middle'
                  iconSize={smallWidths.indexOf(width) !== -1 ? 10 : 25}
                  align='right'
                />
                <Tooltip />
              </PieChart>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h6'>No records to display</Typography>
              </Box>
            )}
          </ResponsiveContainer>
        </Paper>
      </Grid>
    );
  }
}

export default withWidth()(OrdersDistributionPieChart);
