import React, { useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import TableComponent from 'components/Table/TableComponent';

const tableFields = {
  head: ['Account Number', 'Name', 'Email', 'Mobile', 'Status'],
  search: ['Number', 'Name', 'Email', 'Mobile', 'Status'],
  dataKeys: ['account_number', 'name', 'email', 'phone', 'status'],
};
const tableSelectOptions = [
  {
    value: 'ALL',
    label: 'ALL',
  },
  {
    value: 1,
    label: 'ACTIVE',
  },
  {
    value: 0,
    label: 'INACTIVE',
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
  },

  tableHolderStyle: {
    padding: theme.spacing(1),
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  tableHead: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  tabelCardHeaderStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}));

export default function Customers(props) {
  const classes = useStyles();

  useEffect(() => {
    props.changePageHeader('Customers List');
  }, []);

  return (
    <Grid
      container
      direction='column'
      justify='center'
      className={classes.root}
    >
      <Grid item xs={12}>
        <Paper className={classes.tableHolderStyle} elevation={1}>
          <div className={classes.tabelCardHeaderStyle}></div>

          <TableComponent
            {...props}
            dataRoute='customers'
            selectable={true}
            searchable={true}
            tableFields={tableFields}
            selectOptions={tableSelectOptions}
            rowsPerPage={10}
            pagination={true}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
