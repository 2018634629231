import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Box from "@material-ui/core/Box";
import {
  Button,
  Paper,
  CircularProgress,
  Tooltip,
  Fade,
  Popper,
} from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import netjeekServer from "api/netjeek";

const useStyles = makeStyles({
  // paperCard: { height: '500px' },
  card: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    minHeight: 345,
    height: "100%",
  },
  deleteButton: {
    backgroundColor: "#d41323",
    padding: "5px 30px",
    color: "#fff",
    width: 75,
    marginLeft: 15,
    "&:hover": {
      backgroundColor: "#9e0d19",
    },
  },
  editButton: {
    paddingRight: 10,
    paddingLeft: 10,
    color: "#000",
    border: "solid 1px black",
  },
  typography: {
    padding: "16px",
  },
  modalButton: {
    padding: "4px",
    margin: "8px",
    "&:hover": {
      backgroundColor: `#fee666 !important`,
      opacity: 0.7,
    },
  },
});

export default function ShortcutCard(props) {
  const classes = useStyles();
  const [imageUrl] = useState(props.imageUrl);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [imageId] = useState(props.imageId);

  const handleDelete = async (idx) => {
    setIsButtonLoading(true);

    try {
      const response = await netjeekServer.delete(
        `/settings/slider/${imageId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response)
        props.setSuccessMessages(["Slider Image Deleted Successfully"]);
    } catch (err) {}
    setIsDeleteOpen(false);
    props.getImages();
  };

  return (
    <Card
      className={classes.card}
      style={{ background: `url(${imageUrl}) center`, backgroundSize: "cover" }}
    >
      {imageUrl ? (
        <Box>
          <CardActions style={{ justifyContent: "flex-end" }}>
            <Popper
              open={isDeleteOpen}
              anchorEl={anchorEl}
              placement="bottom"
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper style={{ display: "flex" }}>
                    <Typography className={classes.typography}>
                      Delete this item?
                    </Typography>
                    <Button
                      className={classes.modalButton}
                      onClick={() => setIsDeleteOpen(false)}
                    >
                      No
                    </Button>
                    <Button
                      className={classes.modalButton}
                      disabled={isButtonLoading}
                      onClick={() => handleDelete(imageId)}
                    >
                      {isButtonLoading && (
                        <CircularProgress
                          size={22}
                          style={{ marginLeft: 4, position: "absolute" }}
                        />
                      )}
                      Yes
                    </Button>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <Tooltip title="Delete">
              <Button
                size="small"
                className={classes.deleteButton}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setIsDeleteOpen(true);
                }}
              >
                Delete
              </Button>
            </Tooltip>
          </CardActions>
        </Box>
      ) : null}
    </Card>
  );
}
