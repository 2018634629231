import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, Box, Button } from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from 'recharts';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import Paper from '@material-ui/core/Paper';
import withWidth from '@material-ui/core/withWidth';
import { DateRangePicker } from 'react-dates';
import netjeekServer from 'api/netjeek';
import { toast } from 'react-toastify';
import { RoundTopBar, errCodesMapper } from './chartCommon';
import Skeleton from '@material-ui/lab/Skeleton';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';

class OverallSalesBarChart extends React.Component {
  state = {
    barChartInterval: '1',
    isLoading: false,
    isError: false,
    dateRange: {
      startDate: moment().clone().subtract('days', 30),
      endDate: moment(),
      focusedInput: null,
    },
    errorMessages: [],
    successMessages: [],
    deliveredVsTransitData: [],
    filterQuery: {},
  };

  componentDidMount = async () => {
    const filterQuery = {
      startDate: moment().clone().subtract('days', 30).format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    };
    await this.setState({ filterQuery });
    this.getData();
  };
  isOutSideRange = (day) => {
    const dateRange = this.state.dateRange;
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    if (startDate && endDate)
      return (
        day > startDate.clone().add('days', 100) ||
        day < endDate.clone().subtract('days', 100)
      );
    else if (startDate) return day > startDate.clone().add('days', 100);
    else if (endDate) return day < endDate.clone().subtract('days', 100);
    else return false;
  };
  handleDateChange = async ({ startDate, endDate }) => {
    const dateRange = this.state.dateRange;
    const filterQuery = { ...this.state.filterQuery };

    switch (dateRange.focusedInput) {
      case 'startDate':
        if (startDate !== null) {
          dateRange['startDate'] = startDate;
          filterQuery['startDate'] = startDate.format('YYYY-MM-DD');
        } else {
          dateRange.startDate = null;
          delete filterQuery['startDate'];
        }
        break;
      case 'endDate':
        if (endDate !== null) {
          dateRange['endDate'] = endDate;
          filterQuery['endDate'] = endDate.format('YYYY-MM-DD');
        } else {
          dateRange.endDate = null;
          delete filterQuery['endDate'];
        }
        break;
      default:
        break;
    }
    await this.setState({
      dateRange,
      filterQuery,
    });
    this.getData();
  };

  getData = async () => {
    const filterQuery = { ...this.state.filterQuery };
    const { errorMessages } = this.state;
    this.setState({ isLoading: true, isError: false });

    try {
      const response = await netjeekServer.post(
        '/dashboard',
        { deliveredVsTransit: filterQuery },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response) {
        const resData = response.data.data.deliveredVsTransit;

        let deliveredVsTransitData = [];

        resData.forEach((item) => {
          deliveredVsTransitData.push({
            name: moment(item.date).format('DD MMM'),
            Transit: item.inTransit,
            Delivered: item.delivered,
          });
        });
        this.setState({ isLoading: false, deliveredVsTransitData });
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];
      if (err.code === 'ECONNABORTED') {
        // toast.error(errCodesMapper['666']);
        this.setState({ isLoading: true, isError: true });
        return;
      } else {
        const isError = err.response ? err.response.data.error : null;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
            if (error.code === 5000) {
              toast.error('Your session has expired. please sign in again.');
              localStorage.clear();
              this.props.history.push('/signin');
            }
          });
        }
      }
    }
    this.setState({ isLoading: false, isError: true, errorMessages });
  };

  render() {
    const { width } = this.props;
    const {
      dateRange,
      deliveredVsTransitData,
      isError,
      isLoading,
    } = this.state;

    return (
      // {/* Bar Chart */}
      <Grid item xs={12} sm={12} md={6}>
        <Paper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 15,
              // alignItems: 'baseline',
              // overflow: 'hidden'
            }}
          >
            <Typography
              style={{ marginTop: '15px', marginLeft: '15px' }}
              variant={width === 'xs' ? 'h6' : 'h5'}
            >
              Transit vs. Delivered
            </Typography>
            <Box mr={2} mt={2} hidden={isLoading}>
              <DateRangePicker
                small
                noBorder
                daySize={27}
                firstDayOfWeek={6}
                showClearDates
                // reopenPickerOnClearDates
                hideKeyboardShortcutsPanel
                showDefaultInputIcon
                isOutsideRange={this.isOutSideRange}
                startDatePlaceholderText='From Date'
                endDatePlaceholderText='To Date'
                startDateId='startDateId3'
                endDateId='endDateId3'
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                onDatesChange={this.handleDateChange}
                focusedInput={dateRange.focusedInput}
                onFocusChange={(focusedInput) => {
                  const dateRange = { ...this.state.dateRange };
                  dateRange.focusedInput = focusedInput;
                  this.setState({
                    dateRange,
                  });
                }}
                displayFormat={process.env.REACT_APP_DATE_FORMAT}
              />
            </Box>
          </div>
          <ResponsiveContainer aspect={2}>
            {isLoading ? (
              <Box
                style={{
                  padding: 15,
                }}
              >
                <Skeleton
                  variant='rect'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  disableAnimate={isError}
                  height='100%'
                  width='100%'
                >
                  {!isError ? (
                    <CircularProgress size={50} />
                  ) : (
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography> Something went wrong</Typography>
                      <Button variant='outlined' onClick={() => this.getData()}>
                        ↻ Try again
                      </Button>
                    </Box>
                  )}
                </Skeleton>
              </Box>
            ) : deliveredVsTransitData.length ? (
              <BarChart
                style={{
                  marginLeft: '0px',
                  marginRight: '10px',
                  marginTop: '2px',
                }}
                data={deliveredVsTransitData}
              >
                <CartesianGrid stroke='#e1e1e1' />
                <XAxis dataKey='name' stroke='#343434' tickMargin={10} />
                <YAxis stroke='#343434' domain={[0, 'dataMax']} />
                <Tooltip />
                <Legend
                  iconType='circle'
                  verticalAlign='top'
                  formatter={(value) => {
                    return (
                      <span>
                        {value[0].toUpperCase() + value.substring(1)}
                        &nbsp;
                      </span>
                    );
                  }}
                />
                <Bar
                  dataKey='Delivered'
                  barSize={12}
                  shape={<RoundTopBar />}
                  fill='#a4a1fb'
                />
                <Bar
                  dataKey='Transit'
                  barSize={12}
                  shape={<RoundTopBar />}
                  fill='#56d9fe'
                />
              </BarChart>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h6'>No records to display</Typography>
              </Box>
            )}
          </ResponsiveContainer>
        </Paper>
      </Grid>
    );
  }
}

export default withWidth()(OverallSalesBarChart);
