import React, { Component } from 'react';
import {
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

const errCodesMapper = {
  1000: 'Quantity is missing',
  3000: 'Quantity is not valid',
  666: 'Network Error try again',
};
export default class EditShipmentData extends Component {
  state = {
    open: false,
    isLoading: false,
    errorMessages: [],

    shipmentData: {
      date: moment(),
      eta: moment(),
      method: '',
      notes: '',
    },
    updatedShipmentData: {},
  };

  componentDidMount = () => {};

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.data !== prevProps.data) {
      const data = this.props.data;
      // data.eta = moment(data.ete);
      // data.date = moment(data.date);

      this.setState({ shipmentData: data });
    }
  };

  updateShipment = async (event) => {
    event.preventDefault();
    const updatedShipmentData = { ...this.state.updatedShipmentData };
    const shipmentId = this.state.shipmentData.id;

    this.setState({ isLoading: true, errorMessages: [] });
    if (updatedShipmentData.eta)
      if (updatedShipmentData.date)
        // updatedShipmentData.eta = updatedShipmentData.eta.toISOString();
        updatedShipmentData.date = updatedShipmentData.date.toISOString();

    try {
      const response = await netjeekServer.patch(
        `/shipments/${shipmentId}`,
        updatedShipmentData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        this.props.setSuccessMessages(['Shipment Updated Successfully']);
        this.props.getShipmentData(shipmentId);
        this.setOpen(false);
        await this.setState({
          errorMessages: [],
        });
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (err.response.status === 404)
          errorMessages.push(errCodesMapper['666']);
        else if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
  };

  handleShipmentDateChange = (date) => {
    const updatedShipmentData = { ...this.state.updatedShipmentData };
    const shipmentData = { ...this.state.shipmentData };
    updatedShipmentData['date'] = date;
    shipmentData['date'] = date;
    console.log('updatedShipmentData', updatedShipmentData);

    this.setState({ updatedShipmentData, shipmentData });
  };
  handleETADateChange = (date) => {
    const updatedShipmentData = { ...this.state.updatedShipmentData };
    const shipmentData = { ...this.state.shipmentData };
    const newDate = date.add('hours', 8);
    updatedShipmentData.eta = newDate;
    shipmentData.eta = newDate;

    this.setState({ updatedShipmentData, shipmentData });
  };
  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedShipmentData = { ...this.state.updatedShipmentData };
    const shipmentData = { ...this.state.shipmentData };

    updatedShipmentData[name] = value;
    shipmentData[name] = value;
    this.setState({ updatedShipmentData, shipmentData });
  };

  render() {
    const {
      open,
      errorMessages,

      shipmentData,
      isLoading,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={() => this.setOpen(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          <Typography
            variant='h5'
            style={
              {
                //   fontWeight: 700
                //   fontSize: '15px'
              }
            }
          >
            Edit Shipment Details
          </Typography>
        </DialogTitle>
        {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
        <Snackbar type='error' messages={errorMessages} />

        <ValidatorForm
          ref='form'
          onSubmit={this.updateShipment}
          style={{ width: 600 }}
        >
          <DialogContent>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                Shipping Date:
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  disableToolbar
                  fullWidth
                  autoOk
                  inputVariant='outlined'
                  variant='inline'
                  format={process.env.REACT_APP_DATE_FORMAT}
                  margin='dense'
                  value={shipmentData.date}
                  onChange={this.handleShipmentDateChange}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                ETA:
              </Typography>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker
                  disableToolbar
                  fullWidth
                  autoOk
                  inputVariant='outlined'
                  variant='inline'
                  format={process.env.REACT_APP_DATE_FORMAT}
                  margin='dense'
                  value={shipmentData.eta}
                  onChange={this.handleETADateChange}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Shipping Method:
              </Typography>
              <TextValidator
                select
                margin='dense'
                name='method'
                validators={['required']}
                errorMessages={['shipping Method is required']}
                variant='outlined'
                fullWidth
                value={shipmentData.method}
                onChange={this.handleChange}
                style={{ marginBottom: '10px' }}
              >
                <MenuItem value={'EXPRESS'}>Express</MenuItem>
              </TextValidator>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Courier Number:
              </Typography>
              <TextValidator
                fullWidth
                variant='outlined'
                // validators={['required']}
                // errorMessages={['Courier Number is required']}
                margin='dense'
                name='courier_number'
                value={shipmentData.courier_number}
                onChange={this.handleChange}
                type='text'
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                // variant='h6'
                // display='block'
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                Notes:
              </Typography>
              <TextField
                fullWidth
                name='notes'
                margin='dense'
                multiline
                rows={3}
                type='text'
                id='notes'
                value={shipmentData.notes}
                onChange={this.handleChange}
                variant='outlined'
              />
            </Box>
          </DialogContent>
          <DialogActions style={{ margin: 10 }}>
            <Button
              onClick={() => {
                this.setOpen(false);
              }}
              style={{ color: '#000', fontSize: '16px' }}
            >
              Cancel
            </Button>
            <Button
              style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
              disabled={isLoading}
              type='submit'
              variant='contained'
              color='primary'
              // onClick={event => this.updateShipmentData(event)}
            >
              Update
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}
