import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
  InputAdornment,
  Divider,
  Tooltip,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';

import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const errCodesMapper = {
  1000: 'Region is missing',
  1001: 'City is missing',
  1002: 'Price Per Kg is missing',
  1003: 'Delivery Time is missing',
  3000: 'Region is not valid.',
  3001: 'City is not valid/',
  3002: 'Price Per Kg is not valid',
  // '3002': 'Price Per Kg must be a positive number',
  3003: 'Delivery Time is not valid.',
  4000: 'Shipping fees for this city/region already exist',
  666: 'Network Error. Please Try Again!',
};

const CityinRegion = {
  Tripoli: [
    'Tripoli',
    'Zawiya',
    'Sabratha',
    'Zuwara',
    'Tajura',
    'Tarhuna',
    'Gharyan',
    'Msallata',
    'Jumayl',
    'Sorman',
    'Algseibat',
    'Espiaa',
    'Regdalin',
    'Gasrakhyar',
    'Alqubah',
    'Almaya',
    'Alajaylat',
    'Nalut',
    'Zaltan',
    'Mizda',
    'Alurban',
    'Yafran',
    'Arrayaniya',
    'Teji',
    'Kikla',
    'Alrheibat',
    'Jadu',
    'Ghadames',
    'Arrajban',
    'Mashashita',
    'Zintan',
  ],
  Benghazi: [
    'Benghazi',
    'Bayda',
    'Ajdabiya',
    'Tobruk',
    'Derna',
    'Kufra',
    'Marj',
    'Shahhat',
    'Elagheila',
    'Abyar',
    'Nofaliya',
    'Brega',
    'Jalu',
    'Suluq',
    'Raslanuf',
    'Ummal Rizam',
    'Tocra',
    'Qaminis',
    'Benina',
    'Massah',
    'Binjawad',
    'Alabraq',
    'Awjila',
    'Tolmeita',
    'Zella',
    'Martuba',
    'Qayqab',
    'Musaid',
    'Tacnis',
    'Susa',
    'Tazirbu',
  ],
  Misrata: ['Misrata', 'Zliten', 'Khoms', 'Sirte', 'Baniwalid', 'Tawergha'],
  Sabha: [
    'Sabha',
    'Ubari',
    'Waddan',
    'Murzuk',
    'Hun',
    'Brak',
    'Qatrun',
    'Sokna',
    'Ummalaranib',
    'Ghat',
    'Traghan',
  ],
};
const regions = ['Tripoli', 'Benghazi', 'Misrata', 'Sabha'];

const styles = (theme) => ({
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(6),
      right: theme.spacing(6),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
});
class CreateShippingFeeModal extends Component {
  state = {
    open: false,
    isLoading: false,
    errorMessages: [],
    successMessages: [],
    shippingFeesData: {
      region: '',
      city: '',
      pricePerKg: '',
      avgDT: '',
    },
  };

  componentDidMount = () => {
    ValidatorForm.addValidationRule('Number>0', (value) => value > 0);
    ValidatorForm.addValidationRule('Number>=0', (value) => value >= 0);
    this.setState({
      shippingFeeData: {
        region: '',
        city: '',
        pricePerKg: '',
        avgDT: '',
      },
    });
  };

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
  };

  createShipment = async (event) => {
    event.preventDefault();
    const { shippingFeesData } = this.state;
    this.setState({ isLoading: true });
    this.props.rerenderTable(false);
    try {
      const response = await netjeekServer.post(
        `/settings/shipping-fees`,
        shippingFeesData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        let successMessages = [];
        this.props.setSuccessMessages(['Shipping Fees Created Successfully']);
        this.setOpen(false);
        this.props.rerenderTable(true);
        await this.setState({
          successMessages,
          errorMessages: [],
          shippingFeesData: {
            region: '',
            city: '',
            pricePerKg: '',
            avgDT: '',
          },
        });
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
    this.props.rerenderTable(true);
  };

  handleChange = (event) => {
    const name = event.target.name;

    const value = event.target.value;

    const shippingFeesData = { ...this.state.shippingFeesData };
    shippingFeesData[name] = value;
    this.setState({ shippingFeesData });
  };

  handleChangeRegion = (newValue) => {
    const shippingFeesData = this.state.shippingFeesData;
    shippingFeesData['region'] = newValue;
    shippingFeesData['city'] = '';

    this.setState({ shippingFeesData });
  };
  handleChangeCity = (newValue) => {
    const shippingFeesData = this.state.shippingFeesData;
    shippingFeesData['city'] = newValue;
    this.setState({ shippingFeesData });
  };
  render() {
    const { open, errorMessages, shippingFeesData, isLoading } = this.state;
    const { classes } = this.props;

    return (
      <Box>
        <Tooltip placement='left' title='Add Shipping Fee'>
          <SpeedDial
            open={false}
            ariaLabel='SpeedDialShortCuts'
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClick={() => this.setOpen(true)}
          />
        </Tooltip>
        <Dialog
          open={open}
          onClose={() => this.setOpen(false)}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Add Shipping Fee</DialogTitle>
          <Divider />
          <Snackbar type='error' messages={errorMessages} />
          <ValidatorForm
            ref='form'
            onSubmit={this.createShipment}
            style={{ width: 600 }}
          >
            <DialogContent>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    width: '30%',
                  }}
                >
                  Region:
                </Typography>
                <Autocomplete
                  id='regionSelect'
                  options={regions}
                  getOptionLabel={(option) => option}
                  style={{ width: '100%' }}
                  classes={{ inputRoot: classes.inputStyle }}
                  value={shippingFeesData.region}
                  onChange={(event, newValue) => {
                    this.handleChangeRegion(newValue);
                  }}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      margin='dense'
                      validators={['required']}
                      errorMessages={['Region is required']}
                      variant='outlined'
                      fullWidth
                      value={shippingFeesData.region}
                      style={{ marginBottom: '10px' }}
                    />
                  )}
                />
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    width: '30%',
                  }}
                >
                  City:
                </Typography>
                <Autocomplete
                  id='citySelect'
                  options={CityinRegion[shippingFeesData.region]}
                  getOptionLabel={(option) => option}
                  style={{ width: '100%' }}
                  classes={{ inputRoot: classes.inputStyle }}
                  value={shippingFeesData.city}
                  onChange={(event, newValue) => {
                    this.handleChangeCity(newValue);
                  }}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      margin='dense'
                      validators={['required']}
                      errorMessages={['City is required']}
                      variant='outlined'
                      fullWidth
                      value={shippingFeesData.city}
                      style={{ marginBottom: '10px' }}
                    />
                  )}
                />
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    //   marginRight: 15,
                    width: '30%',
                  }}
                >
                  Price Per Kg:
                </Typography>
                <TextValidator
                  fullWidth
                  name='pricePerKg'
                  margin='dense'
                  type='number'
                  id='pricePerKgInput'
                  validators={['Number>=0', 'required']}
                  errorMessages={[
                    'Price must be a positive number',
                    'Price is required',
                  ]}
                  value={shippingFeesData.pricePerKg}
                  onChange={this.handleChange}
                  InputProps={{
                    step: 0.1,
                    endAdornment: (
                      <InputAdornment position='start'>USD</InputAdornment>
                    ),
                  }}
                  variant='outlined'
                />
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '15px',
                    width: '30%',
                  }}
                >
                  Delivery Time:
                </Typography>
                <TextValidator
                  fullWidth
                  name='avgDT'
                  margin='dense'
                  type='number'
                  id='avgDTInput'
                  validators={['Number>0', 'required']}
                  errorMessages={[
                    'Day(s) must be a positive number greater than 0',
                    'Delivery Time is required',
                  ]}
                  value={shippingFeesData.avgDT}
                  onChange={this.handleChange}
                  InputProps={{
                    step: 1,
                    endAdornment: (
                      <InputAdornment position='start'>Day(s)</InputAdornment>
                    ),
                  }}
                  variant='outlined'
                />
              </Box>
            </DialogContent>
            <DialogActions style={{ margin: 10 }}>
              <Button
                onClick={() => {
                  this.setOpen(false);
                }}
                style={{ color: '#000', fontSize: '16px' }}
              >
                Cancel
              </Button>
              <Button
                style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
                disabled={isLoading}
                type='submit'
                variant='contained'
                color='primary'
              >
                ADD
                {isLoading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 4, position: 'absolute' }}
                  />
                )}
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </Box>
    );
  }
}
export default withStyles(styles)(CreateShippingFeeModal);
