import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

import { Route } from 'react-router-dom';
import UserManagement from 'components/UserManagement/UserManagement';
import TopPicks from 'pages/adminPortal/settings/topPicks';
import Shortcuts from 'pages/adminPortal/settings/shortcuts';
import CurrencyExchange from 'pages/adminPortal/settings/currencyExchange';
import SliderManagement from 'pages/adminPortal/settings/imageSlider';
import ShippingFees from 'pages/adminPortal/settings/ShippingFees';
import Discounts from 'pages/adminPortal/settings/Discounts';

const styles = (theme) => ({
  root: {
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
  },
});

class Settings extends Component {
  state = {};

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom></Typography>
        </Grid>
        <Route
          exact
          path={`/admin-portal/settings`}
          render={() => <SliderManagement {...this.props}></SliderManagement>}
        />
        <Route
          exact
          path={`/admin-portal/settings/slider-management`}
          render={() => <SliderManagement {...this.props}></SliderManagement>}
        />
        <Route
          exact
          path={`/admin-portal/settings/top-picks`}
          render={() => <TopPicks {...this.props}></TopPicks>}
        />
        <Route
          exact
          path={`/admin-portal/settings/shortcuts`}
          render={() => <Shortcuts {...this.props}></Shortcuts>}
        />
        <Route
          exact
          path={`/admin-portal/settings/currency-exchange`}
          render={() => <CurrencyExchange {...this.props}></CurrencyExchange>}
        />
        <Route
          exact
          path={'/admin-portal/settings/shipping-fees'}
          render={() => <ShippingFees {...this.props}></ShippingFees>}
        />
        <Route
          exact
          path={`/admin-portal/settings/user-management`}
          render={() => <UserManagement {...this.props}></UserManagement>}
        />
        <Route
          exact
          path='/admin-portal/settings/discount-management'
          render={() => <Discounts {...this.props}></Discounts>}
        />
      </Grid>
    );
  }
}
export default withStyles(styles)(Settings);
