import React, { Component } from 'react';
import {
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const errCodesMapper = {
  1000: 'Title is missing',
  1001: 'Retailer is missing',
  1002: 'Query is missing',
  1003: 'Title is missing',
  2000: 'Query is not valid',
  2001: 'Title is not valid',
  2100: 'Something went wrong',
  3000: 'Retailer must be one of [AMAZON, WALMART, ALIEXPRESS]',
  666: 'Network Error. Please Try Again!',
};
export default class AddTopPicks extends Component {
  state = {
    open: false,
    isLoading: false,
    errorMessages: [],
    successMessages: [],
    topPickData: {
      title: '',
      // titleAr: '',
      query: '',
      eRetailer: '',
    },
    updatedPickData: {},
  };

  componentDidMount = () => {};

  componentDidUpdate = (prevProps) => {
    if (this.props.topPickData !== prevProps.topPickData) {
      this.setState({ topPickData: this.props.topPickData });
    }
  };

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.props.modalType === 'Add') {
      this.addTopPick();
    } else {
      this.updateTopPick();
    }
  };

  updateTopPick = async () => {
    const { updatedPickData } = this.state;
    const topPickId = this.state.topPickData.id;

    if (Object.keys(updatedPickData).length === 0) return;
    this.setState({ isLoading: true });
    try {
      const response = await netjeekServer.put(
        `/top-picks/${topPickId}`,
        updatedPickData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        this.props.setSuccessMessages(['Top Pick Updated Successfully']);
        this.setOpen(false);
        await this.setState({
          updatedPickData: {},
          errorMessages: [],
        });
        this.props.getTopPicks();
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
  };
  addTopPick = async () => {
    const { topPickData } = this.state;
    this.setState({ isLoading: true });
    try {
      const response = await netjeekServer.post(
        `/settings/top-picks`,
        topPickData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        this.props.setSuccessMessages(['Top Pick Added Successfully']);
        this.setOpen(false);
        await this.setState({
          errorMessages: [],
          topPickData: {
            title: '',
            query: '',
            eRetailer: '',
          },
        });
        this.props.getTopPicks();
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (err.response.status === 404)
          errorMessages.push(errCodesMapper['666']);
        else if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
    this.setState({ isLoading: false });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const topPickData = { ...this.state.topPickData };
    const updatedPickData = { ...this.state.updatedPickData };
    if (this.props.modalType === 'Edit') {
      updatedPickData[name] = value;
    }
    topPickData[name] = value;
    this.setState({ topPickData, updatedPickData });
  };

  render() {
    const { modalType } = this.props;
    const {
      open,
      errorMessages,
      successMessages,
      topPickData,
      isLoading,
      updatedPickData,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={() => this.setOpen(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          <Typography variant='h5'>
            {this.props.modalType === 'Add'
              ? 'Add Top Pick'
              : 'Update Top Pick'}
          </Typography>
        </DialogTitle>
        {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
        <Snackbar type='error' messages={errorMessages} />
        <Snackbar type='success' messages={successMessages} />
        <ValidatorForm
          ref='form'
          onSubmit={this.handleSubmit}
          style={{ width: 600 }}
        >
          <DialogContent>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Title:
              </Typography>
              <TextValidator
                autoFocus
                fullWidth
                variant='outlined'
                validators={['required']}
                errorMessages={['Title is required']}
                margin='dense'
                name='title'
                value={topPickData.title}
                onChange={this.handleChange}
                type='text'
              />
            </Box>
            {/* <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%'
                }}
              >
                Arabic Title:
              </Typography>
              <TextValidator
                fullWidth
                variant='outlined'
                validators={['required']}
                errorMessages={['Arabic Title is required']}
                margin='dense'
                name='titleAr'
                value={topPickData.titleAr}
                onChange={this.handleChange}
                type='text'
              />
            </Box> */}
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                // variant='h6'
                // display='block'
                style={{
                  fontSize: '15px',
                  //   marginRight: 15,
                  width: '30%',
                }}
              >
                Query:
              </Typography>
              <TextValidator
                fullWidth
                name='query'
                margin='dense'
                validators={['required']}
                errorMessages={['Query is required']}
                type='text'
                id='query'
                value={topPickData.query}
                onChange={this.handleChange}
                variant='outlined'
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: '15px',
                  width: '30%',
                }}
              >
                Retailer
              </Typography>
              <TextValidator
                select
                margin='dense'
                name='eRetailer'
                variant='outlined'
                validators={['required']}
                errorMessages={['Retailer is required']}
                fullWidth
                value={topPickData.eRetailer}
                onChange={this.handleChange}
                style={{ marginBottom: '10px' }}
              >
                <MenuItem value={'AMAZON_CA'}>AMAZON</MenuItem>
                <MenuItem value={'WALMART_CA'}>WALMART</MenuItem>
                <MenuItem value={'ALIEXPRESS'}>ALIEXPRESS</MenuItem>
              </TextValidator>
            </Box>
          </DialogContent>
          <DialogActions style={{ margin: 10 }}>
            <Button
              onClick={() => {
                this.setOpen(false);
              }}
              style={{ color: '#000', fontSize: '16px' }}
            >
              Cancel
            </Button>
            <Button
              style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
              disabled={
                isLoading ||
                (modalType === 'Edit' &&
                  Object.keys(updatedPickData).length === 0)
              }
              type='submit'
              variant='contained'
              color='primary'
              // onClick={event => this.addTopPick(event)}
            >
              {modalType === 'Add' ? 'Add' : 'Update'}
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}
