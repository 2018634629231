import React from 'react';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { Button } from '@material-ui/core/';

export default function ImageDropzone2(props) {
  const { setimage } = props;

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const onChangeStatus = (image, status) => {
    if (status === 'done') {
      setimage(image);
    } else if (status === 'removed') {
      setimage(null);
    }
  };

  const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
    // const text = files.length > 0 ?

    if (files.length === 0)
      return (
        <Button
          variant='contained'
          color='primary'
          component='label'
          // accept={accept}
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles);
            });
          }}
        >
          Upload Iamge
          <input type='file' style={{ display: 'none' }} />
        </Button>
      );
    else return null;
  };

  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    console.log('file', file);
    body.append('image', file);
    return {
      url: `https://api-admin.netjeek.ml/settings/slider`,
      body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    };
  };

  return (
    <Dropzone
      accept='image/*'
      // getUploadParams={getUploadParams}

      InputComponent={Input}
      canRemove
      onChangeStatus={onChangeStatus}
      getFilesFromEvent={getFilesFromEvent}
    />
  );
}
