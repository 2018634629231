import React, { useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import TableComponent from 'components/Table/TableComponent';
const tableFields = {
  head: [
    'Image',
    'Item Name',
    'Variant',
    'E-Retailer',
    'Quantity Ordered',
    'Quantity Received',
    'Price',
    'Action',
  ],
  dataKeys: [
    'image_path',
    'item_name',
    'variant',
    'retailer_name',
    'quantity',
    'qty_received',
    'price',
    'action',
  ],
  search: [
    null,
    'Item Name',
    'Variant',
    'Retailer',
    'Quantity Ordered',
    'Quantity Received',
    'Price',
    null,
    // 'clearFilter'
  ],
};
const tableSelectOptions = [
  { value: 'ALL', label: 'ALL' },
  {
    label: 'AMAZON',
    value: 'AMAZON_CA',
  },
  {
    label: 'WALMART',
    value: 'WALMART_CA',
  },
  {
    label: 'ALIEXPRESS',
    value: 'ALIEXPRESS',
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
  },
  cardStyle: {
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'space-between',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  tableHolderStyle: {
    padding: theme.spacing(1),
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export default function ReceiveItems(props) {
  const classes = useStyles();

  useEffect(() => {
    props.changePageHeader('Items List');
  }, []);

  return (
    <Grid
      container
      direction='column'
      justify='center'
      className={classes.root}
    >
      <Grid item xs={12}>
        <br />
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.tableHolderStyle} elevation={1}>
          <TableComponent
            {...props}
            dataRoute='items'
            selectable={true}
            searchable={true}
            tableFields={tableFields}
            selectOptions={tableSelectOptions}
            pagination={true}
            rowsPerPage={10}
            rowsPerPageOptions={[5, 10, 25]}
            actions={['Receive']}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
