import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Box,
  CircularProgress,
  Typography,
  Step,
  StepLabel,
  Stepper,
  DialogTitle,
} from '@material-ui/core/';
import Snackbar from 'components/Snackbar';
import netjeekServer from 'api/netjeek';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { toast } from 'react-toastify';

const errCodesMapper = {
  1000: 'Verification Code is missing',
  3000: 'Verification Code is not valid',
  5000: 'Unauthenticated',
  666: 'Network Error. Please Try Again!',
};
const styles = (theme) => ({
  root: {},
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class AccountSetting extends Component {
  state = {
    open: false,
    isLoading: false,
    email: this.props.userData.email,
    activeStep: 0,
    errorMessages: [],
    canSubmit: false,
    resetPasswordData: {},
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.userData !== prevProps.userData) {
      if (!this.props.userData) this.setState({ userData: {} });
      else {
        this.setState({ email: this.props.userData.email });
      }
    }
  };

  componentDidMount = () => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      const { resetPasswordData } = this.state;
      if (resetPasswordData.newPassword !== value) {
        return false;
      }
      return true;
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const activeStep = this.state.activeStep;
    if (activeStep === 0) {
      this.sendVerificationCode();
      this.setState({ activeStep: this.state.activeStep + 1 });
    } else {
      this.resetPassword();
    }
  };
  sendVerificationCode = async () => {
    try {
      const email = this.state.email;
      this.setState({ isLoading: true });

      const response = await netjeekServer.post(
        '/users/attempt-password-reset',
        { email }
      );
      if (response) {
        toast.info('Check your email for the verification code.');
        this.setState({ isLoading: false });
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ errorMessages, isLoading: false });
    }
  };
  resetPassword = async () => {
    try {
      this.setState({ isLoading: true });
      const resetPasswordData = this.state.resetPasswordData;
      const data = {
        email: this.state.email,
        token: resetPasswordData.verificationCode,
        password: resetPasswordData.newPassword,
      };
      const response = await netjeekServer.post('/users/reset-password', {
        ...data,
      });

      if (response) {
        this.setState({ isLoading: false });
        this.props.setSuccessMessages([
          'Your Password Was Reset Successfully.',
        ]);
        localStorage.clear();
        setTimeout(() => {
          this.props.history.push('/signin');
        }, 1000);
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      this.setState({ isLoading: false, errorMessages });
    }
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const resetPasswordData = { ...this.state.resetPasswordData };
    if (value) resetPasswordData[name] = value;
    else delete resetPasswordData[name];
    if (
      resetPasswordData.verificationCode &&
      resetPasswordData.newPassword &&
      resetPasswordData.confirmPassword &&
      resetPasswordData.newPassword === resetPasswordData.confirmPassword
    )
      this.setState({ canSubmit: true });
    else this.setState({ canSubmit: false });

    this.setState({ resetPasswordData });
  };

  getSteps = () => {
    return ['Send Verification Code', 'Reset Password'];
  };

  getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  width: '15%',
                }}
              >
                E-mail:
              </Typography>
              <TextField
                variant='outlined'
                fullWidth
                name='email'
                margin='dense'
                type='text'
                id='email'
                inputProps={{
                  readonly: true,
                }}
                disabled
                value={this.state.email}
              />
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  width: '45%',
                }}
              >
                Verification Code:
              </Typography>
              <TextValidator
                variant='outlined'
                validators={['required']}
                errorMessages={['Verification Code is required']}
                fullWidth
                name='verificationCode'
                margin='dense'
                type='text'
                placeholder='Verification Code'
                // placeholder=
                id='verificationCode'
                onChange={this.handleChange}
                value={this.state.resetPasswordData.verificationCode}
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  width: '45%',
                }}
              >
                New Password:
              </Typography>
              <TextValidator
                variant='outlined'
                validators={[
                  'required',
                  'matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$',
                ]}
                errorMessages={[
                  'New Password is required.',
                  'For your own safety, your password must contain at least 1 uppercase, 1 lowercase and 1 special character.',
                ]}
                value={this.state.resetPasswordData.newPassword}
                onChange={this.handleChange}
                margin='dense'
                fullWidth
                name='newPassword'
                placeholder='New Password'
                type='password'
                id='newPassword'
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  width: '45%',
                }}
              >
                Confirm Password:
              </Typography>

              <TextValidator
                variant='outlined'
                validators={['isPasswordMatch', 'required']}
                errorMessages={[
                  "Passwords doesn't match",
                  'Confirm Password is required',
                ]}
                value={this.state.resetPasswordData.confirmPassword}
                onChange={this.handleChange}
                margin='dense'
                fullWidth
                name='confirmPassword'
                placeholder='Confirm Password'
                type='password'
                id='confirmPassword'
              />
            </Box>
          </Box>
        );

      default:
        return 'Unknown stepIndex';
    }
  };

  setOpen = (state) => {
    this.setState({ open: state, successMessages: [], errorMessages: [] });
    if (state === false) {
      this.setState({ resetPasswordData: {} });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      open,
      errorMessages,
      isLoading,
      canSubmit,
      activeStep,
    } = this.state;
    const steps = this.getSteps();

    return (
      <Dialog
        open={open}
        onClose={() => this.setOpen(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle>Reset Your Password</DialogTitle>
        <Snackbar type='error' messages={errorMessages} />
        <ValidatorForm ref='form' onSubmit={this.handleSubmit}>
          <DialogContent style={{ paddingTop: 0, width: 500 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Typography className={classes.instructions}>
              {this.getStepContent(activeStep)}
            </Typography>
          </DialogContent>
          <DialogActions style={{ margin: 10 }}>
            <Button
              onClick={() => {
                this.setOpen(false);
              }}
              style={{ color: '#000', fontSize: '16px' }}
            >
              Cancel
            </Button>
            <Button
              style={{ paddingRight: 25, paddingLeft: 25, marginLeft: 10 }}
              disabled={isLoading || (!canSubmit && activeStep !== 0)}
              type='submit'
              variant='contained'
              color='primary'
            >
              {activeStep === 0 ? 'Send Code' : 'Reset Password'}
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}
export default withStyles(styles)(AccountSetting);
