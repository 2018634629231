import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Tooltip,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import TableComponent from '../Table/TableComponent';
import DataCard from '../DataCard/DataCard';
import netjeekServer from 'api/netjeek';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import EditShipmentData from './../Shipments/EditShipmentData';
import AddOrdersModal from './AddOrdersModal';
import moment from 'moment';
import ShipmentTraceCard from 'components/TraceCard/ShipmentTraceCard';
import shipmentStatusMapper from 'shared/shipmentStatusMapper';
import { Helmet } from 'react-helmet';
import DownloadIcon from '@material-ui/icons/GetApp';
import sanitize from 'sanitize-filename';
import download from 'downloadjs';
const errCodesMapper = {
  3000: 'ID is not valid',
  5000: 'Unauthenticated',
  5001: 'Unauthorized',
  4000: 'Shipment Has Orders Assigned!',
  6000: "ID doesn't exist",
  666: 'Network Error. Please Try Again!',
};
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f0f1f7',
    overflow: 'hidden',
    // padding: '10px'
  },
  tableHolderStyle: {
    padding: theme.spacing(1.5),
    paddingBottom: 0,
  },
  tableHead: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabelCardHeaderStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  headerStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginRight: theme.spacing(4)
  },
  deleteButton: {
    paddingRight: 15,
    paddingLeft: 15,
    backgroundColor: '#cf1928',
    color: '#fff',
    // border: 'solid 1px black',
    marginLeft: 25,
    '&:hover': {
      backgroundColor: '#9e0d19',
    },
  },
  linkStyle: {
    color: '#00f',
    fontSize: theme.spacing(2),
    // textDecorationLine: 'none'
  },
  modalButton: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.4),
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      opacity: 0.7,
    },
  },
}));

function getTableFields(canRemove) {
  let tableFields = {
    head: [
      'Order Number',
      'Date',
      'Netjeek ETA',
      'Shipping Method',
      'Order Status',
    ],
    search: ['Order Number', 'Date Range', 'Date Range', 'Method', 'Status'],
    dataKeys: ['order_id', 'createdAt', 'eta', 'shipping_method', 'status'],
  };
  if (canRemove) {
    tableFields['head'].push('Action');
    tableFields['search'].push(null);
    tableFields['dataKeys'].push('removeAction');
  }
  return tableFields;
}

export default function ShipmentDetails(props) {
  const classes = useStyles();
  const [shipmentData, setShipmentData] = useState({});
  const [basicData, setBasicData] = useState({});
  const [shipmentStatusData, setShipmentStatusData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [doneAdd, setDoneAdd] = useState(true);
  const shipmentId = props.match.params.id;
  useEffect(() => {
    props.changePageHeader('Shipment Details');
    getShipmentData(shipmentId);
  }, []);

  const parseShipmentData = (resData) => {
    const {
      id,
      number,
      courier_number,
      date,
      eta,
      method,
      status,
      notes,
    } = resData;

    const tempbasicData = {
      id: id,
      number: number.toUpperCase(),
      date: moment(date).format(process.env.REACT_APP_DATE_FORMAT),
      eta: moment(eta).format(process.env.REACT_APP_DATE_FORMAT),
      courier_number: courier_number ? courier_number.toUpperCase() : '',
      method,
      status: shipmentStatusMapper[status],
      notes,
    };

    // if (courier_number)
    //   tempbasicData['courier_number'] = courier_number.toUpperCase();

    setBasicData(tempbasicData);
    setShipmentStatusData(resData.shipment_trace);
    setShipmentData(resData);
  };
  const getShipmentData = async (shipmentId) => {
    try {
      setDoneAdd(false);
      const response = await netjeekServer.get(`/shipments/${shipmentId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const resData = response.data.data;
      setDoneAdd(true);
      parseShipmentData(resData);
    } catch (err) {
      if (err.message === 'CANCELED') return;
      let errorMessages = [];

      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                props.history.push('/signin');
              }
            });
          }
        }
      }
      setIsLoading(false);
    }
  };
  const canAddRemoveOrders = () => {
    let canAdd = true;
    shipmentStatusData.forEach((status) => {
      if (status.name === 'PACKED') canAdd = false;
    });
    return canAdd;
  };

  const deleteShipment = async () => {
    try {
      setIsLoading(true);
      const shipmentId = props.match.params.id;
      const response = await netjeekServer.delete(`/shipments/${shipmentId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response) {
        props.setSuccessMessages(['Shipment Deleted Successfully']);
        props.history.push(`/admin-portal/shipments`);
      }
    } catch (err) {
      if (err.message === 'CANCELED') return;

      if (err.code === 'ECONNABORTED') {
        toast.error(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              toast.error(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                props.history.push('/signin');
              }
            });
          }
        }
      }
      setIsLoading(false);
    }
  };
  const downloadPackingList = async () => {
    setIsDownloading(true);
    const shipmentId = basicData.id;
    const shipmentNumber = basicData.number;

    try {
      const response = await netjeekServer.get(
        `/shipments/${shipmentId}/print-label`,
        {
          responseType: 'arraybuffer',

          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response) {
        props.setSuccessMessages(['Labels Created Updated Successfully']);
        var file = new Blob([response.data], {
          type: 'application/pdf',
        });
        download(
          file,
          `shipment-${sanitize(shipmentNumber)}.pdf`,
          'application/pdf'
        );

        setIsDownloading(false);
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (err.response.status === 404)
          errorMessages.push(errCodesMapper['666']);
        else if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      setIsDownloading(false);
    }
    setIsDownloading(false);
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Shipment Details</title>
      </Helmet>
      <Grid item xs={12} className={classes.headerStyle}>
        <Link to={`/admin-portal/shipments`} className={classes.linkStyle}>
          {`< Back to List`}
        </Link>

        <Box style={{ display: 'flex' }}>
          {/* <Typography variant='h4'>Shipment Details</Typography> */}

          <Box
            style={{
              display: 'flex',
              padding: 0,
              marginLeft: 15,
              alignItems: 'center',
              opacity: isDeleteClicked ? 1 : 0,
              transition: 'all .15s ease-in-out .15s',
            }}
          >
            <Typography>Delete this Shipment?</Typography>
            <Button
              className={classes.modalButton}
              onClick={() => setIsDeleteClicked(false)}
            >
              No
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={isLoading}
              className={classes.modalButton}
              onClick={deleteShipment}
            >
              Yes
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 4, position: 'absolute' }}
                />
              )}
            </Button>
          </Box>
          {canAddRemoveOrders() && shipmentStatusData.length !== 0 && (
            <Tooltip title='Delete Shipment'>
              <Button
                variant='contained'
                size='small'
                disabled={isLoading}
                className={classes.deleteButton}
                onClick={() => setIsDeleteClicked(true)}
              >
                Delete
              </Button>
            </Tooltip>
          )}
          {!canAddRemoveOrders() && (
            <Box ml={2}>
              <Tooltip title='Download Packing List'>
                <Button
                  startIcon={<DownloadIcon />}
                  variant='contained'
                  color='primary'
                  onClick={downloadPackingList}
                  disabled={isDownloading}
                >
                  Packing List
                  {isDownloading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 4, position: 'absolute' }}
                    />
                  )}
                </Button>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Grid>

      <Grid item xs={6}>
        <DataCard
          {...props}
          isEditable={true}
          EditComponent={EditShipmentData}
          title='Basic Information'
          data={basicData}
          getShipmentData={getShipmentData}
        />
      </Grid>
      <Grid item xs={6}>
        <ShipmentTraceCard
          {...props}
          traceData={shipmentStatusData}
          shipmentData={shipmentData}
          getShipmentData={getShipmentData}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.tableHolderStyle} elevation={1}>
          <Box className={classes.tabelCardHeaderStyle}>
            <Typography variant='h5' component='h3' gutterBottom>
              Orders List
            </Typography>
            <Box ml={2} />
            {canAddRemoveOrders() && shipmentStatusData.length !== 0 && (
              <AddOrdersModal
                {...props}
                shipmentId={shipmentId}
                setDoneAdd={setDoneAdd}
              />
            )}
          </Box>

          <TableComponent
            {...props}
            dataRoute={`orders`}
            selectable={true}
            searchable={false}
            tableFields={getTableFields(canAddRemoveOrders())}
            pagination={true}
            rowsPerPage={10}
            rowsPerPageOptions={[5, 10, 20]}
            shipmentId={shipmentId}
            shipmentDetails
            doneAdd={doneAdd}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
