import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import {
  Button,
  Paper,
  CircularProgress,
  Tooltip,
  Fade,
  Popper,
} from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import Image from 'material-ui-image';
import netjeekServer from 'api/netjeek';

const eRetailerNameMapper = {
  AMAZON_CA: 'AMAZON',
  WALMART_CA: 'WALMART',
  ALIEXPRESS: 'ALIEXPRESS',
};
const errCodesMapper = {
  1000: 'Quantity is missing',
  3000: 'Quantity is not valid',

  666: 'Network Error try again',
};
const useStyles = makeStyles({
  // paperCard: { height: '500px' },
  card: {
    // maxWidth: 345,
    height: '100%',
  },
  deleteButton: {
    backgroundColor: '#d41323',
    padding: '5px 30px',
    color: '#fff',
    width: 75,
    marginLeft: 15,
    '&:hover': {
      backgroundColor: '#9e0d19',
    },
  },
  editButton: {
    paddingRight: 10,
    paddingLeft: 10,
    color: '#000',
    border: 'solid 1px black',
  },
  typography: {
    padding: '16px',
  },
  modalButton: {
    padding: '4px',
    margin: '8px',
    '&:hover': {
      backgroundColor: `#fee666 !important`,
      opacity: 0.7,
    },
  },
});

export default function ShortcutCard(props) {
  const classes = useStyles();
  const [shortcutData] = useState(props.shortcutData);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [shortcutImage, setShortcutImage] = useState('');

  const handleDelete = async (idx) => {
    setIsButtonLoading(true);
    const shortcutId = shortcutData.id;

    try {
      const response = await netjeekServer.delete(
        `/settings/shortcuts/${shortcutId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response) {
        props.setSuccessMessages(['Shortcut Deleted Successfully']);
        setIsDeleteOpen(false);
        props.getShortcuts();
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        if (err.response) {
          const isError = err.response.data.error;
          if (isError) {
            const errors = err.response.data.errors;
            errors.forEach((error) => {
              errorMessages.push(errCodesMapper[error.code]);
              if (error.code === 5000) {
                toast.error('Your session has expired. please sign in again.');
                localStorage.clear();
                this.props.history.push('/signin');
              }
            });
          }
        }
      }
      this.setState({ isLoading: false, errorMessages, successMessages: [] });
    }
  };

  useEffect(() => {
    setShortcutImage(shortcutData.image);
  }, [shortcutImage]);

  return (
    <Card className={classes.card}>
      <Image
        style={{
          backgroundColor: 'transparent',
          padding: 0,
          height: '250px',
        }}
        src={shortcutImage}
        imageStyle={{
          height: '100%',
          width: '100%',
          position: 'unset',
        }}
        alt={shortcutImage}
        loading={<CircularProgress size={20} style={{ padding: 0 }} />}
      />

      {Object.keys(shortcutData).length > 0 ? (
        <Box>
          <CardContent style={{ paddingTop: 1, paddingBottom: 3 }}>
            <Box mt={1}>
              <Typography variant='body1' gutterBottom>
                <b> Query:</b> &nbsp; {shortcutData.query}
              </Typography>
              <Typography variant='body1' gutterBottom>
                <b> E-Retailer:</b> &nbsp;{' '}
                {eRetailerNameMapper[shortcutData.eRetailer]}
              </Typography>
            </Box>
          </CardContent>
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <Popper
              open={isDeleteOpen}
              anchorEl={anchorEl}
              placement='bottom'
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper style={{ display: 'flex' }}>
                    <Typography className={classes.typography}>
                      Delete this item?
                    </Typography>
                    <Button
                      className={classes.modalButton}
                      onClick={() => setIsDeleteOpen(false)}
                    >
                      No
                    </Button>
                    <Button
                      className={classes.modalButton}
                      disabled={isButtonLoading}
                      onClick={() => handleDelete(shortcutData.id)}
                    >
                      {isButtonLoading && (
                        <CircularProgress
                          size={22}
                          style={{ marginLeft: 4, position: 'absolute' }}
                        />
                      )}
                      Yes
                    </Button>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <Tooltip title='Delete'>
              <Button
                size='small'
                className={classes.deleteButton}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setIsDeleteOpen(true);
                }}
              >
                Delete
              </Button>
            </Tooltip>
          </CardActions>
        </Box>
      ) : null}
    </Card>
  );
}
