import React, { Component } from 'react';
import { Grid } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

import OrdersDistributionPieChart from './OrdersDistributionPieChart';
import TransitVsDeliverdChart from './TransitVsDeliverdChart';
import OrdersPlacedChart from './OrdersPlacedChart';

const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f0f1f7',
  },
});

class Dashboard extends Component {
  state = {};

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  componentDidMount = () => {
    this.props.changePageHeader('Dashboard');
  };
  render() {
    return (
      <Grid container direction='row'>
        <Grid item xs={12} style={{ marginTop: 15 }}>
          <OrdersPlacedChart {...this.props} />
        </Grid>
        <Grid
          item
          container
          direction='row'
          spacing={2}
          style={{ marginTop: 5 }}
        >
          <OrdersDistributionPieChart {...this.props} />
          <TransitVsDeliverdChart {...this.props} />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(Dashboard);
