import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Paper,
  CssBaseline,
} from '@material-ui/core';
import netjeekServer from 'api/netjeek';
import Background from 'assets/images/cart.jpg';
import netjeekLogo from 'assets/images/logo.png';
import Snackbar from 'components/Snackbar';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const errCodesMapper = {
  1000: 'E-mail is missing',
  3000: 'E-mail is not valid',
  666: 'Network Error. Please Try Again!',
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    padding: theme.spacing(1),
    margin: theme.spacing(3, 0, 2),
  },
  progressor: {
    marginLeft: 4,
    position: 'absolute',
  },
  backToLoginStyle: {
    color: '#3d67f2',
  },
  netjeekLogo: {
    height: '140px',
  },
}));

export default function ForgotPassword(props) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const inputchangeHandler = (name) => (event) => {
    setEmail(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      localStorage.setItem('email', email);
      const response = await netjeekServer.post(
        '/users/attempt-password-reset',
        { email }
      );

      if (response) {
        props.history.push('/reset-password');
        toast.info('Check your email for the verification code.');
        setIsLoading(false);
      }
    } catch (err) {
      let errorMessages = [];
      if (!err.response || err.code === 'ECONNABORTED') {
        errorMessages.push(errCodesMapper['666']);
      } else {
        const isError = err.response.data.error;
        if (isError) {
          const errors = err.response.data.errors;

          errors.forEach((error) => {
            errorMessages.push(errCodesMapper[error.code]);
          });
        }
      }
      setIsLoading(false);
      setErrorMessages(errorMessages);
    }
  };

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img
            alt='netjeek-logo'
            src={netjeekLogo}
            className={classes.netjeekLogo}
          />

          <Typography gutterBottom component='h1' variant='h3' align='center'>
            NETJEEK | ADMIN
          </Typography>
          <Typography gutterBottom component='h1' variant='h6'>
            Forgot your password? Enter your email below.
          </Typography>
          <ValidatorForm className={classes.form} onSubmit={submitHandler}>
            <Snackbar type='error' messages={errorMessages} />
            <TextValidator
              variant='outlined'
              validators={['required', 'isEmail']}
              errorMessages={[
                'Email Address is required',
                'Email Address is not valid',
              ]}
              value={email}
              onChange={inputchangeHandler('email')}
              margin='normal'
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              color='secondary'
              autoFocus
            />

            {isLoading ? (
              <Button
                disabled
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                Please Wait
                <CircularProgress size={24} className={classes.progressor} />
              </Button>
            ) : (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                Send Me Instructions
              </Button>
            )}

            <Grid container>
              <Grid item xs>
                <Link to={`/signin`} className={classes.backToLoginStyle}>
                  {`< Back To Login`}
                </Link>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </Grid>
    </Grid>
  );
}
